import { useEffect, useState } from "react";

const useLocalStorage = (key, intialValue) => {
  const [storeValue, setStoreValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : intialValue;
    } catch (error) {
      console.log();
    }
  });
  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(storeValue));
    } catch (error) {
      console.log(error);
    }
  }, [key, storeValue]);

  return [storeValue, setStoreValue];
};

export default useLocalStorage;
