import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register required Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const HorizontalBarChart = () => {
  // Labels and data setup
  const labels = ["JAN", "FEB", "MAR", "APR", "MAY"]; // Replace Utils.months with static labels
  const data = {
    labels: labels,
    datasets: [
      {
        axis: "y", // Horizontal bar chart

        data: [1.36, 1.2, 1.1, 1],
        fill: false,
        backgroundColor: [
          "rgba(102, 153, 255, 1)",
          "rgba(38, 198, 249, 1)",
          "rgba(102, 153, 255, 1)",
          "rgba(102, 153, 255, 1)",
          "rgba(102, 153, 255, 1)",
        ],
        borderRadius: 10,
        barThickness: 25,
      },
    ],
  };

  // Chart configuration
  const options = {
    indexAxis: "y", // Horizontal bar chart
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: false, // We don't want to start from 0
        min: 0.5, // Start at 0.5
        max: 1.5, // End at 1.5
        ticks: {
          stepSize: 0.25, // Step size of 0.25
          callback: function (value) {
            return value.toFixed(2); // Ensures 2 decimal places without rounding
          },
        },
        grid: {
          borderDash: [5, 5], // Dotted line for x-axis grid
        },
      },
      y: {
        grid: {
          display: false, // Hide grid lines on the y-axis
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default HorizontalBarChart;

// import React from "react";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";

// const HorizontalBarChart = () => {
//   const options = {
//     chart: {
//       type: "bar", // Change to 'bar' for horizontal chart
//     },
//     legend: {
//       enabled: false, // Remove legend
//     },
//     title: {
//       text: "",
//     },
//     yAxis: {
//       categories: [
//         "-0.75",
//         "-0.5",
//         "-0.25",
//         "0",
//         "0.25",
//         "1",
//         "1.25",
//         "1.5",
//         "4",
//       ], //ustom categories for Y-axis
//       title: {
//         text: null, // Remove title if not needed
//       },
//     },
//     xAxis: {
//       categories: ["JAN", "FEB", "MAR", "APR", "MAY"],
//     },
//     credits: {
//       enabled: false,
//     },
//     plotOptions: {
//       bar: {
//         borderRadius: "25%",
//       },
//     },
//     series: [
//       {
//         name: "John",
//         data: [2, 3, 4, 7, 2],
//         color: "#6699FF", // Change color of John’s bars
//       },
//       {
//         name: "Jane",
//         data: [2, -2, -3, 2, 1],
//         color: "#26C6F9", // Change color of John’s bars
//       },
//       {
//         name: "Joe",
//         data: [3, 4, 4, -2, -0.5],
//         color: "#6699FF", // Change color of John’s bars
//       },
//     ],
//   };

//   return <HighchartsReact highcharts={Highcharts} options={options} />;
// };

// export default HorizontalBarChart;
