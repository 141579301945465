export const Star = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.0852 7.81493L25.2002 12.0449C25.4852 12.6299 26.2502 13.1849 26.8952 13.3049L30.7202 13.9349C33.1652 14.3399 33.7352 16.1099 31.9802 17.8799L28.9952 20.8649C28.5002 21.3599 28.2152 22.3349 28.3802 23.0399L29.2352 26.7299C29.9102 29.6399 28.3502 30.7799 25.7852 29.2499L22.2002 27.1199C21.5552 26.7299 20.4752 26.7299 19.8302 27.1199L16.2452 29.2499C13.6802 30.7649 12.1202 29.6399 12.7952 26.7299L13.6502 23.0399C13.7852 22.3199 13.5002 21.3449 13.0052 20.8499L10.0202 17.8649C8.26516 16.1099 8.83516 14.3399 11.2802 13.9199L15.1052 13.2899C15.7502 13.1849 16.5152 12.6149 16.8002 12.0299L18.9152 7.79993C20.0702 5.51993 21.9302 5.51993 23.0852 7.81493Z"
          fill="#6699FF"
        />
        <path
          d="M12 8.625H3C2.385 8.625 1.875 8.115 1.875 7.5C1.875 6.885 2.385 6.375 3 6.375H12C12.615 6.375 13.125 6.885 13.125 7.5C13.125 8.115 12.615 8.625 12 8.625Z"
          fill="#6699FF"
        />
        <path
          d="M7.5 29.625H3C2.385 29.625 1.875 29.115 1.875 28.5C1.875 27.885 2.385 27.375 3 27.375H7.5C8.115 27.375 8.625 27.885 8.625 28.5C8.625 29.115 8.115 29.625 7.5 29.625Z"
          fill="#6699FF"
        />
        <path
          d="M4.5 19.125H3C2.385 19.125 1.875 18.615 1.875 18C1.875 17.385 2.385 16.875 3 16.875H4.5C5.115 16.875 5.625 17.385 5.625 18C5.625 18.615 5.115 19.125 4.5 19.125Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const MedalStar = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.8749 27.7052L29.3999 28.2902C28.8449 28.4252 28.4099 28.8452 28.2899 29.4002L27.7649 31.6052C27.4799 32.8052 25.9499 33.1802 25.1549 32.2352L20.6699 27.0752C20.3099 26.6552 20.5049 25.9952 21.0449 25.8602C23.6999 25.2152 26.0849 23.7302 27.8399 21.6152C28.1249 21.2702 28.6349 21.2252 28.9499 21.5402L32.2799 24.8702C33.4199 26.0102 33.0149 27.4352 31.8749 27.7052Z"
          fill="#6699FF"
        />
        <path
          d="M4.04976 27.7052L6.52476 28.2902C7.07976 28.4252 7.51476 28.8452 7.63476 29.4002L8.15976 31.6052C8.44476 32.8052 9.97476 33.1802 10.7698 32.2352L15.2548 27.0752C15.6148 26.6552 15.4198 25.9952 14.8798 25.8602C12.2248 25.2152 9.83976 23.7302 8.08476 21.6152C7.79976 21.2702 7.28976 21.2252 6.97476 21.5402L3.64476 24.8702C2.50476 26.0102 2.90976 27.4352 4.04976 27.7052Z"
          fill="#6699FF"
        />
        <path
          d="M18 3C12.195 3 7.5 7.695 7.5 13.5C7.5 15.675 8.145 17.67 9.255 19.335C10.875 21.735 13.44 23.43 16.425 23.865C16.935 23.955 17.46 24 18 24C18.54 24 19.065 23.955 19.575 23.865C22.56 23.43 25.125 21.735 26.745 19.335C27.855 17.67 28.5 15.675 28.5 13.5C28.5 7.695 23.805 3 18 3ZM22.59 13.17L21.345 14.415C21.135 14.625 21.015 15.03 21.09 15.33L21.45 16.875C21.735 18.09 21.09 18.57 20.01 17.925L18.51 17.04C18.24 16.875 17.79 16.875 17.52 17.04L16.02 17.925C14.94 18.555 14.295 18.09 14.58 16.875L14.94 15.33C15 15.045 14.895 14.625 14.685 14.415L13.41 13.17C12.675 12.435 12.915 11.7 13.935 11.535L15.54 11.265C15.81 11.22 16.125 10.98 16.245 10.74L17.13 8.97C17.61 8.01 18.39 8.01 18.87 8.97L19.755 10.74C19.875 10.98 20.19 11.22 20.475 11.265L22.08 11.535C23.085 11.7 23.325 12.435 22.59 13.17Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const DollarCircle = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.125 23.88H20.1C21.075 23.88 21.885 23.01 21.885 21.96C21.885 20.655 21.42 20.4 20.655 20.13L19.14 19.605V23.88H19.125Z"
          fill="#6699FF"
        />
        <path
          d="M17.9551 2.85012C9.6751 2.88012 2.9701 9.61512 3.0001 17.8951C3.0301 26.1751 9.7651 32.8801 18.0451 32.8501C26.3251 32.8201 33.0301 26.0851 33.0001 17.8051C32.9701 9.52512 26.2351 2.83512 17.9551 2.85012ZM21.3901 18.0001C22.5601 18.4051 24.1351 19.2751 24.1351 21.9601C24.1351 24.2701 22.3201 26.1301 20.1001 26.1301H19.1251V27.0001C19.1251 27.6151 18.6151 28.1251 18.0001 28.1251C17.3851 28.1251 16.8751 27.6151 16.8751 27.0001V26.1301H16.3351C13.8751 26.1301 11.8801 24.0601 11.8801 21.5101C11.8801 20.8951 12.3901 20.3851 13.0051 20.3851C13.6201 20.3851 14.1301 20.8951 14.1301 21.5101C14.1301 22.8151 15.1201 23.8801 16.3351 23.8801H16.8751V18.8101L14.6101 18.0001C13.4401 17.5951 11.8651 16.7251 11.8651 14.0401C11.8651 11.7301 13.6801 9.87012 15.9001 9.87012H16.8751V9.00012C16.8751 8.38512 17.3851 7.87512 18.0001 7.87512C18.6151 7.87512 19.1251 8.38512 19.1251 9.00012V9.87012H19.6651C22.1251 9.87012 24.1201 11.9401 24.1201 14.4901C24.1201 15.1051 23.6101 15.6151 22.9951 15.6151C22.3801 15.6151 21.8701 15.1051 21.8701 14.4901C21.8701 13.1851 20.8801 12.1201 19.6651 12.1201H19.1251V17.1901L21.3901 18.0001Z"
          fill="#6699FF"
        />
        <path
          d="M14.1299 14.0551C14.1299 15.3601 14.5949 15.6151 15.3599 15.8851L16.8749 16.4101V12.1201H15.8999C14.9249 12.1201 14.1299 12.9901 14.1299 14.0551Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};
export const Friend = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 3C14.07 3 10.875 6.195 10.875 10.125C10.875 13.98 13.89 17.1 17.82 17.235C17.94 17.22 18.06 17.22 18.15 17.235C18.18 17.235 18.195 17.235 18.225 17.235C18.24 17.235 18.24 17.235 18.255 17.235C22.095 17.1 25.11 13.98 25.125 10.125C25.125 6.195 21.93 3 18 3Z"
          fill="#6699FF"
        />
        <path
          d="M25.6199 21.2248C21.4349 18.4348 14.6099 18.4348 10.3949 21.2248C8.48994 22.4998 7.43994 24.2248 7.43994 26.0698C7.43994 27.9148 8.48994 29.6248 10.3799 30.8848C12.4799 32.2948 15.2399 32.9998 17.9999 32.9998C20.7599 32.9998 23.5199 32.2948 25.6199 30.8848C27.5099 29.6098 28.5599 27.8998 28.5599 26.0398C28.5449 24.1948 27.5099 22.4848 25.6199 21.2248ZM20.9999 27.1948H19.1249V29.0698C19.1249 29.6848 18.6149 30.1948 17.9999 30.1948C17.3849 30.1948 16.8749 29.6848 16.8749 29.0698V27.1948H14.9999C14.3849 27.1948 13.8749 26.6848 13.8749 26.0698C13.8749 25.4548 14.3849 24.9448 14.9999 24.9448H16.8749V23.0698C16.8749 22.4548 17.3849 21.9448 17.9999 21.9448C18.6149 21.9448 19.1249 22.4548 19.1249 23.0698V24.9448H20.9999C21.6149 24.9448 22.1249 25.4548 22.1249 26.0698C22.1249 26.6848 21.6149 27.1948 20.9999 27.1948Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const HighFrequency = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3ZM25.785 23.91C25.725 24.045 25.65 24.165 25.545 24.27L23.01 26.805C22.785 27.03 22.5 27.135 22.215 27.135C21.93 27.135 21.645 27.03 21.42 26.805C20.985 26.37 20.985 25.65 21.42 25.215L22.035 24.6H13.65C11.7 24.6 10.125 23.01 10.125 21.075V18.42C10.125 17.805 10.635 17.295 11.25 17.295C11.865 17.295 12.375 17.805 12.375 18.42V21.075C12.375 21.78 12.945 22.35 13.65 22.35H22.035L21.42 21.735C20.985 21.3 20.985 20.58 21.42 20.145C21.855 19.71 22.575 19.71 23.01 20.145L25.545 22.68C25.65 22.785 25.725 22.905 25.785 23.04C25.905 23.325 25.905 23.64 25.785 23.91ZM25.875 17.58C25.875 18.195 25.365 18.705 24.75 18.705C24.135 18.705 23.625 18.195 23.625 17.58V14.925C23.625 14.22 23.055 13.65 22.35 13.65H13.965L14.58 14.25C15.015 14.685 15.015 15.405 14.58 15.84C14.355 16.065 14.07 16.17 13.785 16.17C13.5 16.17 13.215 16.065 12.99 15.84L10.455 13.305C10.35 13.2 10.275 13.08 10.215 12.945C10.095 12.675 10.095 12.36 10.215 12.09C10.275 11.955 10.35 11.82 10.455 11.715L12.99 9.18C13.425 8.745 14.145 8.745 14.58 9.18C15.015 9.615 15.015 10.335 14.58 10.77L13.965 11.385H22.35C24.3 11.385 25.875 12.975 25.875 14.91V17.58Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const BuyTicket = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.23 3H10.77C7.57498 3 4.97998 5.61 4.97998 8.79V29.925C4.97998 32.625 6.91498 33.765 9.28498 32.46L16.605 28.395C17.385 27.96 18.645 27.96 19.41 28.395L26.73 32.46C29.1 33.78 31.035 32.64 31.035 29.925V8.79C31.02 5.61 28.425 3 25.23 3ZM22.515 14.625C21.06 15.15 19.53 15.42 18 15.42C16.47 15.42 14.94 15.15 13.485 14.625C12.9 14.415 12.6 13.77 12.81 13.185C13.035 12.6 13.68 12.3 14.265 12.51C16.68 13.38 19.335 13.38 21.75 12.51C22.335 12.3 22.98 12.6 23.19 13.185C23.4 13.77 23.1 14.415 22.515 14.625Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const SteadyHead = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.285 3H11.715C6.255 3 3 6.255 3 11.715V24.27C3 29.745 6.255 33 11.715 33H24.27C29.73 33 32.985 29.745 32.985 24.285V11.715C33 6.255 29.745 3 24.285 3ZM24 19.125H19.125V24C19.125 24.615 18.615 25.125 18 25.125C17.385 25.125 16.875 24.615 16.875 24V19.125H12C11.385 19.125 10.875 18.615 10.875 18C10.875 17.385 11.385 16.875 12 16.875H16.875V12C16.875 11.385 17.385 10.875 18 10.875C18.615 10.875 19.125 11.385 19.125 12V16.875H24C24.615 16.875 25.125 17.385 25.125 18C25.125 18.615 24.615 19.125 24 19.125Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const Crypto = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.4549 19.125H21.2999H14.2949V23.37H16.2599H22.4549C23.8799 23.37 25.0499 22.41 25.0499 21.24C25.0499 20.07 23.8799 19.125 22.4549 19.125Z"
          fill="#6699FF"
        />
        <path
          d="M18 3C9.72 3 3 9.72 3 18C3 26.28 9.72 33 18 33C26.28 33 33 26.28 33 18C33 9.72 26.28 3 18 3ZM22.455 25.62H19.98V27.75C19.98 28.365 19.47 28.875 18.855 28.875C18.24 28.875 17.73 28.365 17.73 27.75V25.62H16.26H15.915V27.75C15.915 28.365 15.405 28.875 14.79 28.875C14.175 28.875 13.665 28.365 13.665 27.75V25.62H13.17H10.575C9.96 25.62 9.45 25.11 9.45 24.495C9.45 23.88 9.96 23.37 10.575 23.37H12.045V18V12.63H10.575C9.96 12.63 9.45 12.12 9.45 11.505C9.45 10.89 9.96 10.38 10.575 10.38H13.17H13.665V8.25C13.665 7.635 14.175 7.125 14.79 7.125C15.405 7.125 15.915 7.635 15.915 8.25V10.38H16.26H17.73V8.25C17.73 7.635 18.24 7.125 18.855 7.125C19.47 7.125 19.98 7.635 19.98 8.25V10.38H21.3C23.625 10.38 25.68 12.42 25.68 14.76C25.68 15.765 25.32 16.68 24.75 17.43C26.265 18.165 27.3 19.605 27.3 21.27C27.3 23.655 25.125 25.62 22.455 25.62Z"
          fill="#6699FF"
        />
        <path
          d="M23.4299 14.7452C23.4299 13.7552 22.4999 12.6152 21.2999 12.6152H16.2599H14.2949V16.8602H21.2999C22.4699 16.8752 23.4299 15.9152 23.4299 14.7452Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const RiskTaker = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 3C9.735 3 3 9.735 3 18C3 26.265 9.735 33 18 33C26.265 33 33 26.265 33 18C33 9.735 26.265 3 18 3ZM16.875 12C16.875 11.385 17.385 10.875 18 10.875C18.615 10.875 19.125 11.385 19.125 12V19.5C19.125 20.115 18.615 20.625 18 20.625C17.385 20.625 16.875 20.115 16.875 19.5V12ZM19.38 24.57C19.305 24.765 19.2 24.915 19.065 25.065C18.915 25.2 18.75 25.305 18.57 25.38C18.39 25.455 18.195 25.5 18 25.5C17.805 25.5 17.61 25.455 17.43 25.38C17.25 25.305 17.085 25.2 16.935 25.065C16.8 24.915 16.695 24.765 16.62 24.57C16.545 24.39 16.5 24.195 16.5 24C16.5 23.805 16.545 23.61 16.62 23.43C16.695 23.25 16.8 23.085 16.935 22.935C17.085 22.8 17.25 22.695 17.43 22.62C17.79 22.47 18.21 22.47 18.57 22.62C18.75 22.695 18.915 22.8 19.065 22.935C19.2 23.085 19.305 23.25 19.38 23.43C19.455 23.61 19.5 23.805 19.5 24C19.5 24.195 19.455 24.39 19.38 24.57Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const Forex = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.8102 6.17977L19.5602 3.08977C18.7052 2.77477 17.3102 2.77477 16.4552 3.08977L8.2052 6.17977C6.6152 6.77977 5.3252 8.63977 5.3252 10.3348V22.4848C5.3252 23.6998 6.1202 25.3048 7.0952 26.0248L15.3452 32.1898C16.8002 33.2848 19.1852 33.2848 20.6402 32.1898L28.8902 26.0248C29.8652 25.2898 30.6602 23.6998 30.6602 22.4848V10.3348C30.6752 8.63977 29.3852 6.77977 27.8102 6.17977ZM23.2202 14.5798L16.7702 21.0298C16.5452 21.2548 16.2602 21.3598 15.9752 21.3598C15.6902 21.3598 15.4052 21.2548 15.1802 21.0298L12.7802 18.5998C12.3452 18.1648 12.3452 17.4448 12.7802 17.0098C13.2152 16.5748 13.9352 16.5748 14.3702 17.0098L15.9902 18.6298L21.6452 12.9748C22.0802 12.5398 22.8002 12.5398 23.2352 12.9748C23.6702 13.4098 23.6702 14.1448 23.2202 14.5798Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const Global = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_27463_22106)">
          <path
            d="M11.4748 31.3648C11.4298 31.3648 11.3698 31.3948 11.3248 31.3948C8.41484 29.9548 6.04484 27.5698 4.58984 24.6598C4.58984 24.6148 4.61984 24.5548 4.61984 24.5098C6.44984 25.0498 8.33984 25.4548 10.2148 25.7698C10.5448 27.6598 10.9348 29.5348 11.4748 31.3648Z"
            fill="#6699FF"
          />
          <path
            d="M31.4101 24.6748C29.9251 27.6598 27.4501 30.0748 24.4351 31.5298C25.0051 29.6248 25.4851 27.7048 25.8001 25.7698C27.6901 25.4548 29.5501 25.0498 31.3801 24.5098C31.3651 24.5698 31.4101 24.6298 31.4101 24.6748Z"
            fill="#6699FF"
          />
          <path
            d="M31.5301 11.5652C29.6401 10.9952 27.7351 10.5302 25.8001 10.2002C25.4851 8.26522 25.0201 6.34521 24.4351 4.47021C27.5401 5.95521 30.0451 8.46022 31.5301 11.5652Z"
            fill="#6699FF"
          />
          <path
            d="M11.4752 4.63484C10.9352 6.46484 10.5452 8.32484 10.2302 10.2148C8.29522 10.5148 6.37521 10.9948 4.47021 11.5648C5.92521 8.54984 8.34022 6.07484 11.3252 4.58984C11.3702 4.58984 11.4302 4.63484 11.4752 4.63484Z"
            fill="#6699FF"
          />
          <path
            d="M23.2351 9.885C19.7551 9.495 16.2451 9.495 12.7651 9.885C13.1401 7.83 13.6201 5.775 14.2951 3.795C14.3251 3.675 14.3101 3.585 14.3251 3.465C15.5101 3.18 16.7251 3 18.0001 3C19.2601 3 20.4901 3.18 21.6601 3.465C21.6751 3.585 21.6751 3.675 21.7051 3.795C22.3801 5.79 22.8601 7.83 23.2351 9.885Z"
            fill="#6699FF"
          />
          <path
            d="M9.885 23.2351C7.815 22.8601 5.775 22.3801 3.795 21.7051C3.675 21.6751 3.585 21.6901 3.465 21.6751C3.18 20.4901 3 19.2751 3 18.0001C3 16.7401 3.18 15.5101 3.465 14.3401C3.585 14.3251 3.675 14.3251 3.795 14.2951C5.79 13.6351 7.815 13.1401 9.885 12.7651C9.51 16.2451 9.51 19.7551 9.885 23.2351Z"
            fill="#6699FF"
          />
          <path
            d="M33.0002 18.0001C33.0002 19.2751 32.8202 20.4901 32.5352 21.6751C32.4152 21.6901 32.3252 21.6751 32.2052 21.7051C30.2102 22.3651 28.1702 22.8601 26.1152 23.2351C26.5052 19.7551 26.5052 16.2451 26.1152 12.7651C28.1702 13.1401 30.2252 13.6201 32.2052 14.2951C32.3252 14.3251 32.4152 14.3401 32.5352 14.3401C32.8202 15.5251 33.0002 16.7401 33.0002 18.0001Z"
            fill="#6699FF"
          />
          <path
            d="M23.2351 26.1152C22.8601 28.1852 22.3801 30.2252 21.7051 32.2052C21.6751 32.3252 21.6751 32.4152 21.6601 32.5352C20.4901 32.8202 19.2601 33.0002 18.0001 33.0002C16.7251 33.0002 15.5101 32.8202 14.3251 32.5352C14.3101 32.4152 14.3251 32.3252 14.2951 32.2052C13.6351 30.2102 13.1401 28.1852 12.7651 26.1152C14.5051 26.3102 16.2451 26.4452 18.0001 26.4452C19.7551 26.4452 21.5101 26.3102 23.2351 26.1152Z"
            fill="#6699FF"
          />
          <path
            d="M23.645 23.645C19.8934 24.1183 16.1066 24.1183 12.355 23.645C11.8817 19.8934 11.8817 16.1066 12.355 12.355C16.1066 11.8817 19.8934 11.8817 23.645 12.355C24.1183 16.1066 24.1183 19.8934 23.645 23.645Z"
            fill="#6699FF"
          />
        </g>
        <defs>
          <clipPath id="clip0_27463_22106">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export const RiskGuru = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.285 3H11.715C6.255 3 3 6.255 3 11.715V24.27C3 29.745 6.255 33 11.715 33H24.27C29.73 33 32.985 29.745 32.985 24.285V11.715C33 6.255 29.745 3 24.285 3ZM25.17 14.55L16.665 23.055C16.455 23.265 16.17 23.385 15.87 23.385C15.57 23.385 15.285 23.265 15.075 23.055L10.83 18.81C10.395 18.375 10.395 17.655 10.83 17.22C11.265 16.785 11.985 16.785 12.42 17.22L15.87 20.67L23.58 12.96C24.015 12.525 24.735 12.525 25.17 12.96C25.605 13.395 25.605 14.1 25.17 14.55Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};
export const DayTrader = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5 23.25C22.5 28.635 18.135 33 12.75 33C7.365 33 3 28.635 3 23.25C3 17.865 7.365 13.5 12.75 13.5C13.005 13.5 13.275 13.515 13.53 13.53C18.285 13.905 22.095 17.715 22.47 22.47C22.485 22.725 22.5 22.995 22.5 23.25Z"
          fill="#6699FF"
        />
        <path
          d="M33.0001 12.75C33.0001 17.64 29.4001 21.675 24.7201 22.38V22.29C24.2551 16.47 19.5301 11.745 13.6651 11.28H13.6201C14.3251 6.6 18.3601 3 23.2501 3C28.6351 3 33.0001 7.365 33.0001 12.75Z"
          fill="#6699FF"
        />
        <path
          d="M8.385 3H4.5C3.675 3 3 3.675 3 4.5V8.385C3 9.72 4.62 10.395 5.565 9.45L9.45 5.565C10.38 4.62 9.72 3 8.385 3Z"
          fill="#6699FF"
        />
        <path
          d="M27.6151 32.9999H31.5001C32.3251 32.9999 33.0001 32.3249 33.0001 31.4999V27.6149C33.0001 26.2799 31.3801 25.6049 30.4351 26.5499L26.5501 30.4349C25.6201 31.3799 26.2801 32.9999 27.6151 32.9999Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const MarketExposer = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.4999 2.98486C9.04486 2.98486 2.98486 9.04486 2.98486 16.4999C2.98486 23.9549 9.04486 30.0149 16.4999 30.0149C23.9549 30.0149 30.0149 23.9549 30.0149 16.4999C30.0149 9.04486 23.9549 2.98486 16.4999 2.98486ZM16.4999 19.8749H11.9999C11.3849 19.8749 10.8749 19.3649 10.8749 18.7499C10.8749 18.1349 11.3849 17.6249 11.9999 17.6249H16.4999C17.1149 17.6249 17.6249 18.1349 17.6249 18.7499C17.6249 19.3649 17.1149 19.8749 16.4999 19.8749ZM20.9999 15.3749H11.9999C11.3849 15.3749 10.8749 14.8649 10.8749 14.2499C10.8749 13.6349 11.3849 13.1249 11.9999 13.1249H20.9999C21.6149 13.1249 22.1249 13.6349 22.1249 14.2499C22.1249 14.8649 21.6149 15.3749 20.9999 15.3749Z"
          fill="#6699FF"
        />
        <path
          d="M32.9851 28.425C32.4901 27.51 31.4401 27 30.0301 27C28.9651 27 28.0501 27.435 27.5101 28.185C26.9701 28.935 26.8501 29.94 27.1801 30.945C27.8251 32.895 28.9501 33.33 29.5651 33.405C29.6551 33.42 29.7451 33.42 29.8501 33.42C30.5101 33.42 31.5301 33.135 32.5201 31.65C33.3151 30.495 33.4651 29.34 32.9851 28.425Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const ProfitWizard = () => {
  return (
    <>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.8749 12.1201V16.4101L15.3599 15.8851C14.5949 15.6151 14.1299 15.3601 14.1299 14.0551C14.1299 12.9901 14.9249 12.1201 15.8999 12.1201H16.8749Z"
          fill="#6699FF"
        />
        <path
          d="M21.87 21.9448C21.87 23.0098 21.075 23.8798 20.1 23.8798H19.125V19.5898L20.64 20.1148C21.405 20.3848 21.87 20.6398 21.87 21.9448Z"
          fill="#6699FF"
        />
        <path
          d="M24.285 3H11.715C6.255 3 3 6.255 3 11.715V24.285C3 29.745 6.255 33 11.715 33H24.285C29.745 33 33 29.745 33 24.285V11.715C33 6.255 29.745 3 24.285 3ZM21.39 18C22.56 18.405 24.12 19.26 24.12 21.945C24.12 24.255 22.32 26.13 20.1 26.13H19.125V27C19.125 27.615 18.615 28.125 18 28.125C17.385 28.125 16.875 27.615 16.875 27V26.13H16.335C13.875 26.13 11.88 24.045 11.88 21.495C11.88 20.88 12.375 20.37 13.005 20.37C13.62 20.37 14.13 20.88 14.13 21.495C14.13 22.815 15.12 23.88 16.335 23.88H16.875V18.795L14.61 18C13.44 17.595 11.88 16.74 11.88 14.055C11.88 11.745 13.68 9.87 15.9 9.87H16.875V9C16.875 8.385 17.385 7.875 18 7.875C18.615 7.875 19.125 8.385 19.125 9V9.87H19.665C22.125 9.87 24.12 11.955 24.12 14.505C24.12 15.12 23.625 15.63 22.995 15.63C22.38 15.63 21.87 15.12 21.87 14.505C21.87 13.185 20.88 12.12 19.665 12.12H19.125V17.205L21.39 18Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const Download = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.333 13.587H23.4797C20.3197 13.587 17.7463 11.0137 17.7463 7.85366V4.00033C17.7463 3.26699 17.1463 2.66699 16.413 2.66699H10.7597C6.65301 2.66699 3.33301 5.33366 3.33301 10.0937V21.907C3.33301 26.667 6.65301 29.3337 10.7597 29.3337H21.2397C25.3463 29.3337 28.6663 26.667 28.6663 21.907V14.9203C28.6663 14.187 28.0663 13.587 27.333 13.587ZM16.373 21.0403L13.7063 23.707C13.613 23.8003 13.493 23.8803 13.373 23.9203C13.253 23.9737 13.133 24.0003 12.9997 24.0003C12.8663 24.0003 12.7463 23.9737 12.6263 23.9203C12.5197 23.8803 12.413 23.8003 12.333 23.7203C12.3197 23.707 12.3063 23.707 12.3063 23.6937L9.63967 21.027C9.25301 20.6403 9.25301 20.0003 9.63967 19.6137C10.0263 19.227 10.6663 19.227 11.053 19.6137L11.9997 20.587V15.0003C11.9997 14.4537 12.453 14.0003 12.9997 14.0003C13.5463 14.0003 13.9997 14.4537 13.9997 15.0003V20.587L14.9597 19.627C15.3463 19.2403 15.9863 19.2403 16.373 19.627C16.7597 20.0137 16.7597 20.6537 16.373 21.0403Z"
          fill="#6699FF"
        />
        <path
          d="M23.2399 11.7463C24.5066 11.7597 26.2666 11.7597 27.7732 11.7597C28.5332 11.7597 28.9332 10.8663 28.3999 10.333C26.4799 8.39967 23.0399 4.91967 21.0666 2.94633C20.5199 2.39967 19.5732 2.773 19.5732 3.533V8.18633C19.5732 10.133 21.2266 11.7463 23.2399 11.7463Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const Lock = () => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25 10.667V13.467C24.4133 13.387 23.7467 13.347 23 13.3337V10.667C23 6.46699 21.8133 3.66699 16 3.66699C10.1867 3.66699 9 6.46699 9 10.667V13.3337C8.25333 13.347 7.58667 13.387 7 13.467V10.667C7 6.80033 7.93333 1.66699 16 1.66699C24.0667 1.66699 25 6.80033 25 10.667Z"
          fill="white"
        />
        <path
          d="M25.0003 13.4663C24.4137 13.3863 23.747 13.3463 23.0003 13.333H9.00033C8.25366 13.3463 7.58699 13.3863 7.00033 13.4663C3.60033 13.8797 2.66699 15.5463 2.66699 19.9997V22.6663C2.66699 27.9997 4.00033 29.333 9.33366 29.333H22.667C28.0003 29.333 29.3337 27.9997 29.3337 22.6663V19.9997C29.3337 15.5463 28.4003 13.8797 25.0003 13.4663ZM11.6137 22.2797C11.3603 22.5197 11.0137 22.6663 10.667 22.6663C10.4937 22.6663 10.3203 22.6263 10.1603 22.5597C9.98699 22.493 9.85366 22.3997 9.72033 22.2797C9.48033 22.0263 9.33366 21.6797 9.33366 21.333C9.33366 21.1597 9.37366 20.9863 9.44033 20.8263C9.50699 20.6663 9.60033 20.5197 9.72033 20.3863C9.85366 20.2663 9.98699 20.173 10.1603 20.1063C10.6537 19.893 11.2403 20.013 11.6137 20.3863C11.7337 20.5197 11.827 20.6663 11.8937 20.8263C11.9603 20.9863 12.0003 21.1597 12.0003 21.333C12.0003 21.6797 11.8537 22.0263 11.6137 22.2797ZM17.227 21.8397C17.1603 21.9997 17.067 22.1463 16.947 22.2797C16.6937 22.5197 16.347 22.6663 16.0003 22.6663C15.6403 22.6663 15.307 22.5197 15.0537 22.2797C14.9337 22.1463 14.8403 21.9997 14.7737 21.8397C14.707 21.6797 14.667 21.5063 14.667 21.333C14.667 20.973 14.8137 20.6397 15.0537 20.3863C15.547 19.893 16.4403 19.893 16.947 20.3863C17.187 20.6397 17.3337 20.973 17.3337 21.333C17.3337 21.5063 17.2937 21.6797 17.227 21.8397ZM22.2803 22.2797C22.027 22.5197 21.6803 22.6663 21.3337 22.6663C20.987 22.6663 20.6403 22.5197 20.387 22.2797C20.147 22.0263 20.0003 21.693 20.0003 21.333C20.0003 20.973 20.147 20.6397 20.387 20.3863C20.8937 19.893 21.787 19.893 22.2803 20.3863C22.3337 20.453 22.387 20.5197 22.4403 20.5997C22.4937 20.6663 22.5337 20.7463 22.5603 20.8263C22.6003 20.9063 22.627 20.9863 22.6403 21.0663C22.6537 21.1597 22.667 21.253 22.667 21.333C22.667 21.6797 22.5203 22.0263 22.2803 22.2797Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export const UnLockMedal = () => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="48" height="48" rx="24" fill="#F4FDEE" />
        <path
          d="M34.0136 15.8003L26.0936 11.227C24.8002 10.4803 23.2002 10.4803 21.8936 11.227L13.9869 15.8003C12.6936 16.547 11.8936 17.9337 11.8936 19.4403V28.5603C11.8936 30.0537 12.6936 31.4403 13.9869 32.2003L21.9069 36.7737C23.2002 37.5203 24.8002 37.5203 26.1069 36.7737L34.0269 32.2003C35.3202 31.4537 36.1202 30.067 36.1202 28.5603V19.4403C36.1069 17.9337 35.3069 16.5603 34.0136 15.8003ZM24.0002 17.787C25.7202 17.787 27.1069 19.1737 27.1069 20.8937C27.1069 22.6137 25.7202 24.0003 24.0002 24.0003C22.2802 24.0003 20.8936 22.6137 20.8936 20.8937C20.8936 19.187 22.2802 17.787 24.0002 17.787ZM27.5736 30.2137H20.4269C19.3469 30.2137 18.7202 29.0137 19.3202 28.1203C20.2269 26.7737 21.9869 25.867 24.0002 25.867C26.0136 25.867 27.7736 26.7737 28.6802 28.1203C29.2802 29.0003 28.6402 30.2137 27.5736 30.2137Z"
          fill="#65D31D"
        />
      </svg>
    </>
  );
};

export const LockMedal = () => {
  return (
    <>
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="48" height="48" rx="24" fill="#EDF4FF" />
        <path
          d="M36.3337 32.6265L34.1337 33.1465C33.6403 33.2665 33.2537 33.6398 33.147 34.1332L32.6803 36.0932C32.427 37.1598 31.067 37.4932 30.3603 36.6532L26.3737 32.0665C26.0537 31.6932 26.227 31.1065 26.707 30.9865C29.067 30.4132 31.187 29.0932 32.747 27.2132C33.0003 26.9065 33.4537 26.8665 33.7337 27.1465L36.6937 30.1065C37.707 31.1198 37.347 32.3865 36.3337 32.6265Z"
          fill="#6699FF"
        />
        <path
          d="M11.6002 32.6265L13.8002 33.1465C14.2936 33.2665 14.6802 33.6398 14.7869 34.1332L15.2536 36.0932C15.5069 37.1598 16.8669 37.4932 17.5736 36.6532L21.5602 32.0665C21.8802 31.6932 21.7069 31.1065 21.2269 30.9865C18.8669 30.4132 16.7469 29.0932 15.1869 27.2132C14.9336 26.9065 14.4802 26.8665 14.2002 27.1465L11.2402 30.1065C10.2269 31.1198 10.5869 32.3865 11.6002 32.6265Z"
          fill="#6699FF"
        />
        <path
          d="M24.0003 10.667C18.8403 10.667 14.667 14.8403 14.667 20.0003C14.667 21.9337 15.2403 23.707 16.227 25.187C17.667 27.3203 19.947 28.827 22.6003 29.2137C23.0537 29.2937 23.5203 29.3337 24.0003 29.3337C24.4803 29.3337 24.947 29.2937 25.4003 29.2137C28.0537 28.827 30.3337 27.3203 31.7737 25.187C32.7603 23.707 33.3337 21.9337 33.3337 20.0003C33.3337 14.8403 29.1603 10.667 24.0003 10.667ZM28.0803 19.707L26.9737 20.8137C26.787 21.0003 26.6803 21.3603 26.747 21.627L27.067 23.0003C27.3203 24.0803 26.747 24.507 25.787 23.9337L24.4537 23.147C24.2137 23.0003 23.8137 23.0003 23.5737 23.147L22.2403 23.9337C21.2803 24.4937 20.707 24.0803 20.9603 23.0003L21.2803 21.627C21.3337 21.3737 21.2403 21.0003 21.0537 20.8137L19.9203 19.707C19.267 19.0537 19.4803 18.4003 20.387 18.2537L21.8137 18.0137C22.0537 17.9737 22.3337 17.7603 22.4403 17.547L23.227 15.9737C23.6537 15.1203 24.347 15.1203 24.7737 15.9737L25.5603 17.547C25.667 17.7603 25.947 17.9737 26.2003 18.0137L27.627 18.2537C28.5203 18.4003 28.7337 19.0537 28.0803 19.707Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};
