import React from "react";
import more from "../../Assets/Home/more.svg";
import challengefailed from "../../Assets/Home/challengefailed.svg";
import payoutconfirmed from "../../Assets/Home/payoutconfirmed.svg";
import Competitionstarted from "../../Assets/Home/Competitionstarted.svg";
import Evaluationpassed from "../../Assets/Home/Evaluationpassed.svg";
import { ChevronDown } from "lucide-react";

const ActivityFeed = () => {
  return (
    <div className="mt-3 border border-childBorder bg-background  rounded-2xl p-5">
      <div className="flex justify-between">
        <div className="lg:text-[24px] font-semibold">Activity Feed</div>
        <div className="flex gap-x-4 items-center">
          <div className="relative w-[136px] text-[#6699FF] dark:text-white">
            <select className="bg-iconBackground  border border-childBorder outline-none w-full h-[30px] text-btnTextColor rounded-lg pl-2 pr-8 appearance-none">
              <option value="30">Last 30 days</option>
              <option value="7">Last 7 days</option>
              <option value="1">Last 1 day</option>
            </select>
            <div className="absolute inset-y-0 right-2 flex items-center pointer-events-none">
              <ChevronDown size={20} />
            </div>
          </div>

          <div>
            <img src={more} alt="" />
          </div>
        </div>
      </div>
      <div>
        <div className="mt-5">
          <div className="flex justify-center items-center">
            <hr className="flex-1 border-childBorder" />
            <div className="border-childBorder border rounded-2xl w-[65px] h-[30px] font-semibold text-[13px] text-center flex justify-center items-center">
              {" "}
              Today
            </div>
            <hr className="flex-1 border-childBorder" />
          </div>
        </div>

        <div className="relative mt-5">
          {/* Vertical Line */}
          <div className="absolute left-[19px] top-0 h-full border-l border-childBorder"></div>

          {/* Timeline Items */}
          <div className="space-y-10">
            {/* Item 1 */}
            <div className="relative flex items-start space-x-4">
              <div className="flex-shrink-0 bg-background  z-10">
                <img src={challengefailed} alt="" className="rounded-full" />
              </div>
              <div>
                <p className="font-semibold ">Challenge Failed</p>
                <p className="text-sm text-[#6C6C6C]">12 min ago</p>
                <p className="text-[14px]">
                  <span className="font-semibold">Account #12219</span>{" "}
                  <span className="text-[#6C6C6C]">has been</span>{" "}
                  <span className="text-[#FF4D49] font-semibold">
                    Closed due
                  </span>{" "}
                  <span className="text-[#6C6C6C]">
                    to a maximum drawdown breach
                  </span>
                </p>
              </div>
            </div>

            {/* Item 2 */}
            <div className="relative flex items-start space-x-4">
              <div className="flex-shrink-0 bg-background  z-10">
                <img src={payoutconfirmed} alt="" className="rounded-full" />
              </div>
              <div>
                <p className="font-semibold ">Payout Confirmed</p>
                <p className="text-sm text-gray-600">
                  Your Payout request for Account #17372 has been Approved.
                </p>
                <p className="text-sm text-gray-600">$1,520.00 USD - Wise</p>
                <p className="text-xs text-gray-400">15 min ago</p>
              </div>
            </div>

            {/* Item 3 */}
            <div className="relative flex items-start space-x-4">
              <div className="flex-shrink-0 bg-background  z-10">
                <img src={Competitionstarted} alt="" className="rounded-full" />
              </div>
              <div>
                <p className="font-semibold ">Competition Started</p>
                <p className="text-sm text-gray-600">October Competition</p>
                <p className="text-sm text-gray-600">
                  10K Challenge - Deadline: 28/10/23
                </p>
                <p className="text-xs text-gray-400">45 min ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-5">
          <div className="flex justify-center items-center">
            <hr className="flex-1 border-childBorder" />
            <div className="border-childBorder border rounded-2xl w-[86px] h-[30px] font-semibold text-[13px] text-center flex justify-center items-center">
              {" "}
              Yesterday
            </div>
            <hr className="flex-1 border-childBorder" />
          </div>
        </div>

        <div className="relative mt-5">
          {/* Vertical Line */}
          <div className="absolute left-[19px] top-0 h-full border-l border-childBorder"></div>

          {/* Timeline Items */}
          <div className="space-y-10">
            {/* Item 1 */}
            <div className="relative flex items-start space-x-4">
              <div className="flex-shrink-0 bg-background  z-10">
                <img src={Evaluationpassed} alt="" className="rounded-full" />
              </div>
              <div>
                <p className="font-semibold ">Evaluation Passed</p>
                <p className="text-sm text-[#6C6C6C]">12 min ago</p>
                <p className="text-[14px]">
                  <span className="font-semibold">Account #12219</span>{" "}
                  <span className="text-[#6C6C6C]">has been</span>{" "}
                  <span className="text-[#FF4D49] font-semibold">
                    Closed due
                  </span>{" "}
                  <span className="text-[#6C6C6C]">
                    to a maximum drawdown breach
                  </span>
                </p>
              </div>
            </div>

            {/* Item 2 */}
            <div className="relative flex items-start space-x-4">
              <div className="flex-shrink-0 bg-background  z-10">
                <img src={payoutconfirmed} alt="" className="rounded-full" />
              </div>
              <div>
                <p className="font-semibold ">Payout Confirmed</p>
                <p className="text-sm text-gray-600">
                  Your Payout request for Account #17372 has been Approved.
                </p>
                <p className="text-sm text-gray-600">$1,520.00 USD - Wise</p>
                <p className="text-xs text-gray-400">15 min ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mt-5">
          <div className="flex justify-center items-center">
            <hr className="flex-1 border-childBorder" />
            <div className="border-childBorder border rounded-2xl w-[86px] h-[30px] font-semibold text-[13px] text-center flex justify-center items-center">
              {" "}
              Week ago
            </div>
            <hr className="flex-1 border-childBorder" />
          </div>
        </div>

        <div className="relative mt-5">
          {/* Vertical Line */}
          <div className="absolute left-[19px] top-0 h-full border-l border-childBorder"></div>

          {/* Timeline Items */}
          <div className="space-y-10">
            {/* Item 1 */}
            <div className="relative flex items-start space-x-4">
              <div className="flex-shrink-0 bg-background  z-10">
                <img src={challengefailed} alt="" className="rounded-full" />
              </div>
              <div>
                <p className="font-semibold">Challenge Failed</p>
                <p className="text-sm text-[#6C6C6C]">12 min ago</p>
                <p className="text-[14px]">
                  <span className="font-semibold">Account #12219</span>{" "}
                  <span className="text-[#6C6C6C]">has been</span>{" "}
                  <span className="text-[#FF4D49] font-semibold">
                    Closed due
                  </span>{" "}
                  <span className="text-[#6C6C6C]">
                    to a maximum drawdown breach
                  </span>
                </p>
              </div>
            </div>

            {/* Item 2 */}
            <div className="relative flex items-start space-x-4">
              <div className="flex-shrink-0 bg-background  z-10">
                <img src={Competitionstarted} alt="" className="rounded-full" />
              </div>
              <div>
                <p className="font-semibold ">Competition Started</p>
                <p className="text-sm ">October Competition</p>
                <p className="text-sm ">10K Challenge - Deadline: 28/10/23</p>
                <p className="text-xs text-gray-400">45 min ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityFeed;
