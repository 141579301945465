import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import { Link } from "react-router-dom";
import ForgetPassword from "../Popups/ForgetPassword";
// import TwoStepVerification from "../Popups/TwoStepVerification";
import Logincarousel from "../Logincarousel/Logincarousel";
import images from "../../Assets/Assets";
// import ResetPassword from "../Popups/ResetPassword";
// import Verifyemail from "../Popups/Verifyemail";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  // const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState(false);
  const [isForgetPasswordOpen, setIsForgetPasswordOpen] = useState(false);
  // const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);
  // const [isTwoStepOpen, setIsTwoStepOpen] = useState(false);

  // const handleTwoStepOpen = () => {
  //   setIsTwoStepOpen(true);
  // };

  // const handleTwoStepClose = () => {
  //   setIsTwoStepOpen(false);
  // };

  // const openVerifyEmailPopup = () => {
  //   setIsVerifyEmailOpen(true);
  // };

  // const closeVerifyEmailPopup = () => {
  //   setIsVerifyEmailOpen(false);
  // };

  const handleForgetPasswordOpen = () => {
    setIsForgetPasswordOpen(true);
  };

  const handleForgetPasswordClose = () => {
    setIsForgetPasswordOpen(false);
  };

  // const handleResetPasswordOpen = () => {
  //   setIsResetPasswordOpen(true);
  // };

  // const handleResetPasswordClose = () => {
  //   setIsResetPasswordOpen(false);
  // };
  return (
    <div className="lg:flex lg:justify-between">
      <section className="hidden lg:block  lg:w-[843px]">
        <div
          className="w-full h-[100vh] bg-cover bg-center text-white lg:flex flex-col justify-center items-center relative"
          style={{ backgroundImage: `url(${images.background})` }}
        >
          <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
          <div className="absolute top-10 left-10 z-20">
            <img src={images.logo} alt="logo" />
          </div>
          <div className="font-semibold w-[563px] text-center z-20 mt-20">
            <div className="text-[58px] leading-[56px] text-white">
              Manage your Money Anywhere
            </div>
            <div className="text-[#FFFFFF] text-[16px] text-center leading-[24px] mt-5">
              Embarking on the journey of investment just a financial decision
              is a commitment to your future prosperity.
            </div>
            <div className="mt-12 w-full overflow-visible">
              <Logincarousel />
            </div>
          </div>
        </div>
      </section>

      <section className="w-full lg:w-1/2 flex justify-center items-center">
        <div className="w-[90%] sm:w-[397px] flex flex-col gap-y-5 mx-auto">
          <div className="md:hidden pt-5">
            <img src={images.logo} alt="logo" />
          </div>
          <div>
            <div className="font-semibold dark:text-white lg:text-[34px] text-[#2B2A2A] lg:text-center text-left text-[24px]">
              Welcome to Propmodel
            </div>
            <div className="text-[#6C6C6C] dark:text-[#A4A4A4] text-[14px] font-normal lg:text-center text-left mt-2">
              Please sign-in to your account to start trading
            </div>
          </div>
          <form className="flex flex-col gap-y-5">
            <input
              className="h-[56px] w-full dark:border-[#424346] dark:bg-[#121212] rounded-lg border border-[#D0D5DD] pl-5 outline-none placeholder-style"
              placeholder="Email"
            />
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                className="h-[56px] w-full rounded-lg border dark:border-[#424346] dark:bg-[#121212] border-[#D0D5DD] pl-5 pr-12 outline-none placeholder-style"
                placeholder="Password"
              />
              <div
                className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeOff className="text-gray-500" />
                ) : (
                  <Eye className="text-gray-500" />
                )}
              </div>
            </div>
            <div className="flex justify-between text-[16px]">
              <div className="flex gap-2">
                <input type="checkbox" />
                Remember Me
              </div>
              <div
                className="text-[#6699FF] cursor-pointer"
                onClick={handleForgetPasswordOpen}
              >
                Forgot Password?
              </div>
              <ForgetPassword
                isOpen={isForgetPasswordOpen}
                onClose={handleForgetPasswordClose}
              />
            </div>
            <div>
              <Link to={"/home"}>
                <button
                  type="button"
                  className="h-[44px] w-full rounded-lg bg-[#6699FF] text-white font-semibold text-[16px]"
                >
                  Login
                </button>
              </Link>
            </div>
            <div className="text-[#6C6C6C] text-center">
              New to our platform?{" "}
              <Link to={"/signup"}>
                <span className="text-[#6699FF] font-semibold  cursor-pointer">
                  Create an account
                </span>
              </Link>
            </div>
          </form>
          <div className="flex items-center gap-4">
            <hr className="flex-grow border-childBorder" />
            <span className="text-gray-500">or</span>
            <hr className="flex-grow border-childBorder" />
          </div>
          <div className="flex gap-x-3 justify-center">
            {[
              images.facebook,
              images.google,
              images.linkdin,
              images.xsocialmedia,
            ].map((icon, index) => (
              <div
                key={index}
                className="border-[#D0D5DD] dark:border-[#424346] border lg:w-[59px] lg:h-[59px] w-[40px] h-[40px] rounded-full flex justify-center items-center cursor-pointer"
              >
                <img
                  src={icon}
                  alt="social media"
                  className="lg:w-[36px] lg:h-[36px] w-[25px] h-[25px]"
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
