import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const PointsGraph = () => {
  const data = {
    labels: ["Sun", "Mon", "Tue", "Wen", "Thu", "Fri", "Sat"],
    datasets: [
      {
        label: "Balance",
        data: [4000, 12300, 13000, 12000, 14000, 5000, 2000],
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, "rgba(102, 153, 255, 0.4)");
          gradient.addColorStop(1, "rgba(102, 153, 255, 0)");

          return gradient;
        },
        borderColor: "#6699FF",
        borderWidth: 2,
        pointRadius: 2, // Point size
        pointHoverRadius: 8, // Hover radius
        pointHoverBackgroundColor: "#ffffff",
        pointHoverBorderColor: "#3B82F6",
        pointHoverBorderWidth: 2,
        tension: 0.4, // Smooth curves
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows chart to adapt to container size
    scales: {
      x: {
        ticks: {
          color: "#6B7280", // X-axis tick color
          font: {
            size: 12, // Font size
          },
        },
        grid: {
          color: "#e0e0e0",
          drawBorder: false, // Remove border line
        },
      },
      y: {
        ticks: {
          color: "#6B7280", // Y-axis tick color
          font: {
            size: 12, // Font size
          },
          stepSize: 5000,
          callback: (value) => `$${(value / 1000).toFixed(1)}k`, // Format as $Xk
        },
        grid: {
          color: "#e0e0e0",
          drawBorder: false, // Remove border line
        },
        suggestedMin: 0,
        suggestedMax: 20000,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `$${context.raw.toLocaleString()}`, // Format tooltip value
          title: (context) => `Month: ${context[0].label}`, // Format tooltip label
        },
      },
      legend: {
        display: false, // Hide legend
      },
    },
  };

  return (
    <div
      style={{
        width: "100%",
        height: "200px", // Adjust height as needed
        maxWidth: "100%",
        overflow: "hidden", // Prevent overflow
        margin: "0 auto", // Center the chart
      }}
    >
      <Line data={data} options={options} />
    </div>
  );
};

export default PointsGraph;
