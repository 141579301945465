import beginnerFriendly from "../Assets/Education/begineerfriendly.png";
import technicalAnalysis from "../Assets/Education/technicalanalysis.png";
import advancedTraining from "../Assets/Education/advancedtraining.png";
import algorithmicTrading from "../Assets/Education/algorithmictrading.png";
import cryptoTrading from "../Assets/Education/crptotrading.png";
import globalMacroTrading from "../Assets/Education/globalmacrotrading.jpeg";
import more from "../Assets/Challenge/more.svg";
import arrowdown from "../Assets/Challenge/arrow-down.svg";
import facebook from "../Assets/login/facebook.svg";
import google from "../Assets/login/google.svg";
import linkdin from "../Assets/login/linkdin.svg";
import xsocialmedia from "../Assets/login/xsocialmedia.svg";
import background from "../Assets/login/backgorund.png";
import logo from "../Assets/login/Logo.svg";



const images = {
  beginnerFriendly,
  technicalAnalysis,
  advancedTraining,
  algorithmicTrading,
  cryptoTrading,
  globalMacroTrading,
  more,
  arrowdown,
  facebook,
  google,
  linkdin,
  xsocialmedia,
  background,
  logo,
};

export default images;
