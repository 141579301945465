import { ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";
import React, { useState } from "react";
import { Flag, Left, Right } from "../../Assets/Tools/SvgTools";
import turkey from "../../Assets/Tools/turkey.png";
import CountryFilterModal from "./CountryFilterModal";

const EconomicCalendar = () => {
  const [activeTab, setActiveTab] = useState("Upcoming");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tabs = ["Upcoming", "Yesterday", "Today", "Tomorrow"];
  return (
    <div>
      <div className="lg:text-[34px]  text-[24px] font-semibold text-center">
        Economic Calendar
      </div>
      <div className="text-[#6C6C6C] lg:text-[16px] text-[14px] text-center">
        View all the upcoming economic news events.
      </div>

      <div className="w-full  rounded-2xl border border-childBorder bg-background  p-5 mt-10">
        <div className="flex lg:justify-between lg:flex-row flex-col space-y-4">
          <div className="lg:flex items-center lg:gap-x-4 ">
            <div className="text-[#2B2A2A] dark:text-white  w-full lg:w-[250px] flex justify-between gap-x-4 items-center font-semibold lg:text-[18px]  cursor-pointer">
              <div className="lg:w-[36px]   lg:h-[36px] w-[24px] h-[24px] flex justify-center items-center">
                <Left />
              </div>
              23 October 2023
              <div className="lg:w-[36px] lg:h-[36px] w-[24px] h-[24px] flex justify-center items-center">
                <Right />
              </div>
            </div>

            <div
              onClick={() => setIsModalOpen(true)}
              className="bg-[#FAFCFD] dark:bg-[#1C1C1C] dark:text-white border border-childBorder lg:mt-0 mt-4 lg:w-[148px] h-[42px] text-[13px] font-semibold text-[#6699FF] rounded-xl  flex lg:justify-center justify-between lg:px-0 px-2 items-center gap-x-2 cursor-pointer"
            >
              17:45 (UTC+8) <ChevronDown />
            </div>
            {/* Modal */}
            <CountryFilterModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          </div>
          <div>
            <div className="bg-[#F5F5F5] dark:bg-[#0D0D0D]  lg:w-[359px] h-[42px] rounded-xl flex items-center justify-center px-2">
              {tabs.map((tab) => (
                <div
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`w-[94px] h-[30px] text-[13px] dark:text-[#A4A4A4] font-semibold rounded-xl flex justify-center items-center cursor-pointer transition-all duration-200 ${
                    activeTab === tab
                      ? "text-[#6699FF] bg-white dark:bg-[#011845] dark:text-white "
                      : "text-[#2B2A2A]"
                  }`}
                >
                  {tab}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-5 w-full overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white h-[48px] uppercase bg-[#6699FF] ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Time
                </th>
                <th scope="col" className="px-6 py-3">
                  Event Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Country
                </th>
                <th scope="col" className="px-6 py-3">
                  Actual
                </th>
                <th scope="col" className="px-6 py-3">
                  Forecast
                </th>
                <th scope="col" className="px-6 py-3">
                  Previous
                </th>
                <th scope="col" className="px-6 py-3">
                  Impact
                </th>
              </tr>
            </thead>
            <tbody className="dark:text-white">
              <tr className="bg-white h-[48px]  dark:bg-gray-800 ">
                <td className="px-6 py-2">15:00</td>
                <td className="px-6 py-2 font-semibold t">
                  Consumer Confidence
                </td>

                <td
                  className="px-6 py-2 font-semibold  
                "
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={turkey}
                      alt="country"
                      className="w-[20px] h-[20px] rounded-full"
                    />
                    Turkey
                  </div>{" "}
                </td>
                <td className="px-6 py-2">86.3</td>
                <td className="px-6 py-2 ">-</td>
                <td className="px-6 py-2 ">71.5</td>
                <td className="px-6 py-2 ">
                  <Flag />
                </td>
              </tr>
              <tr className="bg-[#F6F8FE] h-[48px] dark:bg-gray-800 ">
                <td className="px-6 py-2">15:00</td>
                <td className="px-6 py-2 font-semibold ">
                  Consumer Confidence
                </td>

                <td className="px-6 py-2 font-semibold ">
                  <div className="flex items-center gap-2">
                    <img
                      src={turkey}
                      alt="country"
                      className="w-[20px] h-[20px] rounded-full"
                    />
                    Turkey
                  </div>{" "}
                </td>
                <td className="px-6 py-2">86.3</td>
                <td className="px-6 py-2 ">-</td>
                <td className="px-6 py-2 ">71.5</td>
                <td className="px-6 py-2 ">
                  <Flag />
                </td>
              </tr>
              <tr className="bg-white h-[48px]  dark:bg-gray-800 ">
                <td className="px-6 py-2">15:00</td>
                <td className="px-6 py-2 font-semibold t">
                  Consumer Confidence
                </td>

                <td
                  className="px-6 py-2 font-semibold  
                "
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={turkey}
                      alt="country"
                      className="w-[20px] h-[20px] rounded-full"
                    />
                    Turkey
                  </div>{" "}
                </td>
                <td className="px-6 py-2">86.3</td>
                <td className="px-6 py-2 ">-</td>
                <td className="px-6 py-2 ">71.5</td>
                <td className="px-6 py-2 ">
                  <Flag />
                </td>
              </tr>
              <tr className="bg-[#F6F8FE] h-[48px] dark:bg-gray-800 ">
                <td className="px-6 py-2">15:00</td>
                <td className="px-6 py-2 font-semibold ">
                  Consumer Confidence
                </td>

                <td className="px-6 py-2 font-semibold ">
                  <div className="flex items-center gap-2">
                    <img
                      src={turkey}
                      alt="country"
                      className="w-[20px] h-[20px] rounded-full"
                    />
                    Turkey
                  </div>{" "}
                </td>
                <td className="px-6 py-2">86.3</td>
                <td className="px-6 py-2 ">-</td>
                <td className="px-6 py-2 ">71.5</td>
                <td className="px-6 py-2 ">
                  <Flag />
                </td>
              </tr>
              <tr className="bg-white h-[48px]  dark:bg-gray-800 ">
                <td className="px-6 py-2">15:00</td>
                <td className="px-6 py-2 font-semibold t">
                  Consumer Confidence
                </td>

                <td
                  className="px-6 py-2 font-semibold  
                "
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={turkey}
                      alt="country"
                      className="w-[20px] h-[20px] rounded-full"
                    />
                    Turkey
                  </div>{" "}
                </td>
                <td className="px-6 py-2">86.3</td>
                <td className="px-6 py-2 ">-</td>
                <td className="px-6 py-2 ">71.5</td>
                <td className="px-6 py-2 ">
                  <Flag />
                </td>
              </tr>
              <tr className="bg-[#F6F8FE] h-[48px] dark:bg-gray-800 ">
                <td className="px-6 py-2">15:00</td>
                <td className="px-6 py-2 font-semibold ">
                  Consumer Confidence
                </td>

                <td className="px-6 py-2 font-semibold ">
                  <div className="flex items-center gap-2">
                    <img
                      src={turkey}
                      alt="country"
                      className="w-[20px] h-[20px] rounded-full"
                    />
                    Turkey
                  </div>{" "}
                </td>
                <td className="px-6 py-2">86.3</td>
                <td className="px-6 py-2 ">-</td>
                <td className="px-6 py-2 ">71.5</td>
                <td className="px-6 py-2 ">
                  <Flag />
                </td>
              </tr>
              <tr className="bg-white h-[48px]  dark:bg-gray-800 ">
                <td className="px-6 py-2">15:00</td>
                <td className="px-6 py-2 font-semibold t">
                  Consumer Confidence
                </td>

                <td
                  className="px-6 py-2 font-semibold  
                "
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={turkey}
                      alt="country"
                      className="w-[20px] h-[20px] rounded-full"
                    />
                    Turkey
                  </div>{" "}
                </td>
                <td className="px-6 py-2">86.3</td>
                <td className="px-6 py-2 ">-</td>
                <td className="px-6 py-2 ">71.5</td>
                <td className="px-6 py-2 ">
                  <Flag />
                </td>
              </tr>
              <tr className="bg-[#F6F8FE] h-[48px] dark:bg-gray-800 ">
                <td className="px-6 py-2">15:00</td>
                <td className="px-6 py-2 font-semibold ">
                  Consumer Confidence
                </td>

                <td className="px-6 py-2 font-semibold ">
                  <div className="flex items-center gap-2">
                    <img
                      src={turkey}
                      alt="country"
                      className="w-[20px] h-[20px] rounded-full"
                    />
                    Turkey
                  </div>{" "}
                </td>
                <td className="px-6 py-2">86.3</td>
                <td className="px-6 py-2 ">-</td>
                <td className="px-6 py-2 ">71.5</td>
                <td className="px-6 py-2 ">
                  <Flag />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-2">
          <div className="flex items-center gap-x-5">
            <div className="text-[14px]">1 0f 13</div>

            <div className="flex gap-x-5 items-center">
              <ChevronLeft size={20} />
              <ChevronRight size={20} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EconomicCalendar;
