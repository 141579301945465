import React, { useState } from "react";

const Profile = () => {
  // Preloaded data
  const initialData = {
    firstName: "Joshua",
    lastName: "Casey",
    email: "joshua@propmodel.co",
    phone: "+61 493 166 747",
    address: "78 Stirling St",
    city: "Perth",
    timezone: "(GMT+8:00) Perth",
    postalCode: "6000",
    state: "Western Australia",
    country: "Australia",
  };

  const [formData, setFormData] = useState(initialData);
  const [isEditing, setIsEditing] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false); // State for SMS verification popup
  const [smsCode, setSmsCode] = useState(""); // State to hold the SMS code input
  const [generatedCode] = useState("123456"); // Simulated SMS code (you would generate this dynamically in real life)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    setIsVerifying(true); // Show SMS verification popup
  };

  const handleCancel = () => {
    setFormData(initialData); // Reset to preloaded data
    setIsEditing(false);
  };

  const handleSmsVerification = () => {
    if (smsCode === generatedCode) {
      // If SMS code matches, save the changes
      setIsVerifying(false);
      setIsEditing(false);
      console.log("Changes saved:", formData);
    } else {
      alert("Invalid SMS code. Please try again.");
    }
  };

  const handleSmsCancel = () => {
    setIsVerifying(false); // Close the verification popup
  };

  return (
    <div>
      <div className="bg-background border-childBorder  rounded-2xl lg:w-[768px] p-5 ">
        <div className="font-semibold text-[20px]">Profile Information</div>

        <div>
          <div className="mt-5">
            <form>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5">
                <input
                  className="w-full h-[56px] dark:bg-[#121212] dark:border-[#424346] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="Joshua"
                  disabled={!isEditing}
                />
                <input
                  className="w-full h-[56px] rounded-xl dark:bg-[#121212] dark:border-[#424346] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Casey"
                  disabled={!isEditing}
                />
                <input
                  className="w-full h-[56px] rounded-xl dark:bg-[#121212] dark:border-[#424346] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="joshua@propmodel.co"
                  disabled={!isEditing}
                />
                <input
                  className="w-full h-[56px] rounded-xl dark:bg-[#121212] dark:border-[#424346] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="+61 493 166 747"
                  disabled={!isEditing}
                />
              </div>

              <hr className="my-5 dark:border-[#252525]" />

              <div className="grid grid-cols-1 sm:grid-cols-1 gap-5">
                <input
                  className="w-full h-[56px] rounded-xl dark:bg-[#121212] dark:border-[#424346] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  placeholder="78 Stirling St"
                  disabled={!isEditing}
                />
                <input
                  className="w-full h-[56px] rounded-xl dark:bg-[#121212] dark:border-[#424346] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="Perth"
                  disabled={!isEditing}
                />
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
                <input
                  className="w-full h-[56px] rounded-xl dark:bg-[#121212] dark:border-[#424346] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
                  name="timezone"
                  value={formData.timezone}
                  onChange={handleChange}
                  placeholder="(GMT+8:00) Perth"
                  disabled={!isEditing}
                />
                <input
                  className="w-full h-[56px] rounded-xl dark:bg-[#121212] dark:border-[#424346] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleChange}
                  placeholder="6000"
                  disabled={!isEditing}
                />
                <input
                  className="w-full h-[56px] rounded-xl dark:bg-[#121212] dark:border-[#424346] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  placeholder="Western Australia"
                  disabled={!isEditing}
                />
                <input
                  className="w-full h-[56px] rounded-xl dark:bg-[#121212] dark:border-[#424346] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  placeholder="Australia"
                  disabled={!isEditing}
                />
              </div>

              <hr className="my-5 dark:border-[#252525]" />

              <div className="flex justify-end gap-3">
                {isEditing ? (
                  <>
                    <button
                      type="button"
                      onClick={handleCancel}
                      className="bg-[#FAFCFD] border-[#EAEAEC] dark:bg-[#121212] dark:border-[#424346] border w-[122px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleSave}
                      className="bg-[#6699FF] w-[146px] h-[38px] rounded-lg text-white font-semibold"
                    >
                      Save Changes
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={handleEditToggle}
                    className="bg-[#6699FF] w-[122px] h-[38px] rounded-lg text-white font-semibold"
                  >
                    Edit Profile
                  </button>
                )}
              </div>
            </form>

            {isVerifying && (
              <div className="fixed inset-0 pt-5 flex lg:justify-center lg:items-center justify-end flex-col bg-gray-500 bg-opacity-50 z-50">
                <div className="bg-white dark:bg-[#121212]  p-6 lg:rounded-lg rounded-t-2xl lg:w-[35%] w-full">
                  <div className="flex flex-col justify-center items-center">
                    <div className="lg:text-[34px] text-[20px] font-semibold ">
                      Verify Your SMS Code
                    </div>
                    <div className="text-[#6C6C6C] text-center mb-4 lg:w-[350px] lg:text-[16px] text-[14px]">
                      Enter the verification code sent to your mobile. Didn't
                      get the code?{" "}
                      <span className="text-[#6699FF] font-semibold">
                        {" "}
                        Resend{" "}
                      </span>
                    </div>
                  </div>

                  <input
                    type="text"
                    className="w-full h-[56px] rounded-xl dark:bg-[#121212] dark:border-[#424346] border-[#D0D5DD] border pl-2 mb-4 outline-none text-[#6C6C6C]"
                    placeholder="Verification Code"
                    value={smsCode}
                    onChange={(e) => setSmsCode(e.target.value)}
                  />
                  <hr className="my-5 dark:border-[#252525]" />
                  <div className="flex justify-end gap-5">
                    <button
                      type="button"
                      onClick={handleSmsCancel}
                      className="bg-[#FAFCFD] dark:bg-[#1C1C1C] border-childBorder border w-[122px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={handleSmsVerification}
                      className="bg-[#6699FF] w-[122px] h-[38px] rounded-lg text-white font-semibold"
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
