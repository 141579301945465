import React, { useState } from "react";
import EmailVerificationPopUp from "./EmailVerificationPopUp";
const IdentityVerification = () => {
  const [isEmailVerifyPopupOpen, setIsEmailVerifyPopupOpen] = useState(false);

  const openPopup = () => setIsEmailVerifyPopupOpen(true);
  const closePopup = () => setIsEmailVerifyPopupOpen(false);

  return (
    <div className="">
      <div className="font-semibold text-[20px]">Identity Verification</div>
      <div className="mt-2">
        Verify your identity to unlock payout features.
        <span className="text-[#6699FF] font-semibold">Learn more </span>
        <div className="mt-5 rounded-2xl border-childBorder border p-5">
          <div className="flex lg:flex-row flex-col lg:items-center gap-y-2 lg:gap-5">
            <div className="text-[16px] font-semibold">
              Identity Verification
            </div>
            <div className="bg-[#EFEFF0] dark:bg-[#232325] font-semibold flex justify-center items-center w-[144px] h-[32px] rounded-2xl border-[#6C6C6C] border text-[#6C6C6C] dark:text-[#A4A4A4]">
              NOT VERIFIED
            </div>
            {/* <div className="bg-[#f9efda] flex justify-center items-center w-[105px] h-[32px] rounded-xl border-[#FDB528] border text-[#FDB528]">
              Pending
            </div> */}
          </div>
          <div className="text-[#6C6C6C] font-medium mt-2">
            Verification currently available.
          </div>
          <hr className="my-4 border-childBorder" />
          <div className="flex justify-end">
            <button
              onClick={openPopup}
              className="bg-[#FAFCFD] dark:bg-[#1C1C1C] border-childBorder border w-full lg:w-[200px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
            >
              Complete Verification
            </button>
          </div>
          {isEmailVerifyPopupOpen && (
            <EmailVerificationPopUp
              onClose={closePopup}
              isOpen={isEmailVerifyPopupOpen}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default IdentityVerification;
