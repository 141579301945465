import React from "react";
import chart from "../../Assets/Tools/mt5chart.png"
const AdvancedChart = () => {
  return (
    <div>
      <div className="text-[34px] font-semibold text-center">
        Advanced Charting
      </div>

      <div className="text-[#6C6C6C] text-[16px] text-center">
        Analyze the markets using advanced charting tools.
      </div>
      <div className="flex justify-center mt-10">
        <img src={chart} alt="" className="w-full"/>
      </div>
    </div>
  );
};

export default AdvancedChart;
