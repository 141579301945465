import React from "react";
import HorizontalBarChart from "./Charts/HorizontalBarChart";

const MonthlyAnalytics = () => {
  return (
    <div className="w-full  bg-background rounded-2xl border-childBorder border p-5">
      <div className="font-semibold">Monthly Analytics</div>
      <div className="text-[#6C6C6C] text-[14px]">Monthly Gain</div>

      <HorizontalBarChart />
    </div>
  );
};

export default MonthlyAnalytics;
