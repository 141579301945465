import React from "react";

const ConfirmOrder = () => {
  return (
    <div>
      {" "}
      <div className="text-[#6699FF] font-semibold text-[20px] text-center lg:mt-0 mt-5">
        Confirm Order
      </div>
      <div className="text-[16px] text-[#6C6C6C] font-normal text-center">
        Billing Details
      </div>
      <form className="mt-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5">
          <input
            className="w-full h-[50px] rounded-xl dark:border-[#424346] dark:bg-[#121212] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="FirstName"
            placeholder="First Name"
          />
          <input
            className="w-full h-[50px] rounded-xl dark:border-[#424346] dark:bg-[#121212] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="LastName"
            placeholder="Last Name"
          />
          <input
            className="w-full h-[50px] rounded-xl dark:border-[#424346] dark:bg-[#121212] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="EmailAddress"
            placeholder="Email Address"
          />
          <input
            className="w-full h-[50px] rounded-xl dark:border-[#424346] dark:bg-[#121212] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="PhoneNumber"
            placeholder="Phone Number"
          />
        </div>

        <hr className="my-5 dark:border-[#424346] " />

        <div className="grid grid-cols-1 sm:grid-cols-1 gap-5">
          <input
            className="w-full h-[50px] rounded-xl dark:border-[#424346] dark:bg-[#121212] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="Address"
            placeholder="Address"
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5 mt-5">
          <input
            className="w-full h-[50px] rounded-xl dark:border-[#424346] dark:bg-[#121212] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="Town/City"
            placeholder="Town/City"
          />
          <input
            className="w-full h-[50px] rounded-xl  dark:border-[#424346] dark:bg-[#121212] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="PostalCode"
            placeholder="Postal Code"
          />
          <select
            className="w-full h-[50px] rounded-xl dark:border-[#424346] dark:bg-[#121212] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="state"
            defaultValue=""
          >
            <option value="" disabled>
              State
            </option>
            <option value="WesternAustralia">Western Australia</option>
            <option value="NewSouthWales">New South Wales</option>
            <option value="Queensland">Queensland</option>
            <option value="Victoria">Victoria</option>
            <option value="SouthAustralia">South Australia</option>
          </select>
          <select
            className="w-full h-[50px] rounded-xl dark:border-[#424346] dark:bg-[#121212] border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
            name="Country"
            defaultValue=""
          >
            <option value="" disabled>
              Country
            </option>
            <option value="WesternAustralia">Western Australia</option>
            <option value="NewSouthWales">New South Wales</option>
            <option value="Queensland">Queensland</option>
            <option value="Victoria">Victoria</option>
            <option value="SouthAustralia">South Australia</option>
          </select>
        </div>
      </form>
    </div>
  );
};

export default ConfirmOrder;
