import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  LineElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend,
  Filler
);

const GradientAreaChart = () => {
  // Data for the chart
  const data = {
    labels: ["Sun", "Mon", "Tue", "Wen", "Thu", "Fri", "Sat"],
    datasets: [
      {
        label: "Value",
        data: [60000, 90000, 80000, 70000, 65000, 70000, 80000],
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            return null;
          }

          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, "rgba(102, 153, 255, 0.4)");
          gradient.addColorStop(1, "rgba(102, 153, 255, 0)");

          return gradient;
        },
        borderColor: "#6699FF",
        borderWidth: 2,
        pointRadius: 2, // Point size
        pointHoverRadius: 8, // Hover radius
        pointHoverBackgroundColor: "#ffffff",
        pointHoverBorderColor: "#3B82F6",
        pointHoverBorderWidth: 2,
        tension: 0.4, // Smooth curves
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `$${context.raw.toLocaleString()}`,
        },
      },
      legend: {
        display: false, // Hide legend
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#6B7280",
          font: {
            size: 12,
          },
        },
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          callback: (value) => `$${(value / 1000).toFixed(0)}K`,
          color: "#6B7280",
          font: {
            size: 12,
          },
        },
        grid: {
          color: "#e0e0e0",
          dash: [3, 3],
        },
      },
    },
  };

  return (
    <div style={{ width: "95%", height: "200px" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default GradientAreaChart;
