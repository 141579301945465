import React, { useEffect, useRef, useState } from "react";
import BillingDetailForm from "./BillingDetailForm";
import PaymentMethod from "./PaymentMethod";
import ThankYouPage from "../../Challenge/StartYourChallenge/StepComponents/ThankYouPage";

const CompetitionCheckout = ({ onClose }) => {
  const popupRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);

  const stepComponents = [
    <BillingDetailForm />,
    <PaymentMethod />,
    <ThankYouPage />,
  ];

  // Handle next step
  const handleNext = () => {
    if (activeStep < stepComponents.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  // Handle previous step
  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  // Close the popup if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); // Call the onClose function to close the popup
      }
    };

    const preventScroll = () => {
      document.body.style.overflow = "hidden"; // Disable body scroll
    };

    const enableScroll = () => {
      document.body.style.overflow = "auto"; // Enable body scroll
    };

    document.addEventListener("mousedown", handleClickOutside);
    preventScroll(); // Disable scroll when the popup is open

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      enableScroll(); // Re-enable scroll when the popup is closed
    };
  }, [onClose]);
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex lg:justify-center lg:items-center justify-end items-end ">
      <div
        ref={popupRef}
        className="bg-white dark:bg-[#121212]  lg:rounded-2xl rounded-t-3xl p-6 w-full lg:w-[1100px] max-h-[85vh] overflow-y-scroll"
      >
        {/* Dynamic Heading */}
        <div>
          {(() => {
            const headings = [
              {
                title: "Competition entry fee",
                description: "Enter your billing details below to proceed.",
              },
              {
                title: "Choose your payment method",
                description:
                  "Select from the options below to adjust your challenge parameters.",
              },
              {
                title: "Start your free trial",
                description:
                  "Test your skill at no cost so you can master the markets.",
              },
            ];
            const currentHeading = headings[activeStep] || headings[0];
            return (
              <>
                <h2 className="font-semibold text-center lg:text-[34px] text-[16px]">
                  {currentHeading.title}
                </h2>
                <p className="text-[#6C6C6C] lg:text-[16px] text-[14px] text-center font-medium">
                  {currentHeading.description}
                </p>
              </>
            );
          })()}
        </div>

        <div className="flex flex-col-reverse lg:flex-row lg:justify-between mt-10 gap-y-5">
          {/* Left Section */}
          <div className="lg:w-2/3 w-full pr-0 lg:pr-6 flex flex-col justify-between lg:border-r  border-childBorder">
            <div>
              {/* Render ThankYouPage when activeStep reaches the last step */}
              <div>{stepComponents[activeStep]}</div>
            </div>

            <div>
              <hr className="my-5 border-childBorder" />
              <div className="mt-5 flex justify-end space-x-2">
                {activeStep === 0 ? (
                  <>
                    <button
                      onClick={onClose}
                      className="bg-[#FAFCFD] dark:bg-[#1C1C1C] dark:border-[#252525] border-[#EAEAEC] border rounded-lg text-[#6699FF] w-[94px] h-[38px]"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleNext}
                      className="bg-[#6699FF] w-[77px] h-[38px] rounded-lg font-semibold text-white"
                    >
                      Next
                    </button>
                  </>
                ) : activeStep === 2 ? (
                  <button
                    onClick={onClose}
                    className="bg-[#6699FF] w-full lg:w-[150px] h-[38px] rounded-lg font-semibold text-white"
                  >
                    My Challenges
                  </button>
                ) : (
                  <>
                    <button
                      onClick={handleBack}
                      className="bg-[#FAFCFD] dark:bg-[#1C1C1C] dark:border-[#252525] border-[#EAEAEC] border rounded-lg text-[#6699FF] w-[94px] h-[38px]"
                    >
                      Back
                    </button>
                    <button
                      onClick={handleNext}
                      className="bg-[#6699FF] w-[77px] h-[38px] rounded-lg font-semibold text-white"
                    >
                      Next
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="lg:w-[320px] border border-[#6699FF] dark:bg-[#1C1C1C] rounded-2xl text-black  p-5">
            <div
              style={{
                background:
                  "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
              }}
              className="p-5 text-white rounded-2xl"
            >
              <div className="flex justify-between">
                <h3 className="text-sm font-normal">Total: (USD)</h3>
                <div className="bg-[#67CA24] text-[12px] font-medium flex justify-center items-center w-[66px] h-[20px] rounded-2xl border border-white">
                  20% OFF
                </div>
              </div>

              <p className="text-3xl font-bold  my-4">$159.00</p>
              <hr className="my-4" />
              <div className="space-y-2 text-sm">
                <div className="flex justify-between">
                  <span>Discount:</span>
                  <span>$0.00</span>
                </div>
                <div className="flex justify-between">
                  <span>Challenge:</span>
                  <span>$159.00</span>
                </div>
              </div>
            </div>

            <div className="flex justify-between mt-2">
              <div className="flex flex-col ">
                <span className="text-[#6C6C6C] text-[12px] font-normal">
                  Balance
                </span>
                <span className="text-[20px] text-[#2B2A2A] font-semibold dark:text-white">
                  $25,000
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-[#6C6C6C] text-[12px] font-normal">
                  Leverage
                </span>
                <span className="text-[20px] text-[#2B2A2A]  font-semibold dark:text-white">
                  1:100
                </span>
              </div>
            </div>
            <hr className="my-2" />
            <div className="flex justify-between">
              <div className="flex flex-col ">
                <span className="text-[#6C6C6C] text-[12px] font-normal">
                  Evaluation Stages
                </span>
                <span className="text-[20px] font-semibold text-[#2B2A2A] dark:text-white">
                  Single
                </span>
              </div>
              <div className="flex flex-col text-right">
                <span className="text-[#6C6C6C] text-[12px] font-normal">
                  Account
                </span>
                <span className="text-[20px] text-[#2B2A2A]  font-semibold dark:text-white">
                  Standard
                </span>
              </div>
            </div>
            <div className="flex justify-between mt-2">
              <div className="flex flex-col ">
                <span className="text-[#6C6C6C] text-[12px] font-normal">
                  Profit Split
                </span>
                <span className="text-[20px] text-[#2B2A2A]  font-semibold dark:text-white">
                  85%
                </span>
              </div>
              <div className="flex flex-col text-right">
                <span className="text-[#6C6C6C] text-[12px] font-normal">
                  Profit Target
                </span>
                <span className="text-[20px] text-[#2B2A2A]  font-semibold dark:text-white">
                  10%
                </span>
              </div>
            </div>
            <div className="flex justify-between mt-2">
              <div className="flex flex-col ">
                <span className="text-[#6C6C6C] text-[12px] font-normal">
                  Max Drawdown
                </span>
                <span className="text-[20px] text-[#2B2A2A]  font-semibold dark:text-white">
                  6%
                </span>
              </div>
              <div className="flex flex-col text-right">
                <span className="text-[#6C6C6C] text-[12px] font-normal">
                  Daily Drawdown:
                </span>
                <span className="text-[20px] text-[#2B2A2A]  font-semibold dark:text-white">
                  4%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitionCheckout;
