import { ArrowLeft, MoveUp } from "lucide-react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import more from "../../Assets/Competitions/more.svg";
import country from "../../Assets/Competitions/country.svg";
import CompetitionCheckout from "./CompetitionCheckout/CompetitionCheckout";
import {
  DollarCircle,
  Gift,
  Placement,
  Timer,
} from "../../Assets/Competitions/SvgCompetions";
const CompetitionDetails = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  return (
    <div>
      <div
        onClick={() => navigate(-1)}
        className="flex justify-start items-center gap-x-2 font-semibold text-[14px] cursor-pointer"
      >
        <ArrowLeft size={20} />
        Back
      </div>
      <div className="lg:text-[34px] text-[24px] font-semibold text-center">
        July Competition
      </div>

      <div className=" lg:text-[16px] text-[14px] text-[#6C6C6C] text-center">
        Competition rules are outlined in this article here.
        <span className="text-[#6699FF] font-semibold">Learn more</span>
      </div>

      <div className="flex justify-center mt-5">
        <button
          onClick={() => setIsOpen(true)}
          className="lg:w-[189px] w-full h-[44px] rounded-lg bg-[#6699FF] dark:text-black text-white font-semibold"
        >
          Join competition
        </button>
      </div>
      {isOpen && <CompetitionCheckout onClose={() => setIsOpen(false)} />}

      <div className="mt-5 flex lg:flex-row flex-col gap-4">
        <div className="border border-childBorder bg-background w-full md:w-[70%] rounded-2xl flex lg:flex-wrap justify-around p-5 gap-4 md:gap-0">
          <div className="flex flex-col lg:flex-row lg:items-start lg:gap-y-0 gap-2 items-center justify-center space-x-2 w-full md:w-auto">
            <div className="w-[44px] h-[44px] bg-[#EDF4FF] dark:bg-[#011845] flex justify-center items-center rounded-full border border-childBorder">
              <Placement />
            </div>
            <div>
              <div className="text-[#6C6C6C] lg:text-[16px] text-[12px] font-semibold">
                My Placement
              </div>
              <div className="flex items-center gap-x-2 lg:mt-0 mt-1">
                <div className="lg:text-[34px] text-[20px] font-semibold ">
                  1st
                </div>
                <div className="w-[50px] dark:bg-[#011845] h-[32px] rounded-xl bg-[#F4FDEE] text-[#65D31D] flex justify-center font-semibold items-center">
                  <MoveUp size={13} /> +3
                </div>
              </div>
            </div>
          </div>

          <div className="hidden md:block w-[1px]  border border-childBorder h-auto mx-4"></div>

          <div className="flex flex-col lg:flex-row lg:items-start lg:gap-y-0 gap-2  items-center justify-center space-x-2 w-full md:w-auto">
            <div className="w-[44px] h-[44px] bg-[#F4FDEE] dark:border-[#65D31D] dark:bg-[#011845] flex justify-center items-center rounded-full border border-childBorder">
              <DollarCircle />
            </div>
            <div>
              <div className="text-[#6C6C6C] lg:text-[16px] text-[12px] font-semibold">
                Prize Pool
              </div>
              <div className="lg:text-[34px] text-[20px] font-semibold lg:mt-0 mt-1 text-center">
                $1,000
              </div>
            </div>
          </div>

          <div className="hidden md:block w-[1px]  border border-childBorder h-auto mx-4"></div>

          <div className="flex flex-col lg:flex-row lg:items-start lg:gap-y-0 gap-2  items-center justify-center space-x-2 w-full md:w-auto">
            <div className="w-[44px] h-[44px] bg-[#ddf3fa] dark:border-[#26C6F9] dark:bg-[#18283C] flex justify-center items-center rounded-full border border-childBorder">
              <Gift />
            </div>
            <div>
              <div className="text-[#6C6C6C] lg:text-[16px] text-[12px] font-semibold">
                Entry Fee
              </div>
              <div className="lg:text-[34px] text-[20px] font-semibold lg:mt-0 mt-1 text-center">
                Free
              </div>
            </div>
          </div>
        </div>

        <div className="border border-childBorder bg-background  w-full md:w-[30%] rounded-2xl p-5">
          <div className="flex space-x-2">
            <div className="w-[44px] h-[44px] bg-[#f3eed2] dark:border-[#FDB528] dark:bg-[#2D2600] flex justify-center items-center rounded-full border border-[#FDB528]">
              <Timer />
            </div>
            <div>
              <div className="text-[#6C6C6C] text-[16px] font-semibold">
                Time Remaining
              </div>
              <div className="text-[34px] font-semibold">
                18 <span className="text-[#6C6C6C] text-[20px]">d</span> 12
                <span className="text-[#6C6C6C] text-[20px]"> h</span> 35
                <span className="text-[#6C6C6C] text-[20px]"> m</span> 51
                <spam className="text-[#6C6C6C] text-[20px]"> s</spam>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-4  border border-childBorder bg-background  rounded-2xl  p-5">
        <div className="flex justify-between flex-row ">
          <div className="font-semibold ">Rangking</div>

          <div className="">
            <img src={more} alt="" />
          </div>
        </div>

        <div className="mt-5 w-full overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-[#6699FF]  ">
              <tr>
                <th scope="col" className="px-6 py-5 ">
                  RANK
                </th>
                <th scope="col" className="px-6 py-5">
                  User
                </th>
                <th scope="col" className="px-6 py-5">
                  % TILL 1st
                </th>
                <th scope="col" className="px-6 py-5">
                  Gain %
                </th>
                <th scope="col" className="px-6 py-5">
                  Prize
                </th>
                <th scope="col" className="px-6 py-5">
                  COUNTRY
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white dark:bg-[#121212] ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2 dark:text-white">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-[#F6F8FE] dark:bg-[#252525] ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2 dark:text-white">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-white  dark:bg-[#121212]">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2 dark:text-white">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-[#F6F8FE] dark:bg-[#252525] ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2 dark:text-white">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-white  dark:bg-[#121212]">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2 dark:text-white">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-[#F6F8FE] dark:bg-[#252525] ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2 dark:text-white">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-white  dark:bg-[#121212]">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2 dark:text-white">Joshua C</td>
                <td className="px-6 py-2">5.17%</td>
                <td className="px-6 py-2 text-[#67CA24]">74.25%</td>
                <td className="px-6 py-2">$250 Propmodel Challenge Voucher</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CompetitionDetails;
