import React, { useEffect } from "react";

const EmailVerificationPopUp = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Clean up when the component is unmounted
    };
  }, [isOpen]);
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex lg:justify-center lg:items-center justify-end items-end ">
      <div className="bg-white  dark:bg-[#121212] lg:rounded-2xl rounded-t-3xl p-6 w-[500px]">
        <div>
          <div className="text-[34px] text-center font-semibold ">
            Check your email
          </div>
          <div className="flex justify-center">
            <div className="text-[16px] w-[300px] text-center text-[#6C6C6C] mb-4">
              <div>
                Enter the verification code sent to your email. Didn't get the
                code?
                <span className="text-[#6699FF]">Resend</span>
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="Verification Code"
            className="w-full border   dark:bg-[#121212] dark:border-[#252525] rounded-xl h-[56px] pl-2 outline-none"
          />

          <hr className="my-4 border-childBorder" />
          <div className="flex justify-end space-x-2">
            <button
              onClick={onClose}
              className="bg-[#FAFCFD] dark:border-[#252525] dark:bg-[#1C1C1C] border-[#EAEAEC] border w-full lg:w-[94px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
            >
              Cancel
            </button>
            <button className="bg-[#6699FF] w-full lg:w-[87px] h-[38px] rounded-lg text-white font-semibold">
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationPopUp;
