import React from "react";
import { ThankYouPageSvg } from "../../../../Assets/Challenge/SvgNewChallenge";
const ThankYouPage = () => {
  return (
    <div className="flex  flex-col items-center lg:mt-0 mt-5">
      <div className="shadow-2xl w-[90px] h-[90px] rounded-full flex justify-center items-center">
        <ThankYouPageSvg />
      </div>
      <div className=" text-[34px] font-semibold mt-5">Thank You!</div>

      <div className="text-[#6C6C6C] dark:text-[#A4A4A4] text-center lg:w-[488px]">
        <div>
          Your order{" "}
          <span className="text-black font-semibold dark:text-white">1536548131</span> has been
          placed!
        </div>
        <div className="mt-5">
          An email has been sent to{" "}
          <span className="text-black font-semibold dark:text-white">joshua@propmodel.co</span>{" "}
          with your order confirmation and receipt. If the email hasn't arrived
          within two minutes, please check your spam folder.
        </div>

        <div className="mt-5">
          You will also receive a second email with a copy of your account
          credentials. You can also access these via the my challenges page,
          good luck!
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
