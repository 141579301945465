import React from "react";
import {
  BlueDollarSvg,
  ConversionsSvg,
  CopySvg,
  DollarSvg,
  HumanSvg,
  LinkClicksSvg,
  ReferLinkSvg,
  UnpaidEarningSvg,
} from "../../Assets/Affiliate/SvgAffiliate";
import { ChevronLeft, ChevronRight, MoveUp } from "lucide-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

const Affiliate = () => {
  return (
    <div>
      <div className="mt-5 lg:grid gap-3 sm:grid-cols-2 lg:grid-cols-4 hidden sm:block">
        {/* This is for large screens */}
        <div className=" rounded-2xl bg-background border-childBorder border  p-5 flex gap-x-3">
          <div>
            <DollarSvg />
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="text-[#6C6C6C] text-[16px] font-semibold">
              Total Earnings
            </div>
            <div className="text-[#2B2A2A] dark:text-white font-semibold text-[24px]">
              $730.00
            </div>
          </div>
        </div>
        <div className=" rounded-2xl bg-background border-childBorder border  p-5 flex gap-x-3">
          <div>
            <UnpaidEarningSvg />
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="text-[#6C6C6C] text-[16px] font-semibold">
              Unpaid Earnings
            </div>
            <div className="text-[#2B2A2A] dark:text-white font-semibold text-[24px]">
              $130.00
            </div>
          </div>
        </div>
        <div className="rounded-2xl bg-background border-childBorder  border p-5 flex gap-x-3">
          <div>
            <LinkClicksSvg />
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="text-[#6C6C6C] text-[16px] font-semibold">
              Link Clicks
            </div>
            <div className="text-[#2B2A2A] dark:text-white font-semibold text-[24px] flex items-center gap-x-2">
              2,664{" "}
              <div className="bg-[#F4FDEE] dark:bg-[#011845] text-[#65D31D] w-[56px] h-[32px] rounded-lg text-[13px] font-semibold flex justify-center items-center  ">
                <MoveUp size={15} /> +12
              </div>
            </div>
          </div>
        </div>
        <div className=" rounded-2xl bg-background border-childBorder border  p-5 flex gap-x-3">
          <div>
            <ConversionsSvg />
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="text-[#6C6C6C] text-[16px] font-semibold">
              Conversions
            </div>
            <div className="text-[#2B2A2A] dark:text-white font-semibold text-[24px] flex items-center gap-x-2">
              127
              <div className="bg-[#F4FDEE] dark:bg-[#011845] text-[#65D31D] w-[56px] h-[32px] rounded-lg text-[13px] font-semibold flex justify-center items-center  ">
                <MoveUp size={15} /> +3
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* This is for Mobile screen */}
      <div className="block sm:hidden mt-2">
        <Swiper
          modules={[Pagination]}
          pagination={{
            clickable: true,
            el: ".custom-pagination-1",
          }}
          spaceBetween={30}
          slidesPerView={1.5}
          loop={true}
        >
          <SwiperSlide>
            <div className="bg-white rounded-2xl border border-[#EAEAEC] p-5 flex gap-x-3">
              <div>
                <DollarSvg />
              </div>
              <div className="flex flex-col gap-y-2">
                <div className="text-[#6C6C6C] text-[12px] font-semibold">
                  Total Earnings
                </div>
                <div className="text-[#2B2A2A] font-semibold text-[20px]">
                  $730.00
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-white rounded-2xl border border-[#EAEAEC] p-5 flex gap-x-3">
              <div>
                <UnpaidEarningSvg />
              </div>
              <div className="flex flex-col gap-y-2">
                <div className="text-[#6C6C6C] text-[12px] font-semibold">
                  Unpaid Earnings
                </div>
                <div className="text-[#2B2A2A] font-semibold text-[20px]">
                  $130.00
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-white rounded-2xl border border-[#EAEAEC] p-5 flex gap-x-3">
              <div>
                <LinkClicksSvg />
              </div>
              <div className="flex flex-col gap-y-2">
                <div className="text-[#6C6C6C] text-[12px] font-semibold">
                  Link Clicks
                </div>
                <div className="text-[#2B2A2A] font-semibold text-[20px] flex items-center gap-x-2">
                  2,664{" "}
                  <div className="bg-[#F4FDEE] text-[#65D31D] w-[56px] lg:h-[32px] h-[25] rounded-lg text-[12px] font-semibold flex justify-center items-center  ">
                    <MoveUp size={15} /> +12
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-white rounded-2xl border border-[#EAEAEC] p-5 flex gap-x-3">
              <div>
                <ConversionsSvg />
              </div>
              <div className="flex flex-col gap-y-2">
                <div className="text-[#6C6C6C] text-[12px] font-semibold">
                  Conversions
                </div>
                <div className="text-[#2B2A2A] font-semibold text-[20px] flex items-center gap-x-2">
                  127
                  <div className="bg-[#F4FDEE] text-[#65D31D] w-[56px] lg:h-[32px] h-[25] rounded-lg text-[12px] font-semibold flex justify-center items-center  ">
                    <MoveUp size={15} /> +3
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* Custom Pagination Dots */}
        <div className="custom-pagination-1 flex justify-center mt-6"></div>
      </div>

      <div className="mt-3 grid gap-5 lg:grid-cols-[2fr_1fr]">
        <div className="bg-background border-childBorder border rounded-2xl px-5 py-10 grid gap-5 lg:grid-cols-3">
          <div className="text-left gap-x-2 lg:gap-y-4 lg:text-left flex lg:flex-col lg:items-start items-center">
            <div className="w-[44px] h-[44px]">
              <ReferLinkSvg size={25} />
            </div>

            <div className="text-[#6C6C6C] lg:font-normal ">
              Share your referral link with your friends.
            </div>
          </div>

          <div className="text-left gap-x-2 lg:gap-y-4 lg:text-left flex lg:flex-col lg:items-start items-center">
            <div className="w-[44px] h-[44px]">
              <HumanSvg size={25} />
            </div>
            <div className="text-[#6C6C6C] lg:font-normal ">
              Anyone who signs up with your link receives 10% off.
            </div>
          </div>

          <div className="text-left gap-x-2 lg:gap-y-4 lg:text-left flex lg:flex-col lg:items-start items-center">
            <div className="w-[44px] h-[44px]">
              <BlueDollarSvg size={25} />
            </div>
            <div className="text-[#6C6C6C] lg:font-normal ">
              Get paid 10% every time a challenge is purchased.
            </div>
          </div>
        </div>

        <div className="bg-background border-childBorder border rounded-2xl px-5 py-10 flex flex-col gap-y-2">
          <div className="text-[16px] font-semibold">Referral Link</div>
          <div className="text-[14px] text-[#6C6C6C]">
            Share your unique referral link below to generate referrals and earn
            bonuses.
          </div>
          <div className="relative">
            <input
              className="border border-[#D0D5DD] dark:border-[#424346] dark:bg-[#252525] rounded-xl h-[40px] w-full pr-10 outline-none pl-2"
              placeholder="http://referral.link"
            />
            <div className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer">
              <CopySvg />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mt-3   rounded-2xl bg-background border-childBorder border  p-5">
        <div className="font-semibold ">Referred Users</div>

        <div className="mt-5 w-full overflow-x-auto">
          <table className="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-[#6699FF] ">
              <tr>
                <th scope="col" className="px-6 py-4">
                  USER
                </th>
                <th scope="col" className="px-6 py-4">
                  Referral date
                </th>
                <th scope="col" className="px-6 py-4">
                  STATUS
                </th>
                <th scope="col" className="px-6 py-4">
                  Earnings
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white dark:bg-[#121212]">
                <td className="px-6 py-4 text-[#6699FF]">#4910</td>
                <td className="px-6 py-4">06 Jul 2023</td>
                <td className="px-6 py-4">
                  <div className="w-[66px] h-[24px] rounded-2xl dark:bg-[#2D2600] bg-[#f7efdf] border border-[#FDB528] text-[#FDB528] font-semibold flex justify-center items-center">
                    UnPaid
                  </div>
                </td>
                <td className="px-6 py-4 dark:text-white font-semibold">
                  $2,500.00 USD
                </td>
              </tr>
              <tr className="bg-[#F6F8FE]  dark:bg-[#252525] ">
                <td className="px-6 py-4 text-[#6699FF]">#4910</td>
                <td className="px-6 py-4">06 Jul 2023</td>
                <td className="px-6 py-4">
                  <div className="w-[48px] h-[24px] rounded-2xl dark:bg-[#00270B] bg-[#F4FDEE] border border-[#65D31D] text-[#65D31D] font-semibold flex justify-center items-center">
                    Paid
                  </div>
                </td>
                <td className="px-6 py-4 dark:text-white font-semibold">
                  $2,500.00 USD
                </td>
              </tr>
              <tr className="bg-white  dark:bg-[#121212] ">
                <td className="px-6 py-4 text-[#6699FF]">#4910</td>
                <td className="px-6 py-4">06 Jul 2023</td>
                <td className="px-6 py-4">
                  <div className="w-[69px] h-[24px] rounded-2xl dark:bg-[#220000] bg-[#FFEAE9] border border-[#FF4D49] text-[#FF4D49] font-semibold flex justify-center items-center">
                    Declined
                  </div>
                </td>
                <td className="px-6 py-4 dark:text-white font-semibold">
                  $2,500.00 USD
                </td>
              </tr>
              <tr className="bg-[#F6F8FE]  dark:bg-[#252525]">
                <td className="px-6 py-4 text-[#6699FF]">#4910</td>
                <td className="px-6 py-4">06 Jul 2023</td>
                <td className="px-6 py-4">
                  <div className="w-[48px] h-[24px] rounded-2xl dark:bg-[#00270B] bg-[#F4FDEE] border border-[#65D31D] text-[#65D31D] font-semibold flex justify-center items-center">
                    Paid
                  </div>
                </td>
                <td className="px-6 py-4  dark:text-white font-semibold">
                  $2,500.00 USD
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-2">
          <div className="flex items-center gap-x-5">
            <div className="text-[14px]">1 0f 13</div>

            <div className="flex gap-x-5 items-center">
              <ChevronLeft size={20} />
              <ChevronRight size={20} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Affiliate;
