import React from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import Navbar from "../Components/Navbar/Navbar";

const Layout = ({ children }) => {
  return (
    <div className="grid grid-cols-[auto_1fr] h-screen">
      {/* Sidebar */}
      <div className=" border-r border-childBorder ">
        <Sidebar />
      </div>

      {/* Main Content */}
      <div className="grid grid-rows-[auto_1fr_auto]">
        {/* Navbar */}
        <div>
          <Navbar />
        </div>

        <div className="bg-[#E6EDFC] children-container overflow-y-scroll h-screen flex flex-col justify-between">
          <div>
            <div className="flex justify-center p-4">
              <div className="w-full">{children}</div>
            </div>
          </div>
          <div>
            <div className="flex justify-between p-4">
              <div className="text-[#6C6C6C] lg:text-[16px] text-[12px]">
                All Rights Reserved Propmodel © 2024
              </div>
              <div className="text-[#4673CC] lg:text-[16px] text-[12px] cursor-pointer">
                Need Help?
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
