import { Route, Routes, Navigate } from "react-router-dom";
import PagesRoutes from "./Routers/PagesRoutes";
import { ThemeProvider } from "./Contexts/ThemeProvider";
import { PopupProvider } from "./Contexts/PopupContext";

function App() {
  return (
    <div className="">
      <ThemeProvider>
        <PopupProvider>
          <Routes>
            <Route path="/" element={<Navigate to="/Login" />} />
            <Route path="/*" element={<PagesRoutes />} />
          </Routes>
        </PopupProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
