import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = () => {
  const data = {
    labels: ["EURUSD", "XAUUSD", "EURJPY", "EURCHF"],
    datasets: [
      {
        data: [48.5, 27.38, 13.11, 10.86],
        backgroundColor: ["#6699FF", "#26C6F9", "#FDB528", "#65D31D"],
        hoverBackgroundColor: ["#4A7DCC", "#1BA9D6", "#E69E22", "#52B716"],
        // borderWidth: 5,
        // borderColor: "#ffffff",
        borderRadius: 15,
        hoverOffset: 8, // Offset when hovered
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "79%", // Creates the donut effect
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => `${context.raw}%`, // Format tooltip to show percentage
        },
      },
      legend: {
        position: "right", // Align legend to the right
        labels: {
          usePointStyle: true,
          padding: 20,
          generateLabels: (chart) => {
            const { labels } = chart.data;
            const { data } = chart.data.datasets[0];
            return labels.map((label, index) => ({
              text: `${label}  -  ${data[index]}%`,
              fillStyle: chart.data.datasets[0].backgroundColor[index],
              strokeStyle: chart.data.datasets[0].backgroundColor[index],
              lineWidth: 0,
            }));
          },
        },
      },
    },
  };

  const textCenter = {
    id: "textCenter",
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data, chartArea } = chart;

      // Calculate the center of the chart area
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;

      // Find the index of the largest value in the data array
      const largestValueIndex = data.datasets[0].data.indexOf(
        Math.max(...data.datasets[0].data)
      );

      // Get the corresponding label for the largest value
      const largestLabel = data.labels[largestValueIndex];

      // Save the current state of the canvas
      ctx.save();

      // Calculate the background circle radius dynamically based on the chart size
      const bgRadius = Math.min(chartArea.width, chartArea.height) / 3; // 1/3 of the smaller dimension
      ctx.fillStyle = "#F6F8FE"; // Background color
      ctx.beginPath(); // Start a new path
      ctx.arc(centerX, centerY, bgRadius, 0, Math.PI * 2); // Draw the circle
      ctx.fill(); // Fill the circle

      // Draw the label (largest value's label)
      ctx.font = "600 16px sans-serif"; // Semi-bold font
      ctx.fillStyle = "#6C6C6C"; // Label color
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(`${largestLabel}`, centerX, centerY - 15); // Display the largest label

      // Draw the value with percentage
      ctx.font = "bolder 24px sans-serif";
      ctx.fillStyle = "black"; // Value color
      ctx.fillText(
        `${data.datasets[0].data[largestValueIndex]}%`,
        centerX,
        centerY + 15
      ); // Display the percentage of the largest value

      // Restore the canvas state
      ctx.restore();
    },
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "250px" }}>
      <Doughnut data={data} options={options} plugins={[textCenter]} />
    </div>
  );
};

export default DonutChart;
