import React, { useState } from "react";
import {
  VisaSvg,
  BlueMasterCardSvg,
  MasterCardSvg,
  AmexSvg,
  PaypalSvg,
  OrangeBitcoinSvg,
  EthereumSvg,
  GreenBitcoinSvg,
} from "../../../Assets/Challenge/SvgNewChallenge";

const PaymentMethod = () => {
  const [selected, setSelected] = useState("Credit Card");
  const options = [
    {
      label: "Credit Card",
      icons: [
        {
          component: <VisaSvg />,
          alt: "Visa",
        },
        {
          component: <BlueMasterCardSvg />,
          alt: "MasterCard",
        },
        {
          component: <MasterCardSvg />,
          alt: "MasterCard",
        },
        {
          component: <AmexSvg />,
          alt: "Amex",
        },
      ],
    },
    {
      label: "Paypal",
      icons: [
        {
          component: <PaypalSvg />,
          alt: "PayPal",
        },
      ],
    },
    {
      label: "Crypto",
      icons: [
        {
          component: <OrangeBitcoinSvg />,
          alt: "Bitcoin",
        },
        {
          component: <EthereumSvg />,
          alt: "Ethereum",
        },
        {
          component: <GreenBitcoinSvg />,
          alt: "Bitcoin Cash",
        },
      ],
    },
  ];
  return (
    <div>
      {" "}
      <div className="flex lg:justify-start lg:flex-row flex-col  lg:gap-x-4 gap-y-4 mt-5">
        {options.map((option) => (
          <div
            key={option.label}
            onClick={() => setSelected(option.label)}
            className={`cursor-pointer flex lg:flex-col lg:gap-0 gap-2 items-center p-4 border rounded-2xl box-shadow lg:w-48 ${
              selected === option.label
                ? "border-[#6699FF] border-2  bg-blue-50 dark:bg-[#121212]"
                : "border-gray-300 dark:border-[#252525]"
            }`}
          >
            <div className="text-lg font-semibold lg:mb-3">{option.label}</div>
            <div className="flex space-x-2">
              {option.icons.map((icon, index) => (
                <div
                  key={index}
                  className="w-8 h-8 flex items-center justify-center"
                >
                  {icon.component}
                </div>
              ))}
            </div>
            <div
              className={`lg:mt-4 w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                selected === option.label
                  ? "border-[#6699FF]"
                  : "border-gray-300"
              }`}
            >
              {selected === option.label && (
                <div className="w-2.5 h-2.5 rounded-full bg-[#6699FF]"></div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethod;
