import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import {
  BlueStar,
  GreyStar,
  OrangeStar,
} from "../../Assets/Competitions/SvgCompetions";

const BlueProgressBar = ({ progress }) => {
  return (
    <div className="w-full  bg-[#EDF4FF] dark:bg-[#011845] rounded-full h-2 mt-2">
      <div
        className="bg-[#6699FF] h-2 rounded-l-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};
const Competitions = () => {
  return (
    <div>
      <div className="font-semibold lg:text-[34px] text-[24px]   text-center">
        Trading Competitions
      </div>
      <div className="lg:text-[16px] text-sm text-[#6C6C6C] text-center">
        Register to show off your skills and win prizes!
      </div>
      {/*This is for large screen*/}
      <div className="mt-5 lg:flex lg:flex-wrap lg:justify-center gap-5 hidden sm:flex">
        <div className="border border-childBorder bg-background  rounded-2xl  w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
          <div className="flex items-center gap-x-2">
            <div className="w-[44px] h-[44px] rounded-full border border-childBorder bg-[#EEEEEE] dark:bg-[#212121] flex justify-center items-center">
              <GreyStar />
            </div>

            <div className="font-semibold text-[20px]">July Competition</div>
          </div>
          <Link to={"/competition-details"}>
            <div
              className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
              style={{
                background:
                  "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
              }}
            >
              <span className="font-semibold text-[24px]">$10K</span> Challenge
            </div>
          </Link>
          <div className="flex justify-between items-center">
            <div className="text-[#FDB528] dark:bg-[#2D2600] bg-[#f5e7cd] flex justify-center items-center border font-semibold border-[#FDB528] w-[133px] h-[32px] rounded-2xl">
              REGISTERED
            </div>
            <div className="text-[16px] font-semibold">In Progress</div>
          </div>
          <hr className="border border-childBorder" />
          <div className="flex justify-between items-center">
            <div className="w-[160px] text-left">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Start Date
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
            <div className="w-[160px] text-right">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Deadline
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
          </div>
          <div className="text-[#6C6C6C] text-[16px] font-normal">
            Test your skills and compete for your chance to win up to $1,000
            worth of prizes!
          </div>
          <hr className="border border-childBorder" />
          <div>
            <div className="flex justify-between">
              <div className="text-[#6C6C6C] text-[14px] font-medium ">
                2,649 Entries
              </div>
              <div className="text-[14px] font-semibold">42%</div>
            </div>

            <BlueProgressBar progress={40} />
            <div className="mt-5">
              <button className="w-full h-[44px] rounded-lg border-[#6D788D] border text-[#6D788D] font-semibold">
                Already joined
              </button>
            </div>
          </div>
        </div>
        <div className="rounded-2xl border border-childBorder bg-background  w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
          <div className="flex items-center gap-x-2">
            <div className="w-[44px] h-[44px] rounded-full border border-[#EAEAEC] bg-[#E6EDFC] dark:border-[#6699FF] dark:bg-[#011845] flex justify-center items-center">
              <BlueStar />
            </div>
            <div className="font-semibold text-[20px]">
              Champion Competition
            </div>
          </div>
          <div
            className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
            style={{
              background:
                "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
            }}
          >
            <span className="font-semibold text-[24px]">$10K</span> Challenge
          </div>
          <div className="flex justify-between items-center">
            <div className="text-[#26C6F9] dark:bg-[#18283C] bg-[#c8ebf6] flex justify-center items-center border font-semibold border-[#26C6F9] w-[133px] h-[32px] rounded-2xl">
              $25 ENTRY FEE
            </div>
            <div className="text-[16px] font-semibold">Upcoming</div>
          </div>
          <hr className="border border-childBorder" />
          <div className="flex justify-between items-center">
            <div className="w-[160px] text-left">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Start Date
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
            <div className="w-[160px] text-right">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Deadline
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
          </div>
          <div className="text-[#6C6C6C] text-[16px] font-normal">
            Test your skills and compete for your chance to win up to $250,000
            worth of prizes!
          </div>
          <hr className="border border-childBorder" />
          <div>
            <div className="flex justify-between">
              <div className="text-[#6C6C6C] text-[14px] font-medium ">
                2,649 Entries
              </div>
              <div className="text-[14px] font-semibold">42%</div>
            </div>

            <BlueProgressBar progress={40} />
            <div className="mt-5">
              <button className="w-full h-[44px] rounded-lg bg-[#6699FF] dark:text-black text-white font-semibold">
                Join competition
              </button>
            </div>
          </div>
        </div>
        <div className=" rounded-2xl border border-childBorder bg-background  w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
          <div className="flex items-center gap-x-2">
            <div className="w-[44px] h-[44px] rounded-full border border-childBorder bg-[#EEEEEE] dark:bg-[#212121] flex justify-center items-center">
              <GreyStar />
            </div>
            <div className="font-semibold text-[20px]">
              September Competition
            </div>
          </div>
          <div
            className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
            style={{
              background:
                "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
            }}
          >
            <span className="font-semibold text-[24px]">$10K</span> Challenge
          </div>
          <div className="flex justify-between items-center">
            <div className="text-[#65D31D] dark:bg-[#011845] bg-[#e3f5d8] flex justify-center items-center font-semibold border border-[#65D31D] w-[133px] h-[32px] rounded-2xl">
              FREE ENTRY
            </div>
            <div className="text-[16px] font-semibold">Open Registration</div>
          </div>
          <hr className="border border-childBorder" />
          <div className="flex justify-between items-center">
            <div className="w-[160px] text-left">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Start Date
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
            <div className="w-[160px] text-right">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Deadline
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
          </div>
          <div className="text-[#6C6C6C] text-[16px] font-normal">
            Test your skills and compete for your chance to win up to $1,000
            worth of prizes!
          </div>
          <hr className="border border-childBorder" />
          <div>
            <div className="flex justify-between">
              <div className="text-[#6C6C6C] text-[14px] font-medium ">
                2,649 Entries
              </div>
              <div className="text-[14px] font-semibold">42%</div>
            </div>

            <BlueProgressBar progress={40} />
            <div className="mt-5">
              <button className="w-full h-[44px] rounded-lg bg-[#6699FF] dark:text-black text-white font-semibold">
                Join competition
              </button>
            </div>
          </div>
        </div>
        <div className="rounded-2xl border border-childBorder bg-background  w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
          <div className="flex items-center gap-x-2">
            <div className="w-[44px] h-[44px] rounded-full border border-childBorder bg-[#EEEEEE] dark:bg-[#212121] flex justify-center items-center">
              <GreyStar />
            </div>
            <div className="font-semibold text-[20px]">October Competition</div>
          </div>
          <div
            className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
            style={{
              background:
                "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
            }}
          >
            <span className="font-semibold text-[24px]">$10K</span> Challenge
          </div>
          <div className="flex justify-between items-center">
            <div className="text-[#65D31D] dark:bg-[#011845] bg-[#e3f5d8] flex justify-center items-center font-semibold border border-[#65D31D] w-[133px] h-[32px] rounded-2xl">
              FREE ENTRY
            </div>
            <div className="text-[16px] font-semibold">Coming Soon</div>
          </div>
          <hr className="border border-childBorder" />
          <div className="flex justify-between items-center">
            <div className="w-[160px] text-left">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Start Date
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
            <div className="w-[160px] text-right">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Deadline
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
          </div>
          <div className="text-[#6C6C6C] text-[16px] font-normal">
            Test your skills and compete for your chance to win up to $1,000
            worth of prizes!
          </div>
          <hr className="border border-childBorder" />
          <div>
            <div className="flex justify-between">
              <div className="text-[#6C6C6C] text-[14px] font-medium ">
                2,649 Entries
              </div>
              <div className="text-[14px] font-semibold">42%</div>
            </div>

            <BlueProgressBar progress={40} />
            <div className="mt-5">
              <button className="w-full h-[44px] dark:bg-[#252525] dark:text-[#494949] rounded-lg bg-[#EAEAEC] text-[#C6C6C6] font-semibold">
                Coming soon
              </button>
            </div>
          </div>
        </div>
        <div className="rounded-2xl border border-childBorder bg-background  w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
          <div className="flex items-center gap-x-2">
            <div className="w-[44px] h-[44px] rounded-full border border-childBorder bg-[#EEEEEE] dark:bg-[#212121] flex justify-center items-center">
              <GreyStar />
            </div>
            <div className="font-semibold text-[20px]">
              November Competition
            </div>
          </div>
          <div
            className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
            style={{
              background:
                "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
            }}
          >
            <span className="font-semibold text-[24px]">$10K</span> Challenge
          </div>
          <div className="flex justify-between items-center">
            <div className="text-[#65D31D] dark:bg-[#011845] bg-[#e3f5d8] flex justify-center items-center border font-semibold border-[#65D31D] w-[133px] h-[32px] rounded-2xl">
              FREE ENTRY
            </div>
            <div className="text-[16px] font-semibold">Coming Soon</div>
          </div>
          <hr className="border border-childBorder" />
          <div className="flex justify-between items-center">
            <div className="w-[160px] text-left">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Start Date
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
            <div className="w-[160px] text-right">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Deadline
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
          </div>
          <div className="text-[#6C6C6C] text-[16px] font-normal">
            Test your skills and compete for your chance to win up to $1,000
            worth of prizes!
          </div>
          <hr className="border border-childBorder" />
          <div>
            <div className="flex justify-between">
              <div className="text-[#6C6C6C] text-[14px] font-medium ">
                2,649 Entries
              </div>
              <div className="text-[14px] font-semibold">42%</div>
            </div>

            <BlueProgressBar progress={40} />
            <div className="mt-5">
              <button className="w-full h-[44px] dark:bg-[#252525] dark:text-[#494949]  rounded-lg bg-[#EAEAEC] text-[#C6C6C6] font-semibold">
                Coming soon
              </button>
            </div>
          </div>
        </div>
        <div className=" rounded-2xl border border-childBorder bg-background  w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
          <div className="flex items-center gap-x-2">
            <div className="w-[44px] h-[44px] rounded-full border border-[#FDB528] bg-[#fef2da] dark:border-[#FDB528] dark:bg-[#2D2600] flex justify-center items-center">
              <OrangeStar />
            </div>
            <div className="font-semibold text-[20px]">
              Christmas Competition
            </div>
          </div>
          <div
            className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
            style={{
              background:
                "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
            }}
          >
            <span className="font-semibold text-[24px]">$10K</span> Challenge
          </div>
          <div className="flex justify-between items-center">
            <div className="text-[#65D31D] dark:bg-[#011845] bg-[#e3f5d8] flex justify-center items-center border font-semibold border-[#65D31D] w-[133px] h-[32px] rounded-2xl">
              FREE ENTRY
            </div>
            <div className="text-[16px] font-semibold">Coming Soon</div>
          </div>
          <hr className="border border-childBorder" />
          <div className="flex justify-between items-center">
            <div className="w-[160px] text-left">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Start Date
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
            <div className="w-[160px] text-right">
              <div className="text-[12px] text-[#6C6C6C] font-normal">
                Deadline
              </div>
              <div className="font-semibold text-[16px]">01/7/23</div>
            </div>
          </div>
          <div className="text-[#6C6C6C] text-[16px] font-normal">
            Test your skills and compete for your chance to win up to $1,000
            worth of prizes!
          </div>
          <hr className="border border-childBorder" />
          <div>
            <div className="flex justify-between">
              <div className="text-[#6C6C6C] text-[14px] font-medium ">
                2,649 Entries
              </div>
              <div className="text-[14px] font-semibold">42%</div>
            </div>

            <BlueProgressBar progress={40} />
            <div className="mt-5">
              <button className="w-full h-[44px] dark:bg-[#252525] dark:text-[#494949] rounded-lg bg-[#EAEAEC] text-[#C6C6C6] font-semibold">
                Coming soon
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*This is for Mobile screen*/}
      <div className="mt-5 block sm:hidden">
        <Swiper
          modules={[Pagination]}
          pagination={{
            clickable: true,
            el: ".custom-pagination",
          }}
          spaceBetween={30}
          loop={true}
          slidesPerView={1}
          centeredSlides={true}
        >
          <SwiperSlide>
            <div className="border border-childBorder bg-background  rounded-2xl  w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
              <div className="flex items-center gap-x-2">
                <div className="w-[44px] h-[44px] rounded-full border border-childBorder bg-[#EEEEEE] dark:bg-[#212121] flex justify-center items-center">
                  <GreyStar />
                </div>

                <div className="font-semibold text-[20px]">
                  July Competition
                </div>
              </div>
              <Link to={"/competition-details"}>
                <div
                  className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
                  style={{
                    background:
                      "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
                  }}
                >
                  <span className="font-semibold text-[24px]">$10K</span>{" "}
                  Challenge
                </div>
              </Link>
              <div className="flex justify-between items-center">
                <div className="text-[#FDB528] dark:bg-[#2D2600] bg-[#f5e7cd] flex justify-center items-center border font-semibold border-[#FDB528] w-[133px] h-[32px] rounded-2xl">
                  REGISTERED
                </div>
                <div className="text-[16px] font-semibold">In Progress</div>
              </div>
              <hr className="border border-childBorder" />
              <div className="flex justify-between items-center">
                <div className="w-[160px] text-left">
                  <div className="text-[12px] text-[#6C6C6C] font-normal">
                    Start Date
                  </div>
                  <div className="font-semibold text-[16px]">01/7/23</div>
                </div>
                <div className="w-[160px] text-right">
                  <div className="text-[12px] text-[#6C6C6C] font-normal">
                    Deadline
                  </div>
                  <div className="font-semibold text-[16px]">01/7/23</div>
                </div>
              </div>
              <div className="text-[#6C6C6C] text-[16px] font-normal">
                Test your skills and compete for your chance to win up to $1,000
                worth of prizes!
              </div>
              <hr className="border border-childBorder" />
              <div>
                <div className="flex justify-between">
                  <div className="text-[#6C6C6C] text-[14px] font-medium ">
                    2,649 Entries
                  </div>
                  <div className="text-[14px] font-semibold">42%</div>
                </div>

                <BlueProgressBar progress={40} />
                <div className="mt-5">
                  <button className="w-full h-[44px] rounded-lg border-[#6D788D] border text-[#6D788D] font-semibold">
                    Already joined
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="rounded-2xl border border-childBorder bg-background  w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
              <div className="flex items-center gap-x-2">
                <div className="w-[44px] h-[44px] rounded-full border border-[#EAEAEC] bg-[#E6EDFC] dark:border-[#6699FF] dark:bg-[#011845] flex justify-center items-center">
                  <BlueStar />
                </div>
                <div className="font-semibold text-[20px]">
                  Champion Competition
                </div>
              </div>
              <div
                className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
                style={{
                  background:
                    "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
                }}
              >
                <span className="font-semibold text-[24px]">$10K</span>{" "}
                Challenge
              </div>
              <div className="flex justify-between items-center">
                <div className="text-[#26C6F9] dark:bg-[#18283C] bg-[#c8ebf6] flex justify-center items-center border font-semibold border-[#26C6F9] w-[133px] h-[32px] rounded-2xl">
                  $25 ENTRY FEE
                </div>
                <div className="text-[16px] font-semibold">Upcoming</div>
              </div>
              <hr className="border border-childBorder" />
              <div className="flex justify-between items-center">
                <div className="w-[160px] text-left">
                  <div className="text-[12px] text-[#6C6C6C] font-normal">
                    Start Date
                  </div>
                  <div className="font-semibold text-[16px]">01/7/23</div>
                </div>
                <div className="w-[160px] text-right">
                  <div className="text-[12px] text-[#6C6C6C] font-normal">
                    Deadline
                  </div>
                  <div className="font-semibold text-[16px]">01/7/23</div>
                </div>
              </div>
              <div className="text-[#6C6C6C] text-[16px] font-normal">
                Test your skills and compete for your chance to win up to
                $250,000 worth of prizes!
              </div>
              <hr className="border border-childBorder" />
              <div>
                <div className="flex justify-between">
                  <div className="text-[#6C6C6C] text-[14px] font-medium ">
                    2,649 Entries
                  </div>
                  <div className="text-[14px] font-semibold">42%</div>
                </div>

                <BlueProgressBar progress={40} />
                <div className="mt-5">
                  <button className="w-full h-[44px] rounded-lg bg-[#6699FF] dark:text-black text-white font-semibold">
                    Join competition
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" rounded-2xl border border-childBorder bg-background  w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
              <div className="flex items-center gap-x-2">
                <div className="w-[44px] h-[44px] rounded-full border border-childBorder bg-[#EEEEEE] dark:bg-[#212121] flex justify-center items-center">
                  <GreyStar />
                </div>
                <div className="font-semibold text-[20px]">
                  September Competition
                </div>
              </div>
              <div
                className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
                style={{
                  background:
                    "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
                }}
              >
                <span className="font-semibold text-[24px]">$10K</span>{" "}
                Challenge
              </div>
              <div className="flex justify-between items-center">
                <div className="text-[#65D31D] dark:bg-[#011845] bg-[#e3f5d8] flex justify-center items-center font-semibold border border-[#65D31D] w-[133px] h-[32px] rounded-2xl">
                  FREE ENTRY
                </div>
                <div className="text-[16px] font-semibold">
                  Open Registration
                </div>
              </div>
              <hr className="border border-childBorder" />
              <div className="flex justify-between items-center">
                <div className="w-[160px] text-left">
                  <div className="text-[12px] text-[#6C6C6C] font-normal">
                    Start Date
                  </div>
                  <div className="font-semibold text-[16px]">01/7/23</div>
                </div>
                <div className="w-[160px] text-right">
                  <div className="text-[12px] text-[#6C6C6C] font-normal">
                    Deadline
                  </div>
                  <div className="font-semibold text-[16px]">01/7/23</div>
                </div>
              </div>
              <div className="text-[#6C6C6C] text-[16px] font-normal">
                Test your skills and compete for your chance to win up to $1,000
                worth of prizes!
              </div>
              <hr className="border border-childBorder" />
              <div>
                <div className="flex justify-between">
                  <div className="text-[#6C6C6C] text-[14px] font-medium ">
                    2,649 Entries
                  </div>
                  <div className="text-[14px] font-semibold">42%</div>
                </div>

                <BlueProgressBar progress={40} />
                <div className="mt-5">
                  <button className="w-full h-[44px] rounded-lg bg-[#6699FF] dark:text-black text-white font-semibold">
                    Join competition
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* Custom Pagination Dots */}
        <div className="custom-pagination flex justify-center mt-6"></div>
      </div>
      <div className="mt-5 block sm:hidden">
        <div className="font-semibold text-[24px] mb-5">
          Coming Soon Challenges
        </div>
        <Swiper
          modules={[Pagination]}
          pagination={{
            clickable: true,
            el: ".custom-pagination-1",
          }}
          spaceBetween={30}
          loop={true}
          slidesPerView={1}
          centeredSlides={true}
        >
          <SwiperSlide>
            <div className="rounded-2xl border border-childBorder bg-background  w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
              <div className="flex items-center gap-x-2">
                <div className="w-[44px] h-[44px] rounded-full border border-childBorder bg-[#EEEEEE] dark:bg-[#212121] flex justify-center items-center">
                  <GreyStar />
                </div>
                <div className="font-semibold text-[20px]">
                  October Competition
                </div>
              </div>
              <div
                className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
                style={{
                  background:
                    "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
                }}
              >
                <span className="font-semibold text-[24px]">$10K</span>{" "}
                Challenge
              </div>
              <div className="flex justify-between items-center">
                <div className="text-[#65D31D] dark:bg-[#011845] bg-[#e3f5d8] flex justify-center items-center font-semibold border border-[#65D31D] w-[133px] h-[32px] rounded-2xl">
                  FREE ENTRY
                </div>
                <div className="text-[16px] font-semibold">Coming Soon</div>
              </div>
              <hr className="border border-childBorder" />
              <div className="flex justify-between items-center">
                <div className="w-[160px] text-left">
                  <div className="text-[12px] text-[#6C6C6C] font-normal">
                    Start Date
                  </div>
                  <div className="font-semibold text-[16px]">01/7/23</div>
                </div>
                <div className="w-[160px] text-right">
                  <div className="text-[12px] text-[#6C6C6C] font-normal">
                    Deadline
                  </div>
                  <div className="font-semibold text-[16px]">01/7/23</div>
                </div>
              </div>
              <div className="text-[#6C6C6C] text-[16px] font-normal">
                Test your skills and compete for your chance to win up to $1,000
                worth of prizes!
              </div>
              <hr className="border border-childBorder" />
              <div>
                <div className="flex justify-between">
                  <div className="text-[#6C6C6C] text-[14px] font-medium ">
                    2,649 Entries
                  </div>
                  <div className="text-[14px] font-semibold">42%</div>
                </div>

                <BlueProgressBar progress={40} />
                <div className="mt-5">
                  <button className="w-full h-[44px] dark:bg-[#252525] dark:text-[#494949] rounded-lg bg-[#EAEAEC] text-[#C6C6C6] font-semibold">
                    Coming soon
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="rounded-2xl border border-childBorder bg-background  w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
              <div className="flex items-center gap-x-2">
                <div className="w-[44px] h-[44px] rounded-full border border-childBorder bg-[#EEEEEE] dark:bg-[#212121] flex justify-center items-center">
                  <GreyStar />
                </div>
                <div className="font-semibold text-[20px]">
                  November Competition
                </div>
              </div>
              <div
                className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
                style={{
                  background:
                    "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
                }}
              >
                <span className="font-semibold text-[24px]">$10K</span>{" "}
                Challenge
              </div>
              <div className="flex justify-between items-center">
                <div className="text-[#65D31D] dark:bg-[#011845] bg-[#e3f5d8] flex justify-center items-center border font-semibold border-[#65D31D] w-[133px] h-[32px] rounded-2xl">
                  FREE ENTRY
                </div>
                <div className="text-[16px] font-semibold">Coming Soon</div>
              </div>
              <hr className="border border-childBorder" />
              <div className="flex justify-between items-center">
                <div className="w-[160px] text-left">
                  <div className="text-[12px] text-[#6C6C6C] font-normal">
                    Start Date
                  </div>
                  <div className="font-semibold text-[16px]">01/7/23</div>
                </div>
                <div className="w-[160px] text-right">
                  <div className="text-[12px] text-[#6C6C6C] font-normal">
                    Deadline
                  </div>
                  <div className="font-semibold text-[16px]">01/7/23</div>
                </div>
              </div>
              <div className="text-[#6C6C6C] text-[16px] font-normal">
                Test your skills and compete for your chance to win up to $1,000
                worth of prizes!
              </div>
              <hr className="border border-childBorder" />
              <div>
                <div className="flex justify-between">
                  <div className="text-[#6C6C6C] text-[14px] font-medium ">
                    2,649 Entries
                  </div>
                  <div className="text-[14px] font-semibold">42%</div>
                </div>

                <BlueProgressBar progress={40} />
                <div className="mt-5">
                  <button className="w-full h-[44px] dark:bg-[#252525] dark:text-[#494949]  rounded-lg bg-[#EAEAEC] text-[#C6C6C6] font-semibold">
                    Coming soon
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className=" rounded-2xl border border-childBorder bg-background  w-fill max-w-[368px] flex flex-col gap-y-5  p-7">
              <div className="flex items-center gap-x-2">
                <div className="w-[44px] h-[44px] rounded-full border border-[#FDB528] bg-[#fef2da] dark:border-[#FDB528] dark:bg-[#2D2600] flex justify-center items-center">
                  <OrangeStar />
                </div>
                <div className="font-semibold text-[20px]">
                  Christmas Competition
                </div>
              </div>
              <div
                className="text-white text-[16px] rounded-[6px] flex justify-center items-center gap-x-1 py-2"
                style={{
                  background:
                    "linear-gradient(119.08deg, #0356FE 5.69%, #59C5FC 45.86%, #21D2C9 66.42%, #54CE02 101.32%)",
                }}
              >
                <span className="font-semibold text-[24px]">$10K</span>{" "}
                Challenge
              </div>
              <div className="flex justify-between items-center">
                <div className="text-[#65D31D] dark:bg-[#011845] bg-[#e3f5d8] flex justify-center items-center border font-semibold border-[#65D31D] w-[133px] h-[32px] rounded-2xl">
                  FREE ENTRY
                </div>
                <div className="text-[16px] font-semibold">Coming Soon</div>
              </div>
              <hr className="border border-childBorder" />
              <div className="flex justify-between items-center">
                <div className="w-[160px] text-left">
                  <div className="text-[12px] text-[#6C6C6C] font-normal">
                    Start Date
                  </div>
                  <div className="font-semibold text-[16px]">01/7/23</div>
                </div>
                <div className="w-[160px] text-right">
                  <div className="text-[12px] text-[#6C6C6C] font-normal">
                    Deadline
                  </div>
                  <div className="font-semibold text-[16px]">01/7/23</div>
                </div>
              </div>
              <div className="text-[#6C6C6C] text-[16px] font-normal">
                Test your skills and compete for your chance to win up to $1,000
                worth of prizes!
              </div>
              <hr className="border border-childBorder" />
              <div>
                <div className="flex justify-between">
                  <div className="text-[#6C6C6C] text-[14px] font-medium ">
                    2,649 Entries
                  </div>
                  <div className="text-[14px] font-semibold">42%</div>
                </div>

                <BlueProgressBar progress={40} />
                <div className="mt-5">
                  <button className="w-full h-[44px] dark:bg-[#252525] dark:text-[#494949] rounded-lg bg-[#EAEAEC] text-[#C6C6C6] font-semibold">
                    Coming soon
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* Custom Pagination Dots */}
        <div className="custom-pagination-1 flex justify-center mt-6"></div>
      </div>
    </div>
  );
};

export default Competitions;
