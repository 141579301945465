import React from "react";
import DonutChart from "./Charts/DonutChart";
const PopularAssets = () => {
  return (
    <div className="w-full bg-background rounded-2xl border-childBorder border p-5">
      <div className="font-semibold">Popular Assets</div>
      <div className="text-[#6C6C6C] text-[14px]">Ranked By Total Trades</div>
      <div className="mt-5">
        <DonutChart />
      </div>
    </div>
  );
};

export default PopularAssets;
