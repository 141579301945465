import React from "react";

const RecentDevices = () => {
  return (
    <div>
      <div className="font-semibold text-[20px]">Recent Devices</div>

      <div className="mt-5 w-full overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-white uppercase bg-[#6699FF]">
            <tr>
              <th scope="col" className="px-6 py-5 ">
                BROWSER/DEVICE
              </th>
              <th scope="col" className="px-6 py-5">
                LOCATION
              </th>
              <th scope="col" className="px-6 py-5">
                IP ADDRESS
              </th>
              <th scope="col" className="px-6 py-5">
                RECENT ACTIVITY
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white  dark:bg-gray-800 ">
              <td className="px-6 py-2">
                <div>
                  <div className="text-[16px] font-semibold text-black dark:text-white">
                    Chrome on Windows
                  </div>
                  <div className="text-[#6C6C6C] text-[14px]">Dell XPS 15</div>
                </div>
              </td>
              <td className="px-6 py-2">New York, NY</td>
              <td className="px-6 py-2">142.1234.7723</td>
              <td className="px-6 py-2 ">28 Apr 2022, 18:20</td>
            </tr>
            <tr className="bg-[#F6F8FE]  dark:bg-gray-800 ">
              <td className="px-6 py-2">
                <div>
                  <div className="text-[16px] font-semibold text-black dark:text-white">
                    Chrome on Windows
                  </div>
                  <div className="text-[#6C6C6C] text-[14px]">Dell XPS 15</div>
                </div>
              </td>
              <td className="px-6 py-2">New York, NY</td>
              <td className="px-6 py-2">142.1234.7723</td>
              <td className="px-6 py-2 ">28 Apr 2022, 18:20</td>
            </tr>
            <tr className="bg-white  dark:bg-gray-800 ">
              <td className="px-6 py-2">
                <div>
                  <div className="text-[16px] font-semibold text-black dark:text-white">
                    Chrome on Windows
                  </div>
                  <div className="text-[#6C6C6C] text-[14px]">Dell XPS 15</div>
                </div>
              </td>
              <td className="px-6 py-2">New York, NY</td>
              <td className="px-6 py-2">142.1234.7723</td>
              <td className="px-6 py-2 ">28 Apr 2022, 18:20</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentDevices;
