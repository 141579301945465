import { createContext, useEffect } from "react";
import useLocalStorage from "../Hooks/useLocalStorage";

export const ThemeContext = createContext();
export const ThemeProvider = ({ children }) => {
  //   const [dark, setDark] = useState(false);
  const [storeValue, setStoreValue] = useLocalStorage("theme", "");

  // Set the theme on the body element
  useEffect(() => {
    if (storeValue === "dark") {
      document.body.setAttribute("data-theme", "dark");
    } else {
      document.body.removeAttribute("data-theme");
    }
  }, [storeValue]);
  return (
    <>
      <ThemeContext.Provider value={{ storeValue, setStoreValue }}>
        {children}
      </ThemeContext.Provider>
    </>
  );
};
