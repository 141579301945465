


export const Home = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8593 8.36985L13.9293 2.82985C12.8593 1.96985 11.1293 1.96985 10.0693 2.81985L3.13929 8.36985C2.35929 8.98985 1.85929 10.2998 2.02929 11.2798L3.35929 19.2398C3.59929 20.6598 4.95929 21.8098 6.39929 21.8098H17.5993C19.0293 21.8098 20.3993 20.6498 20.6393 19.2398L21.9693 11.2798C22.1293 10.2998 21.6293 8.98985 20.8593 8.36985ZM11.9993 15.4998C10.6193 15.4998 9.49929 14.3798 9.49929 12.9998C9.49929 11.6198 10.6193 10.4998 11.9993 10.4998C13.3793 10.4998 14.4993 11.6198 14.4993 12.9998C14.4993 14.3798 13.3793 15.4998 11.9993 15.4998Z"
          fill="#C6C6C6"
        />
      </svg>
    </>
  );
};
export const Star = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7309 3.51014L15.4909 7.03014C15.7309 7.52014 16.3709 7.99014 16.9109 8.08014L20.1009 8.61014C22.1409 8.95014 22.6209 10.4301 21.1509 11.8901L18.6709 14.3701C18.2509 14.7901 18.0209 15.6001 18.1509 16.1801L18.8609 19.2501C19.4209 21.6801 18.1309 22.6201 15.9809 21.3501L12.9909 19.5801C12.4509 19.2601 11.5609 19.2601 11.0109 19.5801L8.02089 21.3501C5.88089 22.6201 4.58089 21.6701 5.14089 19.2501L5.85089 16.1801C5.98089 15.6001 5.75089 14.7901 5.33089 14.3701L2.85089 11.8901C1.39089 10.4301 1.86089 8.95014 3.90089 8.61014L7.09089 8.08014C7.62089 7.99014 8.26089 7.52014 8.50089 7.03014L10.2609 3.51014C11.2209 1.60014 12.7809 1.60014 13.7309 3.51014Z"
        fill="#C6C6C6"
      />
    </svg>
  );
};
export const Competition = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1992 7.16L7.19922 3.7V2.75C7.19922 2.34 6.85922 2 6.44922 2C6.03922 2 5.69922 2.34 5.69922 2.75V21.25C5.69922 21.66 6.03922 22 6.44922 22C6.85922 22 7.19922 21.66 7.19922 21.25V17.29L15.4192 13.23C15.4192 13.23 15.4192 13.23 15.4292 13.23C17.0892 12.37 17.9792 11.26 17.9292 10.09C17.8792 8.92 16.9092 7.88 15.1992 7.16Z"
        fill="#C6C6C6"
      />
    </svg>
  );
};

export const Leaderboard = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8095 5.49V6.23L14.2695 4.18C12.9295 3.41 11.0595 3.41 9.72945 4.18L6.18945 6.24V5.49C6.18945 3.24 7.41945 2 9.66945 2H14.3295C16.5795 2 17.8095 3.24 17.8095 5.49Z"
        fill="#C6C6C6"
      />
      <path
        d="M17.84 7.97023L17.7 7.90023L16.34 7.12023L13.52 5.49023C12.66 4.99023 11.34 4.99023 10.48 5.49023L7.66 7.11023L6.3 7.91023L6.12 8.00023C4.37 9.18023 4.25 9.40023 4.25 11.2902V15.8102C4.25 17.7002 4.37 17.9202 6.16 19.1302L10.48 21.6202C10.91 21.8802 11.45 21.9902 12 21.9902C12.54 21.9902 13.09 21.8702 13.52 21.6202L17.88 19.1002C19.64 17.9202 19.75 17.7102 19.75 15.8102V11.2902C19.75 9.40023 19.63 9.18023 17.84 7.97023ZM14.79 13.5002L14.18 14.2502C14.08 14.3602 14.01 14.5702 14.02 14.7202L14.08 15.6802C14.12 16.2702 13.7 16.5702 13.15 16.3602L12.26 16.0002C12.12 15.9502 11.89 15.9502 11.75 16.0002L10.86 16.3502C10.31 16.5702 9.89 16.2602 9.93 15.6702L9.99 14.7102C10 14.5602 9.93 14.3502 9.83 14.2402L9.21 13.5002C8.83 13.0502 9 12.5502 9.57 12.4002L10.5 12.1602C10.65 12.1202 10.82 11.9802 10.9 11.8602L11.42 11.0602C11.74 10.5602 12.25 10.5602 12.58 11.0602L13.1 11.8602C13.18 11.9902 13.36 12.1202 13.5 12.1602L14.43 12.4002C15 12.5502 15.17 13.0502 14.79 13.5002Z"
        fill="#C6C6C6"
      />
    </svg>
  );
};

export const Tools = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0199 10.6999L17.8999 12.0399C17.4999 12.2899 16.9899 12.2299 16.6599 11.8999L12.1299 7.36992C11.7999 7.03992 11.7399 6.52992 11.9899 6.12992L13.3299 4.00992C14.1499 2.71992 15.7899 2.65992 16.9999 3.85992L20.1799 7.03992C21.2999 8.16992 21.2299 9.92992 20.0199 10.6999Z"
        fill="#C6C6C6"
      />
      <path
        d="M14.7106 19.9502L5.99064 20.9702C5.91296 20.9806 5.83629 20.9879 5.76069 20.9924C5.07556 21.0327 4.90569 20.2252 5.39099 19.7399L7.98064 17.1502C8.30064 16.8402 8.30064 16.3502 7.98064 16.0402C7.68064 15.7302 7.19064 15.7302 6.87064 16.0402L4.28099 18.6299C3.79569 19.1152 2.99556 18.9446 3.03197 18.2592C3.03579 18.1873 3.04199 18.1143 3.05064 18.0402L4.08064 9.32024C4.34064 7.14024 5.14064 6.42024 7.44064 6.56024L8.94064 6.65024C9.43064 6.68024 9.89064 6.89024 10.2406 7.24024L16.7906 13.7902C17.1406 14.1402 17.3506 14.6002 17.3706 15.0902L17.4606 16.5902C17.6906 18.9002 16.9006 19.7002 14.7106 19.9502Z"
        fill="#C6C6C6"
      />
    </svg>
  );
};

export const Education = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15C15.7279 15 18.75 12.0899 18.75 8.5C18.75 4.91015 15.7279 2 12 2C8.27208 2 5.25 4.91015 5.25 8.5C5.25 12.0899 8.27208 15 12 15Z"
        fill="#C6C6C6"
      />
      <path
        d="M15.79 15.6101C16.12 15.4401 16.5 15.6901 16.5 16.0601V20.9101C16.5 21.8101 15.87 22.2501 15.09 21.8801L12.41 20.6101C12.18 20.5101 11.82 20.5101 11.59 20.6101L8.91 21.8801C8.13 22.2401 7.5 21.8001 7.5 20.9001L7.52 16.0601C7.52 15.6901 7.91 15.4501 8.23 15.6101C9.36 16.1801 10.64 16.5001 12 16.5001C13.36 16.5001 14.65 16.1801 15.79 15.6101Z"
        fill="#C6C6C6"
      />
    </svg>
  );
};

export const Mastery = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8203 2H7.18031C5.05031 2 3.32031 3.74 3.32031 5.86V19.95C3.32031 21.75 4.61031 22.51 6.19031 21.64L11.0703 18.93C11.5903 18.64 12.4303 18.64 12.9403 18.93L17.8203 21.64C19.4003 22.52 20.6903 21.76 20.6903 19.95V5.86C20.6803 3.74 18.9503 2 16.8203 2ZM15.0103 9.75C14.0403 10.1 13.0203 10.28 12.0003 10.28C10.9803 10.28 9.96031 10.1 8.99031 9.75C8.60031 9.61 8.40031 9.18 8.54031 8.79C8.69031 8.4 9.12031 8.2 9.51031 8.34C11.1203 8.92 12.8903 8.92 14.5003 8.34C14.8903 8.2 15.3203 8.4 15.4603 8.79C15.6003 9.18 15.4003 9.61 15.0103 9.75Z"
        fill="#C6C6C6"
      />
    </svg>
  );
};

export const Affiliate = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM17.26 9.96L14.95 12.94C14.66 13.31 14.25 13.55 13.78 13.6C13.31 13.66 12.85 13.53 12.48 13.24L10.65 11.8C10.58 11.74 10.5 11.74 10.46 11.75C10.42 11.75 10.35 11.77 10.29 11.85L7.91 14.94C7.76 15.13 7.54 15.23 7.32 15.23C7.16 15.23 7 15.18 6.86 15.07C6.53 14.82 6.47 14.35 6.72 14.02L9.1 10.93C9.39 10.56 9.8 10.32 10.27 10.26C10.73 10.2 11.2 10.33 11.57 10.62L13.4 12.06C13.47 12.12 13.54 12.12 13.59 12.11C13.63 12.11 13.7 12.09 13.76 12.01L16.07 9.03C16.32 8.7 16.8 8.64 17.12 8.9C17.45 9.17 17.51 9.64 17.26 9.96Z"
        fill="#C6C6C6"
      />
    </svg>
  );
};

export const Account = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5408 4.11984L13.0408 2.05984C12.4708 1.84984 11.5408 1.84984 10.9708 2.05984L5.47078 4.11984C4.41078 4.51984 3.55078 5.75984 3.55078 6.88984V14.9898C3.55078 15.7998 4.08078 16.8698 4.73078 17.3498L10.2308 21.4598C11.2008 22.1898 12.7908 22.1898 13.7608 21.4598L19.2608 17.3498C19.9108 16.8598 20.4408 15.7998 20.4408 14.9898V6.88984C20.4508 5.75984 19.5908 4.51984 18.5408 4.11984ZM15.4808 9.71984L11.1808 14.0198C11.0308 14.1698 10.8408 14.2398 10.6508 14.2398C10.4608 14.2398 10.2708 14.1698 10.1208 14.0198L8.52078 12.3998C8.23078 12.1098 8.23078 11.6298 8.52078 11.3398C8.81078 11.0498 9.29078 11.0498 9.58078 11.3398L10.6608 12.4198L14.4308 8.64984C14.7208 8.35984 15.2008 8.35984 15.4908 8.64984C15.7808 8.93984 15.7808 9.42984 15.4808 9.71984Z"
        fill="#C6C6C6"
      />
    </svg>
  );
};

export const Support = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8008 9.91016C17.6708 9.91016 16.6608 10.4602 16.0308 11.3002C15.4008 10.4602 14.3908 9.91016 13.2608 9.91016C11.3508 9.91016 9.80078 11.4702 9.80078 13.3902C9.80078 14.1302 9.92078 14.8202 10.1208 15.4502C11.1008 18.5602 14.1408 20.4302 15.6408 20.9402C15.8508 21.0102 16.2008 21.0102 16.4108 20.9402C17.9108 20.4302 20.9508 18.5702 21.9308 15.4502C22.1408 14.8102 22.2508 14.1302 22.2508 13.3902C22.2608 11.4702 20.7108 9.91016 18.8008 9.91016Z"
        fill="#C6C6C6"
      />
      <path
        d="M20.75 8.3401C20.75 8.5701 20.52 8.7201 20.3 8.6601C18.95 8.3101 17.47 8.6001 16.35 9.4001C16.13 9.5601 15.83 9.5601 15.62 9.4001C14.83 8.8201 13.87 8.5001 12.86 8.5001C10.28 8.5001 8.18 10.6101 8.18 13.2101C8.18 16.0301 9.53 18.1401 10.89 19.5501C10.96 19.6201 10.9 19.7401 10.81 19.7001C8.08 18.7701 2 14.9101 2 8.3401C2 5.4401 4.33 3.1001 7.21 3.1001C8.92 3.1001 10.43 3.9201 11.38 5.1901C12.34 3.9201 13.85 3.1001 15.55 3.1001C18.42 3.1001 20.75 5.4401 20.75 8.3401Z"
        fill="#C6C6C6"
      />
    </svg>
  );
};

export const DarkMode = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49935 12.3332C10.8926 12.3332 12.8327 10.3931 12.8327 7.99984C12.8327 5.6066 10.8926 3.6665 8.49935 3.6665C6.10611 3.6665 4.16602 5.6066 4.16602 7.99984C4.16602 10.3931 6.10611 12.3332 8.49935 12.3332Z"
        fill="#2B2A2A"
        stroke="#2B2A2A"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.2607 12.7602L13.174 12.6735M13.174 3.32683L13.2607 3.24016L13.174 3.32683ZM3.74065 12.7602L3.82732 12.6735L3.74065 12.7602ZM8.50065 1.38683V1.3335V1.38683ZM8.50065 14.6668V14.6135V14.6668ZM1.88732 8.00016H1.83398H1.88732ZM15.1673 8.00016H15.114H15.1673ZM3.82732 3.32683L3.74065 3.24016L3.82732 3.32683Z"
        stroke="#2B2A2A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const LightMode = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.85404 8.27997C2.09404 11.7133 5.00738 14.5066 8.49404 14.66C10.954 14.7666 13.154 13.62 14.474 11.8133C15.0207 11.0733 14.7274 10.58 13.814 10.7466C13.3674 10.8266 12.9074 10.86 12.4274 10.84C9.16738 10.7066 6.50071 7.97997 6.48738 4.75997C6.48071 3.89331 6.66071 3.07331 6.98738 2.32664C7.34738 1.49997 6.91404 1.10664 6.08071 1.45997C3.44071 2.57331 1.63404 5.23331 1.85404 8.27997Z"
        fill="#6C6C6C"
        stroke="#6C6C6C"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
