import React, { useState } from "react";
import {
  AccountView,
  MedalStar,
} from "../../../Assets/Mychallenge/SvgMyChallenge";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
const BlueProgressBar = ({ progress }) => {
  return (
    <div className="w-full  bg-[#EDF4FF] dark:bg-[#011845] rounded-full h-2 mt-2">
      <div
        className="bg-[#6699FF] h-2 rounded-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};
const MyChallenge = () => {
  const [activeTab, setActiveTab] = useState("myChallenge");
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "newChallenge") {
      navigate("/challenge/new-challenge");
    } else if (tab === "myChallenge") {
      navigate("/challenge/my-challenge");
    }
  };
  return (
    <div className="bg-background border border-childBorder rounded-2xl p-5 text-textPrimary">
      <div className="flex justify-center mt-5">
        <div className="bg-[#F5F5F5] dark:bg-[#0D0D0D] w-[256px] h-[42px] rounded-xl flex items-center justify-center px-2">
          <div
            onClick={() => handleTabClick("newChallenge")}
            className={`w-[127px] h-[30px] text-[13px] flex justify-center items-center cursor-pointer ${
              activeTab === "newChallenge"
                ? "bg-[white] text-[#6699FF] font-semibold rounded-lg"
                : "text-[#6C6C6C]"
            }`}
          >
            New Challenge
          </div>
          <div
            onClick={() => handleTabClick("myChallenge")}
            className={`w-[127px] h-[30px] text-[13px] flex justify-center items-center cursor-pointer ${
              activeTab === "myChallenge"
                ? "bg-[white] text-[#6699FF] dark:text-white dark:bg-[#011845] font-semibold rounded-lg"
                : "text-[#6C6C6C]"
            }`}
          >
            My Challenge
          </div>
        </div>
      </div>

      <div className="font-semibold text-center text-[24px] lg:text-[34px] mt-5">
        Active Challenges
      </div>
      <div className="text-[#6C6C6C] text-center text-[14px] lg:text-[16px]">
        Access your dashboards and account credentials below.
      </div>
      {/*This is for large screen*/}
      <div className="lg:grid lg:gap-5 mt-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 hidden">
        <div className=" border border-childBorder rounded-2xl p-5 flex flex-col gap-y-5 w-full">
          <div className="flex items-center gap-x-2 ">
            <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center  border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
              <MedalStar />
            </div>

            <div>
              <div className="text-[16px] font-semibold">Account #52422</div>
              <div className="text-[#6C6C6C] font-medium text-[14px]">
                @Account#52422
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between">
              <div className="border-[#65D31D]  border rounded-2xl text-[#65D31D] font-semibold text-[16px] flex justify-center items-center dark:bg-[#00270B] bg-[#F4FDEE] w-[92px]">
                ACTIVE
              </div>
              <div className="text-[20px] font-semibold">$10k</div>
            </div>
            <hr className="border border-childBorder" />
            <div className="flex justify-between">
              <div>
                <div className="text-[#6C6C6C] text-[12px]">Evaluation</div>
                <div className="font-semibold text-[16px]">Single</div>
              </div>
              <div className="text-right">
                <div className="text-[#6C6C6C] text-[12px]">Current Stage:</div>
                <div className="font-semibold text-[16px]">Unlimited</div>
              </div>
            </div>
            <div className="flex justify-between">
              <div>
                <div className="text-[#6C6C6C] text-[12px]">Account</div>
                <div className="font-semibold text-[16px]">Standard</div>
              </div>
              <div className="text-right">
                <div className="text-[#6C6C6C] text-[12px]">Total Profit</div>
                <div className="font-semibold text-[16px]">9.95%</div>
              </div>
            </div>
            <hr className="border border-childBorder" />
            <div>
              <BlueProgressBar progress={0} />
              <div className="flex justify-between">
                <div className="text-[#6C6C6C] font-medium">Progress</div>
                <div>0%</div>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-x-2">
            <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
              <AccountView />
            </div>

            <Link to={"/challenge/dashboard"}>
              <button className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]">
                Dashboard
              </button>
            </Link>
          </div>
        </div>
        <div className="border border-childBorder rounded-2xl p-5 flex flex-col gap-y-5 w-full">
          <div className="flex items-center gap-x-2">
            <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center  border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
              <MedalStar />
            </div>
            <div>
              <div className="text-[16px] font-semibold">Account #52422</div>
              <div className="text-[#6C6C6C] font-medium text-[14px]">
                @Account#52422
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between">
              <div className="border-[#65D31D] border rounded-2xl text-[#65D31D] font-semibold text-[16px] flex justify-center items-center dark:bg-[#00270B] bg-[#F4FDEE] w-[92px]">
                ACTIVE
              </div>
              <div className="text-[20px] font-semibold">$10k</div>
            </div>
            <hr className="border border-childBorder" />
            <div className="flex justify-between">
              <div>
                <div className="text-[#6C6C6C] text-[12px]">Evaluation</div>
                <div className="font-semibold text-[16px]">Single</div>
              </div>
              <div className="text-right">
                <div className="text-[#6C6C6C] text-[12px]">Current Stage:</div>
                <div className="font-semibold text-[16px]">Unlimited</div>
              </div>
            </div>
            <div className="flex justify-between">
              <div>
                <div className="text-[#6C6C6C] text-[12px]">Account</div>
                <div className="font-semibold text-[16px]">Standard</div>
              </div>
              <div className="text-right">
                <div className="text-[#6C6C6C] text-[12px]">Total Profit</div>
                <div className="font-semibold text-[16px]">9.95%</div>
              </div>
            </div>
            <hr className="border border-childBorder" />
            <div>
              <BlueProgressBar progress={50} />
              <div className="flex justify-between">
                <div className="text-[#6C6C6C] font-medium">Progress</div>
                <div>50%</div>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-x-2">
            <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
              <AccountView />
            </div>
            <button className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]">
              Dashboard
            </button>
          </div>
        </div>
        <div className="border border-childBorder rounded-2xl p-5 flex flex-col gap-y-5 w-full">
          <div className="flex items-center gap-x-2">
            <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center  border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
              <MedalStar />
            </div>
            <div>
              <div className="text-[16px] font-semibold">Account #52422</div>
              <div className="text-[#6C6C6C] font-medium text-[14px]">
                @Account#52422
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-4">
            <div className="flex justify-between">
              <div className="border-[#65D31D] border rounded-2xl text-[#65D31D] font-semibold text-[16px] flex justify-center items-center dark:bg-[#00270B] bg-[#F4FDEE] w-[92px]">
                ACTIVE
              </div>
              <div className="text-[20px] font-semibold">$10k</div>
            </div>
            <hr className="border border-childBorder" />
            <div className="flex justify-between">
              <div>
                <div className="text-[#6C6C6C] text-[12px]">Evaluation</div>
                <div className="font-semibold text-[16px]">Single</div>
              </div>
              <div className="text-right">
                <div className="text-[#6C6C6C] text-[12px]">Current Stage:</div>
                <div className="font-semibold text-[16px]">Unlimited</div>
              </div>
            </div>
            <div className="flex justify-between">
              <div>
                <div className="text-[#6C6C6C] text-[12px]">Account</div>
                <div className="font-semibold text-[16px]">Standard</div>
              </div>
              <div className="text-right">
                <div className="text-[#6C6C6C] text-[12px]">Total Profit</div>
                <div className="font-semibold text-[16px]">9.95%</div>
              </div>
            </div>
            <hr className="border border-childBorder" />
            <div>
              <BlueProgressBar progress={100} />
              <div className="flex justify-between">
                <div className="text-[#6C6C6C] font-medium">Progress</div>
                <div>100%</div>
              </div>
            </div>
          </div>
          <div className="flex justify-between gap-x-2">
            <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
              <AccountView />
            </div>
            <button className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]">
              Dashboard
            </button>
          </div>
        </div>
      </div>
      {/*This is for Mobile screen*/}
      <div className="mt-5 block sm:hidden">
        <Swiper
          modules={[Pagination]}
          pagination={{
            clickable: true,
            el: ".custom-pagination-4",
          }}
          spaceBetween={30}
          loop={true}
          slidesPerView={1}
          centeredSlides={true}
        >
          <SwiperSlide>
            <div className=" border border-childBorder rounded-2xl p-5 flex flex-col gap-y-5 w-full">
              <div className="flex items-center gap-x-2 ">
                <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center  border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
                  <MedalStar />
                </div>

                <div>
                  <div className="text-[16px] font-semibold">
                    Account #52422
                  </div>
                  <div className="text-[#6C6C6C] font-medium text-[14px]">
                    @Account#52422
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-4">
                <div className="flex justify-between">
                  <div className="border-[#65D31D]  border rounded-2xl text-[#65D31D] font-semibold text-[16px] flex justify-center items-center dark:bg-[#00270B] bg-[#F4FDEE] w-[92px]">
                    ACTIVE
                  </div>
                  <div className="text-[20px] font-semibold">$10k</div>
                </div>
                <hr className="border border-childBorder" />
                <div className="flex justify-between">
                  <div>
                    <div className="text-[#6C6C6C] text-[12px]">Evaluation</div>
                    <div className="font-semibold text-[16px]">Single</div>
                  </div>
                  <div className="text-right">
                    <div className="text-[#6C6C6C] text-[12px]">
                      Current Stage:
                    </div>
                    <div className="font-semibold text-[16px]">Unlimited</div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <div className="text-[#6C6C6C] text-[12px]">Account</div>
                    <div className="font-semibold text-[16px]">Standard</div>
                  </div>
                  <div className="text-right">
                    <div className="text-[#6C6C6C] text-[12px]">
                      Total Profit
                    </div>
                    <div className="font-semibold text-[16px]">9.95%</div>
                  </div>
                </div>
                <hr className="border border-childBorder" />
                <div>
                  <BlueProgressBar progress={0} />
                  <div className="flex justify-between">
                    <div className="text-[#6C6C6C] font-medium">Progress</div>
                    <div>0%</div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between gap-x-2">
                <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
                  <AccountView />
                </div>

                <button
                  onClick={() => navigate("/challenge/dashboard")}
                  className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]"
                >
                  Dashboard
                </button>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="border border-childBorder rounded-2xl p-5 flex flex-col gap-y-5 w-full">
              <div className="flex items-center gap-x-2">
                <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center  border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
                  <MedalStar />
                </div>
                <div>
                  <div className="text-[16px] font-semibold">
                    Account #52422
                  </div>
                  <div className="text-[#6C6C6C] font-medium text-[14px]">
                    @Account#52422
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-4">
                <div className="flex justify-between">
                  <div className="border-[#65D31D] border rounded-2xl text-[#65D31D] font-semibold text-[16px] flex justify-center items-center dark:bg-[#00270B] bg-[#F4FDEE] w-[92px]">
                    ACTIVE
                  </div>
                  <div className="text-[20px] font-semibold">$10k</div>
                </div>
                <hr className="border border-childBorder" />
                <div className="flex justify-between">
                  <div>
                    <div className="text-[#6C6C6C] text-[12px]">Evaluation</div>
                    <div className="font-semibold text-[16px]">Single</div>
                  </div>
                  <div className="text-right">
                    <div className="text-[#6C6C6C] text-[12px]">
                      Current Stage:
                    </div>
                    <div className="font-semibold text-[16px]">Unlimited</div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <div className="text-[#6C6C6C] text-[12px]">Account</div>
                    <div className="font-semibold text-[16px]">Standard</div>
                  </div>
                  <div className="text-right">
                    <div className="text-[#6C6C6C] text-[12px]">
                      Total Profit
                    </div>
                    <div className="font-semibold text-[16px]">9.95%</div>
                  </div>
                </div>
                <hr className="border border-childBorder" />
                <div>
                  <BlueProgressBar progress={50} />
                  <div className="flex justify-between">
                    <div className="text-[#6C6C6C] font-medium">Progress</div>
                    <div>50%</div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between gap-x-2">
                <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
                  <AccountView />
                </div>
                <button className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]">
                  Dashboard
                </button>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="border border-childBorder rounded-2xl p-5 flex flex-col gap-y-5 w-full">
              <div className="flex items-center gap-x-2">
                <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center  border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
                  <MedalStar />
                </div>
                <div>
                  <div className="text-[16px] font-semibold">
                    Account #52422
                  </div>
                  <div className="text-[#6C6C6C] font-medium text-[14px]">
                    @Account#52422
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-y-4">
                <div className="flex justify-between">
                  <div className="border-[#65D31D] border rounded-2xl text-[#65D31D] font-semibold text-[16px] flex justify-center items-center dark:bg-[#00270B] bg-[#F4FDEE] w-[92px]">
                    ACTIVE
                  </div>
                  <div className="text-[20px] font-semibold">$10k</div>
                </div>
                <hr className="border border-childBorder" />
                <div className="flex justify-between">
                  <div>
                    <div className="text-[#6C6C6C] text-[12px]">Evaluation</div>
                    <div className="font-semibold text-[16px]">Single</div>
                  </div>
                  <div className="text-right">
                    <div className="text-[#6C6C6C] text-[12px]">
                      Current Stage:
                    </div>
                    <div className="font-semibold text-[16px]">Unlimited</div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <div className="text-[#6C6C6C] text-[12px]">Account</div>
                    <div className="font-semibold text-[16px]">Standard</div>
                  </div>
                  <div className="text-right">
                    <div className="text-[#6C6C6C] text-[12px]">
                      Total Profit
                    </div>
                    <div className="font-semibold text-[16px]">9.95%</div>
                  </div>
                </div>
                <hr className="border border-childBorder" />
                <div>
                  <BlueProgressBar progress={100} />
                  <div className="flex justify-between">
                    <div className="text-[#6C6C6C] font-medium">Progress</div>
                    <div>100%</div>
                  </div>
                </div>
              </div>
              <div className="flex justify-between gap-x-2">
                <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
                  <AccountView />
                </div>
                <button className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]">
                  Dashboard
                </button>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* Custom Pagination Dots */}
        <div className="custom-pagination-4 flex justify-center mt-6"></div>
      </div>

      <div className="mt-5">
        {/*This is for large screen*/}
        <div className="font-semibold text-[16px]">Inactive Challenges</div>
        <div className="mt-5 lg:grid lg:gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 hidden ">
          <div className="border bg-[#f9f3e6] dark:bg-[#2D2600] border-[#FDB528] rounded-2xl p-5 flex flex-col gap-y-5 w-full">
            <div className="flex items-center gap-x-2">
              <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center  border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
                <MedalStar />
              </div>
              <div>
                <div className="text-[16px] font-semibold">Account #52422</div>
                <div className="text-[#6C6C6C] font-medium text-[14px]">
                  @Account#52422
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-4">
              <div className="flex justify-between">
                <div className="border-[#FDB528] dark:bg-[#2D2600] border rounded-2xl text-[#FDB528] font-semibold text-[16px] flex justify-center items-center bg-[#f9f3e6] w-[92px]">
                  CLOSED
                </div>
                <div className="text-[20px] font-semibold">$10k</div>
              </div>
              <hr className="border border-childBorder" />
              <div className="flex justify-between">
                <div>
                  <div className="text-[#6C6C6C] text-[12px]">Evaluation</div>
                  <div className="font-semibold text-[16px]">Single</div>
                </div>
                <div className="text-right">
                  <div className="text-[#6C6C6C] text-[12px]">
                    Current Stage:
                  </div>
                  <div className="font-semibold text-[16px]">Unlimited</div>
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <div className="text-[#6C6C6C] text-[12px]">Account</div>
                  <div className="font-semibold text-[16px]">Standard</div>
                </div>
                <div className="text-right">
                  <div className="text-[#6C6C6C] text-[12px]">Total Profit</div>
                  <div className="font-semibold text-[16px]">9.95%</div>
                </div>
              </div>
              <hr className="border border-childBorder" />
              <div>
                <BlueProgressBar progress={0} />
                <div className="flex justify-between">
                  <div className="text-[#6C6C6C] font-medium">Progress</div>
                  <div>0%</div>
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-x-2">
              <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
                <AccountView />
              </div>
              <button className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]">
                Dashboard
              </button>
            </div>
          </div>
          <div className="border bg-[#f3dedd] dark:bg-[#220000]  border-[#FF4D49] rounded-2xl p-5 flex flex-col gap-y-5 w-full">
            <div className="flex items-center gap-x-2">
              <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center  border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
                <MedalStar />
              </div>
              <div>
                <div className="text-[16px] font-semibold">Account #52422</div>
                <div className="text-[#6C6C6C] font-medium text-[14px]">
                  @Account#52422
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-4">
              <div className="flex justify-between">
                <div className="border-[#FF4D49] dark:bg-[#220000] bg-[#f3dedd]  border rounded-2xl text-[#FF4D49] font-semibold text-[16px] flex justify-center items-center  w-[92px]">
                  ACTIVE
                </div>
                <div className="text-[20px] font-semibold">$35k</div>
              </div>
              <hr className="border border-childBorder" />
              <div className="flex justify-between">
                <div>
                  <div className="text-[#6C6C6C] text-[12px]">Evaluation</div>
                  <div className="font-semibold text-[16px]">Single</div>
                </div>
                <div className="text-right">
                  <div className="text-[#6C6C6C] text-[12px]">
                    Current Stage:
                  </div>
                  <div className="font-semibold text-[16px]">Unlimited</div>
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <div className="text-[#6C6C6C] text-[12px]">Account</div>
                  <div className="font-semibold text-[16px]">Standard</div>
                </div>
                <div className="text-right">
                  <div className="text-[#6C6C6C] text-[12px]">Total Profit</div>
                  <div className="font-semibold text-[16px]">9.95%</div>
                </div>
              </div>
              <hr className="border border-childBorder" />
              <div>
                <BlueProgressBar progress={30} />
                <div className="flex justify-between">
                  <div className="text-[#6C6C6C] font-medium">Progress</div>
                  <div>30%</div>
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-x-2">
              <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
                <AccountView />
              </div>
              <button className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]">
                Dashboard
              </button>
            </div>
          </div>
        </div>
        {/*This is for Mobile screen*/}
        <div className="mt-5 block sm:hidden">
          <Swiper
            modules={[Pagination]}
            pagination={{
              clickable: true,
              el: ".custom-pagination-5",
            }}
            spaceBetween={30}
            loop={true}
            slidesPerView={1}
            centeredSlides={true}
          >
            <SwiperSlide>
              <div className="border bg-[#f9f3e6] dark:bg-[#2D2600] border-[#FDB528] rounded-2xl p-5 flex flex-col gap-y-5 w-full">
                <div className="flex items-center gap-x-2">
                  <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center  border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
                    <MedalStar />
                  </div>
                  <div>
                    <div className="text-[16px] font-semibold">
                      Account #52422
                    </div>
                    <div className="text-[#6C6C6C] font-medium text-[14px]">
                      @Account#52422
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-y-4">
                  <div className="flex justify-between">
                    <div className="border-[#FDB528] dark:bg-[#2D2600] border rounded-2xl text-[#FDB528] font-semibold text-[16px] flex justify-center items-center bg-[#f9f3e6] w-[92px]">
                      CLOSED
                    </div>
                    <div className="text-[20px] font-semibold">$10k</div>
                  </div>
                  <hr className="border border-childBorder" />
                  <div className="flex justify-between">
                    <div>
                      <div className="text-[#6C6C6C] text-[12px]">
                        Evaluation
                      </div>
                      <div className="font-semibold text-[16px]">Single</div>
                    </div>
                    <div className="text-right">
                      <div className="text-[#6C6C6C] text-[12px]">
                        Current Stage:
                      </div>
                      <div className="font-semibold text-[16px]">Unlimited</div>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>
                      <div className="text-[#6C6C6C] text-[12px]">Account</div>
                      <div className="font-semibold text-[16px]">Standard</div>
                    </div>
                    <div className="text-right">
                      <div className="text-[#6C6C6C] text-[12px]">
                        Total Profit
                      </div>
                      <div className="font-semibold text-[16px]">9.95%</div>
                    </div>
                  </div>
                  <hr className="border border-childBorder" />
                  <div>
                    <BlueProgressBar progress={0} />
                    <div className="flex justify-between">
                      <div className="text-[#6C6C6C] font-medium">Progress</div>
                      <div>0%</div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between gap-x-2">
                  <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
                    <AccountView />
                  </div>
                  <button className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]">
                    Dashboard
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="border bg-[#f3dedd] dark:bg-[#220000]  border-[#FF4D49] rounded-2xl p-5 flex flex-col gap-y-5 w-full">
                <div className="flex items-center gap-x-2">
                  <div className="h-[44px] w-[44px] rounded-full flex justify-center items-center  border border-childBorder dark:bg-[#212121] bg-[#EEEEEE] ">
                    <MedalStar />
                  </div>
                  <div>
                    <div className="text-[16px] font-semibold">
                      Account #52422
                    </div>
                    <div className="text-[#6C6C6C] font-medium text-[14px]">
                      @Account#52422
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-y-4">
                  <div className="flex justify-between">
                    <div className="border-[#FF4D49] dark:bg-[#220000] bg-[#f3dedd]  border rounded-2xl text-[#FF4D49] font-semibold text-[16px] flex justify-center items-center  w-[92px]">
                      ACTIVE
                    </div>
                    <div className="text-[20px] font-semibold">$35k</div>
                  </div>
                  <hr className="border border-childBorder" />
                  <div className="flex justify-between">
                    <div>
                      <div className="text-[#6C6C6C] text-[12px]">
                        Evaluation
                      </div>
                      <div className="font-semibold text-[16px]">Single</div>
                    </div>
                    <div className="text-right">
                      <div className="text-[#6C6C6C] text-[12px]">
                        Current Stage:
                      </div>
                      <div className="font-semibold text-[16px]">Unlimited</div>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div>
                      <div className="text-[#6C6C6C] text-[12px]">Account</div>
                      <div className="font-semibold text-[16px]">Standard</div>
                    </div>
                    <div className="text-right">
                      <div className="text-[#6C6C6C] text-[12px]">
                        Total Profit
                      </div>
                      <div className="font-semibold text-[16px]">9.95%</div>
                    </div>
                  </div>
                  <hr className="border border-childBorder" />
                  <div>
                    <BlueProgressBar progress={30} />
                    <div className="flex justify-between">
                      <div className="text-[#6C6C6C] font-medium">Progress</div>
                      <div>30%</div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between gap-x-2">
                  <div className="h-[44px] w-[44px] bg-[#FAFCFD] dark:bg-[#252525] flex justify-center items-center rounded-lg border border-childBorder ">
                    <AccountView />
                  </div>
                  <button className="bg-[#6699FF] text-white font-semibold rounded-xl w-full lg:w-[248px] h-[44px]">
                    Dashboard
                  </button>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          {/* Custom Pagination Dots */}
          <div className="custom-pagination-5 flex justify-center mt-6"></div>
        </div>
      </div>
    </div>
  );
};

export default MyChallenge;
