import React from "react";
import certificate from "../../Assets/Achievements/certificate.png";
import {
  Download,
  Lock,
  LockMedal,
  UnLockMedal,
} from "../../Assets/Achievements/SvgAchievements";
const Certificates = () => {
  const certificates = [
    {
      id: 1,
      title: "Passed 1st Phase",
      description: "Pass evaluation phase (1)",
      image: certificate,
      icon: "🔓",
      Medal: <UnLockMedal />,
    },
    {
      id: 2,
      title: "Passed 2nd Phase",
      description: "Pass evaluation phase (2)",
      image: certificate,
      icon: "🔓",
    },
    {
      id: 3,
      title: "Passed 3rd Phase",
      description: "Pass evaluation phase (3)",
      image: certificate,
      icon: "🔒",
      Medal: <LockMedal />,
    },
    {
      id: 4,
      title: "Funded Account",
      description: "Reach a funded account",
      image: certificate,
      icon: "🔓",
    },
    {
      id: 5,
      title: "Payout Certificate",
      description: "Reach a funded payout",
      image: certificate,
      icon: "🔒",
    },
    {
      id: 6,
      title: "Competition Winner",
      description: "...",
      image: certificate,
      icon: "🔒",
    },
  ];

  return (
    <div className=" min-h-screen">
      <div className="lg:text-[34px] text-[24px] text-center font-semibold">
        Certificates
      </div>
      <div className="text-[#6C6C6C] lg:text-base  text-[14px] text-center">
        Unlock personalized certificates to showcase your achievements.
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-10">
        {certificates.map((certificate) => (
          <div
            key={certificate.id}
            className="border border-childBorder rounded-2xl bg-background shadow-sm  flex flex-col gap-2"
          >
            <div className="relative">
              <img
                src={certificate.image}
                alt={certificate.title}
                className="rounded-2xl w-full h-48 object-cover"
              />
              <div className="absolute top-2 left-2 z-30">
                {certificate.icon === "🔒" ? <LockMedal /> : <UnLockMedal />}
              </div>

              {certificate.icon === "🔒" && (
                <div className="absolute inset-0 bg-black bg-opacity-50 rounded-lg z-20"></div>
              )}

              {certificate.icon === "🔒" && (
                <div className="absolute inset-0 flex items-center justify-center z-30">
                  <div className=" ">
                    <Lock />
                  </div>
                </div>
              )}
            </div>
            <div className="p-5 flex justify-between">
              <div className="mt-2">
                <h3 className="font-semibold text-base">{certificate.title}</h3>
                <p className="text-[#6C6C6C] text-sm">
                  {certificate.description}
                </p>
              </div>

              <div className="flex justify-end">
                <button
                  disabled={certificate.icon === "🔒"}
                  className={`text-blue-500 ${
                    certificate.icon === "🔒"
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                >
                  <Download
                    className={`w-5 h-5 ${
                      certificate.icon === "🔒" ? "opacity-50" : ""
                    }`}
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Certificates;
