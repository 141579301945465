import React from "react";
import blogimage from "../../Assets/Home/blogimage.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

const HomeBlogs = () => {
  return (
    <>
      {/*This of for large screen*/}
      <div className="  mt-3 lg:flex flex-wrap gap-5 justify-center lg:justify-start hidden sm:flex">
        <div className=" bg-background border border-childBorder rounded-2xl w-full sm:w-[380px]">
          <div>
            <img src={blogimage} alt="Blog" className="w-full rounded-t-2xl" />
          </div>
          <div className="p-5 flex flex-col space-y-3">
            <div className="text-[#6699FF] text-[14px] font-medium">
              Phoenix Baker • 19 Jan 2024
            </div>
            <div className="text-[16px] font-semibold">MT5 Tips & Tricks</div>
            <div className="text-[#6C6C6C] text-[14px]">
              Managing your money isn’t the easiest thing to do. Now that many
              of us no longer balance a checkbook, tracking and expenses.
            </div>
            <div className="flex flex-wrap gap-2">
              <div className="text-[#65D31D] bg-[#F4FDEE] dark:bg-[#00270B]  flex justify-center items-center text-[12px] w-[87px] h-[28px] font-medium rounded-xl">
                Helpful Tips
              </div>
              <div className="text-[#26C6F9] bg-[#deeff4] dark:bg-[#18283C] flex justify-center items-center text-[12px] w-[87px] h-[28px] font-medium rounded-xl">
                Helpful Tips
              </div>
            </div>
          </div>
        </div>

        <div className=" bg-background border border-childBorder rounded-2xl w-full sm:w-[380px]">
          <div>
            <img src={blogimage} alt="Blog" className="w-full rounded-t-2xl" />
          </div>
          <div className="p-5 flex flex-col space-y-3">
            <div className="text-[#6699FF] text-[14px] font-medium">
              Lana Steiner • 18 Jan 2024
            </div>
            <div className="text-[16px] font-semibold">
              Identifying Profitable Strategies
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Managing your money isn’t the easiest thing to do. Now that many
              of us no longer balance a checkbook, tracking and expenses.
            </div>
            <div className="">
              <div className="text-[#26C6F9] bg-[#deeff4] dark:bg-[#18283C] flex justify-center items-center text-[12px] w-[87px] h-[28px] font-medium rounded-xl">
                Helpful Tips
              </div>
            </div>
          </div>
        </div>
        <div className=" bg-background border border-childBorder rounded-2xl w-full sm:w-[380px]">
          <div>
            <img src={blogimage} alt="Blog" className="w-full rounded-t-2xl" />
          </div>
          <div className="p-5 flex flex-col space-y-3">
            <div className="text-[#6699FF] text-[14px] font-medium">
              Phoenix Baker • 19 Jan 2024
            </div>
            <div className="text-[16px] font-semibold">MT5 Tips & Tricks</div>
            <div className="text-[#6C6C6C] text-[14px]">
              Managing your money isn’t the easiest thing to do. Now that many
              of us no longer balance a checkbook, tracking and expenses.
            </div>
            <div className="flex flex-wrap gap-2">
              <div className="text-[#65D31D] bg-[#F4FDEE] dark:bg-[#00270B] flex justify-center items-center text-[12px] w-[87px] h-[28px] font-medium rounded-xl">
                Helpful Tips
              </div>
              <div className="text-[#FDB528] bg-[#f5eede] dark:bg-[#2D2600] flex justify-center items-center text-[12px] w-[50px] h-[28px] font-medium rounded-xl">
                FAQS
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 block sm:hidden">
        {/*This of for Mobile screen*/}
        <Swiper
          modules={[Pagination, Autoplay]}
          pagination={{
            clickable: true,
            el: ".custom-pagination",
          }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          spaceBetween={30}
          slidesPerView="auto"
          loop={true}
          centeredSlides={true}
        >
          <SwiperSlide>
            <div className="bg-white rounded-2xl w-full sm:w-[380px]">
              <div>
                <img
                  src={blogimage}
                  alt="Blog"
                  className="w-full rounded-t-2xl"
                />
              </div>
              <div className="p-5 flex flex-col space-y-3">
                <div className="text-[#6699FF] text-[14px] font-medium">
                  Phoenix Baker • 19 Jan 2024
                </div>
                <div className="text-[16px] font-semibold">
                  MT5 Tips & Tricks
                </div>
                <div className="text-[#6C6C6C] text-[14px]">
                  Managing your money isn’t the easiest thing to do. Now that
                  many of us no longer balance a checkbook, tracking and
                  expenses.
                </div>
                <div className="flex flex-wrap gap-2">
                  <div className="text-[#65D31D] bg-[#F4FDEE] flex justify-center items-center text-[12px] w-[87px] h-[28px] font-medium rounded-xl">
                    Helpful Tips
                  </div>
                  <div className="text-[#26C6F9] bg-[#deeff4] flex justify-center items-center text-[12px] w-[87px] h-[28px] font-medium rounded-xl">
                    Helpful Tips
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-white rounded-2xl w-full sm:w-[380px]">
              <div>
                <img
                  src={blogimage}
                  alt="Blog"
                  className="w-full rounded-t-2xl"
                />
              </div>
              <div className="p-5 flex flex-col space-y-3">
                <div className="text-[#6699FF] text-[14px] font-medium">
                  Phoenix Baker • 19 Jan 2024
                </div>
                <div className="text-[16px] font-semibold">
                  MT5 Tips & Tricks
                </div>
                <div className="text-[#6C6C6C] text-[14px]">
                  Managing your money isn’t the easiest thing to do. Now that
                  many of us no longer balance a checkbook, tracking and
                  expenses.
                </div>
                <div className="flex flex-wrap gap-2">
                  <div className="text-[#65D31D] bg-[#F4FDEE] flex justify-center items-center text-[12px] w-[87px] h-[28px] font-medium rounded-xl">
                    Helpful Tips
                  </div>
                  <div className="text-[#26C6F9] bg-[#deeff4] flex justify-center items-center text-[12px] w-[87px] h-[28px] font-medium rounded-xl">
                    Helpful Tips
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="bg-white rounded-2xl w-full sm:w-[380px]">
              <div>
                <img
                  src={blogimage}
                  alt="Blog"
                  className="w-full rounded-t-2xl"
                />
              </div>
              <div className="p-5 flex flex-col space-y-3">
                <div className="text-[#6699FF] text-[14px] font-medium">
                  Phoenix Baker • 19 Jan 2024
                </div>
                <div className="text-[16px] font-semibold">
                  MT5 Tips & Tricks
                </div>
                <div className="text-[#6C6C6C] text-[14px]">
                  Managing your money isn’t the easiest thing to do. Now that
                  many of us no longer balance a checkbook, tracking and
                  expenses.
                </div>
                <div className="flex flex-wrap gap-2">
                  <div className="text-[#65D31D] bg-[#F4FDEE] flex justify-center items-center text-[12px] w-[87px] h-[28px] font-medium rounded-xl">
                    Helpful Tips
                  </div>
                  <div className="text-[#26C6F9] bg-[#deeff4] flex justify-center items-center text-[12px] w-[87px] h-[28px] font-medium rounded-xl">
                    Helpful Tips
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        {/* Custom Pagination Dots */}
        <div className="custom-pagination flex justify-center mt-6"></div>
      </div>
    </>
  );
};

export default HomeBlogs;
