import React from "react";
import { RotateLeft } from "../../../Assets/Education/Svgeducation";
import images from "../../../Assets/Assets";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

const BlueProgressBar = ({ progress }) => {
  return (
    <div className="w-full  bg-[#EDF4FF] dark:bg-[#011845] rounded-full h-2 mt-2">
      <div
        className="bg-[#6699FF] h-2 rounded-l-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};
const TradingCourses = () => {
  return (
    <div>
      <div className="lg:text-[34px] text-[24px] font-semibold text-center">
        Course Library
      </div>
      <div className="text-[#6C6C6C] lg:text-[16px] text-[14px] text-center">
        Explore a comprehensive collection of comprehensive trading courses.
      </div>
      {/*This is for large screen*/}
      <div className="mt-10 lg:flex  lg:flex-wrap lg:gap-5 lg:justify-center hidden sm:flex">
        <div className="w-[370px] rounded-2xl bg-background border-childBorder border ">
          <div className="relative">
            <div className="absolute top-2 left-2 bg-[#F4FDEE] dark:bg-[#00270B] font-semibold flex justify-center items-center text-[#65D31D] border border-[#65D31D] w-[171px] h-[32px] rounded-2xl">
              Beginner Friendly
            </div>

            <img src={images.beginnerFriendly} alt="" className="rounded-2xl" />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">5.0</span>
                <span className="text-[#6C6C6C]">Rating</span>
              </div>
              <div className="flex flex-col text-right">
                <span className="font-semibold">59</span>
                <span className="text-[#6C6C6C]">Reviews</span>
              </div>
            </div>

            <div className="text-[16px] font-semibold">
              Trading Fundamentals for Beginners
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Here we will be covering the basics of market analysis, risk
              management, and order execution, ideal for those new to trading.
            </div>
            <hr className="border border-childBorder" />
            <div>
              <BlueProgressBar progress={14} />
              <div className="flex justify-between">
                <div className="text-[#6C6C6C]">20 min</div>
                <div className="font-semibold">0%</div>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="border border-childBorder dark:bg-[#1C1C1C] bg-[#FAFCFD] w-[44px] h-[44px] rounded-lg flex justify-center items-center">
                <RotateLeft />
              </div>
              <Link to={"/education/trading-courses/course-video"}>
                <div>
                  <button className="bg-[#6699FF] font-semibold text-white rounded-lg w-[264px] h-[44px]">
                    Continue
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
          <div className="relative">
            <div className="absolute top-2 left-2 dark:bg-[#2D2600] bg-[#fbefd6] font-semibold flex justify-center items-center text-[#FDB528] border border-[#FDB528] w-[171px] h-[32px] rounded-2xl">
              Technical Analysis
            </div>

            <img
              src={images.technicalAnalysis}
              alt=""
              className="rounded-2xl"
            />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">4.8</span>
                <span className="text-[#6C6C6C]">Rating</span>
              </div>
              <div className="flex flex-col">
                <span className="font-semibold text-right">410</span>
                <span className="text-[#6C6C6C]">Reviews</span>
              </div>
            </div>

            <div className="text-[16px] font-semibold">
              Mastering Technical Analysis
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Explore the intricacies of technical analysis, learning how to
              interpret charts, identify trends, and make informed trading
              decisions.
            </div>
            <hr className="border border-childBorder" />
            <div>
              <BlueProgressBar progress={14} />
              <div className="flex justify-between">
                <div className="text-[#6C6C6C]">20 min</div>
                <div className="font-semibold">0%</div>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="border border-childBorder dark:bg-[#1C1C1C] bg-[#FAFCFD] w-[44px] h-[44px] rounded-lg flex justify-center items-center">
                <RotateLeft />
              </div>
              <div>
                <button className="bg-[#6699FF] font-semibold text-white rounded-lg w-[264px] h-[44px]">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
          <div className="relative">
            <div className="absolute top-2 left-2 dark:bg-[#18283C] bg-[#daeff6] font-semibold flex justify-center items-center text-[#26C6F9] border border-[#26C6F9] w-[171px] h-[32px] rounded-2xl">
              Advanced Training
            </div>

            <img src={images.advancedTraining} alt="" className="rounded-2xl" />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">4.4</span>
                <span className="text-[#6C6C6C]">Rating</span>
              </div>
              <div className="flex flex-col text-right">
                <span className="font-semibold">378</span>
                <span className="text-[#6C6C6C]">Reviews</span>
              </div>
            </div>

            <div className="text-[16px] font-semibold">
              Advanced Risk Management
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Learn advanced risk management techniques, understanding how to
              protect your capital and optimize your trading.
            </div>
            <hr className="border border-childBorder" />
            <div>
              <BlueProgressBar progress={14} />
              <div className="flex justify-between">
                <div className="text-[#6C6C6C]">20 min</div>
                <div className="font-semibold">0%</div>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="border border-childBorder dark:bg-[#1C1C1C] bg-[#FAFCFD] w-[44px] h-[44px] rounded-lg flex justify-center items-center">
                <RotateLeft />
              </div>
              <div>
                <button className="bg-[#6699FF] font-semibold text-white rounded-lg w-[264px] h-[44px]">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
          <div className="relative">
            <div className="absolute top-2 left-2 dark:bg-[#220000] bg-[#FFEAE9] font-semibold flex justify-center items-center text-[#FF4D49] border border-[#FF4D49] w-[171px] h-[32px] rounded-2xl">
              Algorithmic Trading
            </div>

            <img
              src={images.algorithmicTrading}
              alt=""
              className="rounded-2xl"
            />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">3.8</span>
                <span className="text-[#6C6C6C]">Rating</span>
              </div>
              <div className="flex flex-col text-right">
                <span className="font-semibold">531</span>
                <span className="text-[#6C6C6C]">Reviews</span>
              </div>
            </div>

            <div className="text-[16px] font-semibold">
              Algorithmic Trading and Automation
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Discover the power of algorithmic trading, exploring how to
              design, backtest, and deploy automated trading strategies.
            </div>
            <hr className="border border-childBorder" />
            <div>
              <BlueProgressBar progress={14} />
              <div className="flex justify-between">
                <div className="text-[#6C6C6C]">20 min</div>
                <div className="font-semibold">0%</div>
              </div>
            </div>

            <div>
              <button className="text-[#6699FF] font-semibold dark:bg-[#1C1C1C] border border-childBorder bg-[#FAFCFD] flex justify-center items-center gap-x-2  rounded-lg w-full h-[44px]">
                Start Over <RotateLeft />
              </button>
            </div>
          </div>
        </div>
        <div className="w-[370px] rounded-2xl bg-background border-childBorder border ">
          <div className="relative">
            <div className="absolute top-2 left-2 dark:bg-[#00270B] bg-[#F4FDEE] font-semibold flex justify-center items-center text-[#65D31D] border border-[#65D31D] w-[171px] h-[32px] rounded-2xl">
              Beginner Friendly
            </div>

            <img src={images.cryptoTrading} alt="" className="rounded-2xl" />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">4.5</span>
                <span className="text-[#6C6C6C]">Rating</span>
              </div>
              <div className="flex flex-col text-right">
                <span className="font-semibold">225</span>
                <span className="text-[#6C6C6C]">Reviews</span>
              </div>
            </div>

            <div className="text-[16px] font-semibold">
              Crypto Trading for Beginners
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Jump into the world of crypto, starting from the ground up with an
              emphasis on digital asset markets and strategies.
            </div>
            <hr className="border border-childBorder" />
            <div>
              <BlueProgressBar progress={14} />
              <div className="flex justify-between">
                <div className="text-[#6C6C6C]">20 min</div>
                <div className="font-semibold">0%</div>
              </div>
            </div>
            <div>
              <button className="text-[#6699FF] font-semibold dark:bg-[#1C1C1C] border border-childBorder bg-[#FAFCFD] flex justify-center items-center gap-x-2  rounded-lg w-full h-[44px]">
                Start Over <RotateLeft />
              </button>
            </div>
          </div>
        </div>
        <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
          <div className="relative">
            <div className="absolute top-2 left-2 dark:bg-[#18283C] bg-[#daeff6] font-semibold flex justify-center items-center text-[#26C6F9] border border-[#26C6F9] w-[171px] h-[32px] rounded-2xl">
              Advanced Training
            </div>

            <img
              src={images.globalMacroTrading}
              alt=""
              className="rounded-2xl"
            />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">4.2</span>
                <span className="text-[#6C6C6C]">Rating</span>
              </div>
              <div className="flex flex-col text-right">
                <span className="font-semibold">378</span>
                <span className="text-[#6C6C6C]">Reviews</span>
              </div>
            </div>

            <div className="text-[16px] font-semibold">
              Global Macro Trading Strategies
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Learn how to navigate the financial markets by analyzing
              macroeconomic trends and geopolitical events.
            </div>
            <hr className="border border-childBorder" />
            <div>
              <BlueProgressBar progress={14} />
              <div className="flex justify-between">
                <div className="text-[#6C6C6C]">20 min</div>
                <div className="font-semibold">0%</div>
              </div>
            </div>
            <div className="">
              <div>
                <button className="bg-[#6699FF] font-semibold text-white rounded-lg w-full h-[44px]">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*This is for Mobile screen*/}
      <div className="mt-3 block sm:hidden">
        <Swiper
          modules={[Pagination]}
          pagination={{
            clickable: true,
            el: ".custom-pagination-2",
          }}
          spaceBetween={30}
          loop={true}
          slidesPerView={1}
          centeredSlides={true}
        >
          <SwiperSlide>
            <div className="w-[370px] rounded-2xl bg-background border-childBorder border ">
              <div className="relative">
                <div className="absolute top-2 left-2 bg-[#F4FDEE] dark:bg-[#00270B] font-semibold flex justify-center items-center text-[#65D31D] border border-[#65D31D] w-[171px] h-[32px] rounded-2xl">
                  Beginner Friendly
                </div>

                <img
                  src={images.beginnerFriendly}
                  alt=""
                  className="rounded-2xl"
                />
              </div>
              <div className=" p-5 flex flex-col gap-y-2 ">
                <div className="flex justify-between">
                  <div className="flex flex-col">
                    <span className="font-semibold">5.0</span>
                    <span className="text-[#6C6C6C]">Rating</span>
                  </div>
                  <div className="flex flex-col text-right">
                    <span className="font-semibold">59</span>
                    <span className="text-[#6C6C6C]">Reviews</span>
                  </div>
                </div>

                <div className="text-[16px] font-semibold">
                  Trading Fundamentals for Beginners
                </div>
                <div className="text-[#6C6C6C] text-[14px]">
                  Here we will be covering the basics of market analysis, risk
                  management, and order execution, ideal for those new to
                  trading.
                </div>
                <hr className="border border-childBorder" />
                <div>
                  <BlueProgressBar progress={14} />
                  <div className="flex justify-between">
                    <div className="text-[#6C6C6C]">20 min</div>
                    <div className="font-semibold">0%</div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="border border-childBorder dark:bg-[#1C1C1C] bg-[#FAFCFD] w-[44px] h-[44px] rounded-lg flex justify-center items-center">
                    <RotateLeft />
                  </div>
                  <Link to={"/education/trading-courses/course-video"}>
                    <div>
                      <button className="bg-[#6699FF] font-semibold text-white rounded-lg w-[264px] h-[44px]">
                        Continue
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
              <div className="relative">
                <div className="absolute top-2 left-2 dark:bg-[#2D2600] bg-[#fbefd6] font-semibold flex justify-center items-center text-[#FDB528] border border-[#FDB528] w-[171px] h-[32px] rounded-2xl">
                  Technical Analysis
                </div>

                <img
                  src={images.technicalAnalysis}
                  alt=""
                  className="rounded-2xl"
                />
              </div>
              <div className=" p-5 flex flex-col gap-y-2 ">
                <div className="flex justify-between">
                  <div className="flex flex-col">
                    <span className="font-semibold">4.8</span>
                    <span className="text-[#6C6C6C]">Rating</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-semibold text-right">410</span>
                    <span className="text-[#6C6C6C]">Reviews</span>
                  </div>
                </div>

                <div className="text-[16px] font-semibold">
                  Mastering Technical Analysis
                </div>
                <div className="text-[#6C6C6C] text-[14px]">
                  Explore the intricacies of technical analysis, learning how to
                  interpret charts, identify trends, and make informed trading
                  decisions.
                </div>
                <hr className="border border-childBorder" />
                <div>
                  <BlueProgressBar progress={14} />
                  <div className="flex justify-between">
                    <div className="text-[#6C6C6C]">20 min</div>
                    <div className="font-semibold">0%</div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="border border-childBorder dark:bg-[#1C1C1C] bg-[#FAFCFD] w-[44px] h-[44px] rounded-lg flex justify-center items-center">
                    <RotateLeft />
                  </div>
                  <div>
                    <button className="bg-[#6699FF] font-semibold text-white rounded-lg w-[264px] h-[44px]">
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
              <div className="relative">
                <div className="absolute top-2 left-2 dark:bg-[#18283C] bg-[#daeff6] font-semibold flex justify-center items-center text-[#26C6F9] border border-[#26C6F9] w-[171px] h-[32px] rounded-2xl">
                  Advanced Training
                </div>

                <img
                  src={images.advancedTraining}
                  alt=""
                  className="rounded-2xl"
                />
              </div>
              <div className=" p-5 flex flex-col gap-y-2 ">
                <div className="flex justify-between">
                  <div className="flex flex-col">
                    <span className="font-semibold">4.4</span>
                    <span className="text-[#6C6C6C]">Rating</span>
                  </div>
                  <div className="flex flex-col text-right">
                    <span className="font-semibold">378</span>
                    <span className="text-[#6C6C6C]">Reviews</span>
                  </div>
                </div>

                <div className="text-[16px] font-semibold">
                  Advanced Risk Management
                </div>
                <div className="text-[#6C6C6C] text-[14px]">
                  Learn advanced risk management techniques, understanding how
                  to protect your capital and optimize your trading.
                </div>
                <hr className="border border-childBorder" />
                <div>
                  <BlueProgressBar progress={14} />
                  <div className="flex justify-between">
                    <div className="text-[#6C6C6C]">20 min</div>
                    <div className="font-semibold">0%</div>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className="border border-childBorder dark:bg-[#1C1C1C] bg-[#FAFCFD] w-[44px] h-[44px] rounded-lg flex justify-center items-center">
                    <RotateLeft />
                  </div>
                  <div>
                    <button className="bg-[#6699FF] font-semibold text-white rounded-lg w-[264px] h-[44px]">
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* Custom Pagination Dots */}
        <div className="custom-pagination-2 flex justify-center mt-6"></div>

        <div>
          <div className="font-semibold my-5">Finished Courses</div>
          <Swiper
            modules={[Pagination]}
            pagination={{
              clickable: true,
              el: ".custom-pagination-5",
            }}
            spaceBetween={30}
            loop={true}
            slidesPerView={1}
            centeredSlides={true}
          >
            <SwiperSlide>
            <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
          <div className="relative">
            <div className="absolute top-2 left-2 dark:bg-[#220000] bg-[#FFEAE9] font-semibold flex justify-center items-center text-[#FF4D49] border border-[#FF4D49] w-[171px] h-[32px] rounded-2xl">
              Algorithmic Trading
            </div>

            <img
              src={images.algorithmicTrading}
              alt=""
              className="rounded-2xl"
            />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">3.8</span>
                <span className="text-[#6C6C6C]">Rating</span>
              </div>
              <div className="flex flex-col text-right">
                <span className="font-semibold">531</span>
                <span className="text-[#6C6C6C]">Reviews</span>
              </div>
            </div>

            <div className="text-[16px] font-semibold">
              Algorithmic Trading and Automation
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Discover the power of algorithmic trading, exploring how to
              design, backtest, and deploy automated trading strategies.
            </div>
            <hr className="border border-childBorder" />
            <div>
              <BlueProgressBar progress={14} />
              <div className="flex justify-between">
                <div className="text-[#6C6C6C]">20 min</div>
                <div className="font-semibold">0%</div>
              </div>
            </div>

            <div>
              <button className="text-[#6699FF] font-semibold dark:bg-[#1C1C1C] border border-childBorder bg-[#FAFCFD] flex justify-center items-center gap-x-2  rounded-lg w-full h-[44px]">
                Start Over <RotateLeft />
              </button>
            </div>
          </div>
        </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="w-[370px] rounded-2xl bg-background border-childBorder border ">
          <div className="relative">
            <div className="absolute top-2 left-2 dark:bg-[#00270B] bg-[#F4FDEE] font-semibold flex justify-center items-center text-[#65D31D] border border-[#65D31D] w-[171px] h-[32px] rounded-2xl">
              Beginner Friendly
            </div>

            <img src={images.cryptoTrading} alt="" className="rounded-2xl" />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">4.5</span>
                <span className="text-[#6C6C6C]">Rating</span>
              </div>
              <div className="flex flex-col text-right">
                <span className="font-semibold">225</span>
                <span className="text-[#6C6C6C]">Reviews</span>
              </div>
            </div>

            <div className="text-[16px] font-semibold">
              Crypto Trading for Beginners
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Jump into the world of crypto, starting from the ground up with an
              emphasis on digital asset markets and strategies.
            </div>
            <hr className="border border-childBorder" />
            <div>
              <BlueProgressBar progress={14} />
              <div className="flex justify-between">
                <div className="text-[#6C6C6C]">20 min</div>
                <div className="font-semibold">0%</div>
              </div>
            </div>
            <div>
              <button className="text-[#6699FF] font-semibold dark:bg-[#1C1C1C] border border-childBorder bg-[#FAFCFD] flex justify-center items-center gap-x-2  rounded-lg w-full h-[44px]">
                Start Over <RotateLeft />
              </button>
            </div>
          </div>
        </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
          <div className="relative">
            <div className="absolute top-2 left-2 dark:bg-[#18283C] bg-[#daeff6] font-semibold flex justify-center items-center text-[#26C6F9] border border-[#26C6F9] w-[171px] h-[32px] rounded-2xl">
              Advanced Training
            </div>

            <img
              src={images.globalMacroTrading}
              alt=""
              className="rounded-2xl"
            />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <span className="font-semibold">4.2</span>
                <span className="text-[#6C6C6C]">Rating</span>
              </div>
              <div className="flex flex-col text-right">
                <span className="font-semibold">378</span>
                <span className="text-[#6C6C6C]">Reviews</span>
              </div>
            </div>

            <div className="text-[16px] font-semibold">
              Global Macro Trading Strategies
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Learn how to navigate the financial markets by analyzing
              macroeconomic trends and geopolitical events.
            </div>
            <hr className="border border-childBorder" />
            <div>
              <BlueProgressBar progress={14} />
              <div className="flex justify-between">
                <div className="text-[#6C6C6C]">20 min</div>
                <div className="font-semibold">0%</div>
              </div>
            </div>
            <div className="">
              <div>
                <button className="bg-[#6699FF] font-semibold text-white rounded-lg w-full h-[44px]">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
            </SwiperSlide>
          </Swiper>
          {/* Custom Pagination Dots */}
          <div className="custom-pagination-5 flex justify-center mt-6"></div>
        </div>
      </div>
    </div>
  );
};

export default TradingCourses;
