import React from "react";
import images from "../../../Assets/Assets";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

const PropmodelTutorials = () => {
  return (
    <div>
      <div className="lg:text-[34px] text-[24px] font-semibold text-center">
        Video Archive
      </div>
      <div className="text-[#6C6C6C] lg:text-[16px] text-[14px] text-center">
        Explore instructional videos on navigating the Propmodel platform.
      </div>
      {/*This is for large screen*/}
      <div className="mt-10 lg:flex  lg:flex-wrap lg:gap-5 lg:justify-center  hidden sm:flex">
        <div className="w-[370px] rounded-2xl bg-background border-childBorder border ">
          <div className="">
            <img src={images.beginnerFriendly} alt="" className="rounded-2xl" />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="text-[16px] font-semibold">
              Starting A Challenge
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Take a deep dive into the Propmodel platform and discover how to
              initiate your very first trading challenge.
            </div>

            <div className="">
              <Link to={"/education/propmodel-tutorials/learn-more"}>
                <div>
                  <button className="bg-[#FAFCFD] dark:bg-[#1C1C1C]  border border-childBorder font-semibold text-[#6699FF] rounded-lg w-full h-[44px]">
                    Learn More
                  </button>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
          <div className="">
            <img
              src={images.technicalAnalysis}
              alt=""
              className="rounded-2xl"
            />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="text-[16px] font-semibold">
              The Trading Dashboard
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Uncover the core of Propmodel’s trading platform, where you'll
              gain valuable insights into your trading history and performance.
            </div>

            <div className="">
              <div>
                <button className="bg-[#FAFCFD] dark:bg-[#1C1C1C]  border border-childBorder font-semibold text-[#6699FF] rounded-lg w-full h-[44px]">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
          <div className="">
            <img src={images.advancedTraining} alt="" className="rounded-2xl" />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="text-[16px] font-semibold">
              The Affiliate Dashboard
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Explore the potential of Propmodel’s affiliate program, where
              you'll learn how to maximize your earnings by referring others to
              Nostro.
            </div>

            <div className="">
              <div>
                <button className="bg-[#FAFCFD]  dark:bg-[#1C1C1C]  border border-childBorder font-semibold text-[#6699FF] rounded-lg w-full h-[44px]">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
          <div
            className="
          "
          >
            <img
              src={images.algorithmicTrading}
              alt=""
              className="rounded-2xl"
            />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="text-[16px] font-semibold">
              Starting A Challenge
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Discover how to initiate trading competitions on Propmodel, giving
              you the opportunity to test your skills against fellow traders.
            </div>

            <div className="">
              <div>
                <button className="bg-[#FAFCFD]  dark:bg-[#1C1C1C]  border border-childBorder font-semibold text-[#6699FF] rounded-lg w-full h-[44px]">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
          <div className="">
            <img src={images.cryptoTrading} alt="" className="rounded-2xl" />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="text-[16px] font-semibold">The Leaderboard</div>
            <div className="text-[#6C6C6C] text-[14px]">
              Learn how to track your progress, measure your success, and see
              where you stand in Propmodel’s rankings.
            </div>

            <div className="">
              <div>
                <button className="bg-[#FAFCFD] dark:bg-[#1C1C1C]  border border-childBorder font-semibold text-[#6699FF] rounded-lg w-full h-[44px]">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
          <div className="">
            <img
              src={images.globalMacroTrading}
              alt=""
              className="rounded-2xl"
            />
          </div>
          <div className=" p-5 flex flex-col gap-y-2 ">
            <div className="text-[16px] font-semibold">
              Using The Trading Tools
            </div>
            <div className="text-[#6C6C6C] text-[14px]">
              Unlock the full potential of Propmodel’s trading tools, gaining
              proficiency in technical analysis and risk management.
            </div>

            <div className="">
              <div>
                <button className="bg-[#FAFCFD]  dark:bg-[#1C1C1C]  border border-childBorder font-semibold text-[#6699FF] rounded-lg w-full h-[44px]">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*This is for Mobile screen*/}
      <div className="mt-3 block sm:hidden">
        <Swiper
          modules={[Pagination]}
          pagination={{
            clickable: true,
            el: ".custom-pagination",
          }}
          spaceBetween={30}
          loop={true}
          slidesPerView={1}
          centeredSlides={true}
        >
          <SwiperSlide>
            <div className="w-[370px] rounded-2xl bg-background border-childBorder border ">
              <div className="">
                <img
                  src={images.beginnerFriendly}
                  alt=""
                  className="rounded-2xl"
                />
              </div>
              <div className=" p-5 flex flex-col gap-y-2 ">
                <div className="text-[16px] font-semibold">
                  Starting A Challenge
                </div>
                <div className="text-[#6C6C6C] text-[14px]">
                  Take a deep dive into the Propmodel platform and discover how
                  to initiate your very first trading challenge.
                </div>

                <div className="">
                  <Link to={"/education/propmodel-tutorials/learn-more"}>
                    <div>
                      <button className="bg-[#FAFCFD] dark:bg-[#1C1C1C]  border border-childBorder font-semibold text-[#6699FF] rounded-lg w-full h-[44px]">
                        Learn More
                      </button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
              <div className="">
                <img
                  src={images.technicalAnalysis}
                  alt=""
                  className="rounded-2xl"
                />
              </div>
              <div className=" p-5 flex flex-col gap-y-2 ">
                <div className="text-[16px] font-semibold">
                  The Trading Dashboard
                </div>
                <div className="text-[#6C6C6C] text-[14px]">
                  Uncover the core of Propmodel’s trading platform, where you'll
                  gain valuable insights into your trading history and
                  performance.
                </div>

                <div className="">
                  <div>
                    <button className="bg-[#FAFCFD] dark:bg-[#1C1C1C]  border border-childBorder font-semibold text-[#6699FF] rounded-lg w-full h-[44px]">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
              <div className="">
                <img
                  src={images.advancedTraining}
                  alt=""
                  className="rounded-2xl"
                />
              </div>
              <div className=" p-5 flex flex-col gap-y-2 ">
                <div className="text-[16px] font-semibold">
                  The Affiliate Dashboard
                </div>
                <div className="text-[#6C6C6C] text-[14px]">
                  Explore the potential of Propmodel’s affiliate program, where
                  you'll learn how to maximize your earnings by referring others
                  to Nostro.
                </div>

                <div className="">
                  <div>
                    <button className="bg-[#FAFCFD]  dark:bg-[#1C1C1C]  border border-childBorder font-semibold text-[#6699FF] rounded-lg w-full h-[44px]">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* Custom Pagination Dots */}
        <div className="custom-pagination flex justify-center mt-6"></div>

        <div>
          <div className="font-semibold my-5">Another Video</div>
          <Swiper
            modules={[Pagination]}
            pagination={{
              clickable: true,
              el: ".custom-pagination-1",
            }}
            spaceBetween={30}
            loop={true}
            slidesPerView={1}
            centeredSlides={true}
          >
            <SwiperSlide>
              <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
                <div
                  className="
          "
                >
                  <img
                    src={images.algorithmicTrading}
                    alt=""
                    className="rounded-2xl"
                  />
                </div>
                <div className=" p-5 flex flex-col gap-y-2 ">
                  <div className="text-[16px] font-semibold">
                    Starting A Challenge
                  </div>
                  <div className="text-[#6C6C6C] text-[14px]">
                    Discover how to initiate trading competitions on Propmodel,
                    giving you the opportunity to test your skills against
                    fellow traders.
                  </div>

                  <div className="">
                    <div>
                      <button className="bg-[#FAFCFD]  dark:bg-[#1C1C1C]  border border-childBorder font-semibold text-[#6699FF] rounded-lg w-full h-[44px]">
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
                <div className="">
                  <img
                    src={images.cryptoTrading}
                    alt=""
                    className="rounded-2xl"
                  />
                </div>
                <div className=" p-5 flex flex-col gap-y-2 ">
                  <div className="text-[16px] font-semibold">
                    The Leaderboard
                  </div>
                  <div className="text-[#6C6C6C] text-[14px]">
                    Learn how to track your progress, measure your success, and
                    see where you stand in Propmodel’s rankings.
                  </div>

                  <div className="">
                    <div>
                      <button className="bg-[#FAFCFD] dark:bg-[#1C1C1C]  border border-childBorder font-semibold text-[#6699FF] rounded-lg w-full h-[44px]">
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-[370px] rounded-2xl bg-background border-childBorder border  ">
                <div className="">
                  <img
                    src={images.globalMacroTrading}
                    alt=""
                    className="rounded-2xl"
                  />
                </div>
                <div className=" p-5 flex flex-col gap-y-2 ">
                  <div className="text-[16px] font-semibold">
                    Using The Trading Tools
                  </div>
                  <div className="text-[#6C6C6C] text-[14px]">
                    Unlock the full potential of Propmodel’s trading tools,
                    gaining proficiency in technical analysis and risk
                    management.
                  </div>

                  <div className="">
                    <div>
                      <button className="bg-[#FAFCFD]  dark:bg-[#1C1C1C]  border border-childBorder font-semibold text-[#6699FF] rounded-lg w-full h-[44px]">
                        Learn More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          {/* Custom Pagination Dots */}
          <div className="custom-pagination-1 flex justify-center mt-6"></div>
        </div>
      </div>
    </div>
  );
};

export default PropmodelTutorials;
