import React, { useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip
);

const GradientAreaChart = () => {
  const chartRef = useRef(null);
  const data = {
    labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], // X-axis labels
    datasets: [
      {
        label: "Values",
        data: [50, 60, 55, 70, 65, 80, 90], // Y-axis data
        borderColor: "#67CA24", // Line color
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, "rgba(76, 175, 80, 0.4)"); // Top gradient color
          gradient.addColorStop(1, "rgba(76, 175, 80, 0)"); // Bottom gradient color
          return gradient;
        },
        fill: true,

        borderWidth: 2,
        pointRadius: 2, // Point size
        pointHoverRadius: 8, // Hover radius
        pointHoverBackgroundColor: "#ffffff",
        pointHoverBorderColor: "#67CA24",
        pointHoverBorderWidth: 2,
        tension: 0.4, // Smooth curves
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        callbacks: {
          label: (context) => `Value: ${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          display: false, // Hide X-axis labels
        },
        grid: {
          display: false, // Remove X-axis grid lines
        },
        border: {
          display: false, // Hide X-axis line
        },
      },
      y: {
        ticks: {
          display: false, // Hide Y-axis labels
        },
        grid: {
          display: false,
        },
        border: {
          display: false, // Hide Y-axis line
        },
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "132px" }}>
      <Line ref={chartRef} data={data} options={options} />
    </div>
  );
};

export default GradientAreaChart;
