import { ArrowLeft } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ThumbsUp, ThumbsDown } from "lucide-react";
import images from "../../../Assets/Assets";

const PropModelLearnMore = () => {
  const navigate = useNavigate();
  return (
    <div className="">
      <div className="bg-background border-childBorder border  rounded-2xl shadow-lg ">
        <div className="p-5">
          <button
            className=" font-semibold flex items-center gap-2"
            onClick={() => navigate(-1)}
          >
            <ArrowLeft size={20} /> Back
          </button>
        </div>

        <div className="relative rounded-2xl overflow-hidden mx-5">
          <img
            src={images.beginnerFriendly}
            alt="Trading Fundamentals"
            className="w-full h-auto object-cover"
          />
          <div className="absolute inset-0 flex justify-center items-center">
            <button className="w-16 h-16 bg-white rounded-full flex justify-center items-center shadow-lg">
              <div className="w-10 h-10 bg-[#6699FF] flex justify-center items-center rounded-full">
                <span className="text-white text-2xl font-bold">▶</span>
              </div>
            </button>
          </div>
        </div>

        <div className="p-5">
          <h2 className="lg:text-xl text-[16px] font-semibold">
            Starting A Challenge
          </h2>
          <div className="text-[#6C6C6C] text-sm mt-2">
            55 People found this helpful
          </div>

          <hr className="mt-5 border-childBorder" />

          <div className="flex  lg:flex-row lg:gap-0 gap-5 flex-col mt-5">
            <div className="lg:flex gap-4">
              <div className="flex items-center gap-2">
                <div>
                  <p className=" font-semibold lg:text-[16px] text-[14px]">
                    Did you find this video helpful?
                  </p>
                  <div className="text-[#6C6C6C] text-sm">
                    55 People found this helpful
                  </div>
                </div>
                <div className="flex gap-3">
                  <button className="w-8 h-8 rounded-full bg-[#6699FF] flex justify-center items-center">
                    <ThumbsUp size={16} color="#FFFFFF" />
                  </button>
                  <button className="w-8 h-8 rounded-full bg-[#6699FF] flex justify-center items-center">
                    <ThumbsDown size={16} color="#FFFFFF" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropModelLearnMore;
