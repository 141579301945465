import { CircleX, Search, X } from "lucide-react";
import { WorldSearch } from "../../Assets/Tools/SvgTools";
import { useEffect, useState } from "react";

const CountryFilterModal = ({ isOpen, onClose }) => {
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  if (!isOpen) return null;

  const tags = [
    { country: "US", flag: "🇺🇸" },
    { country: "EU", flag: "🇪🇺" },
    { country: "IT", flag: "🇮🇹" },
    { country: "NZ", flag: "🇺🇸" },
    { country: "GE", flag: "🇩🇪" },
    { country: "AU", flag: "🇺🇸" },
    { country: "FR", flag: "🇫🇷" },
    { country: "JP", flag: "🇺🇸" },
    { country: "ZA", flag: "🇺🇸" },
    { country: "TR", flag: "🇹🇷" },
    { country: "DE", flag: "🇺🇸" },
    { country: "MX", flag: "🇲🇽" },
  ];
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex lg:justify-center lg:items-center justify-end items-end z-50">
      <div className="bg-white dark:bg-[#121212] lg:rounded-2xl rounded-t-2xl shadow-lg lg:w-[90%] max-w-[500px] p-5">
        <div className="flex justify-between items-center border-b border-childBorder pb-4">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <Search className="text-[#9E9FAB]" />
            </div>

            <input
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search"
              className="w-full p-2 pl-10 pr-10 dark:border-[#424346] border rounded-xl dark:bg-[#121212] focus:outline-none"
            />

            {searchText && (
              <div
                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                onClick={() => setSearchText("")}
              >
                <CircleX className="text-[#9E9FAB]" />
              </div>
            )}
          </div>
        </div>

        <div className="space-y-4 ">
          <div>
            <h3 className="text-[#2B2A2A] dark:text-white font-medium mb-2 mt-4">
              Select Countries
            </h3>
            <div className="flex flex-wrap gap-2 ">
              {tags.map((tag, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center gap-1 dark:bg-[#232325] bg-gray-100 rounded-lg w-[70px] h-[30px] shadow-sm"
                >
                  <span className="w-[20px] h-[20px] rounded-full">
                    {tag.flag}
                  </span>
                  <span className="text-sm font-medium">{tag.country}</span>
                  <button className="text-gray-400 hover:text-gray-600">
                    <X className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-[#2B2A2A] dark:text-white font-medium mb-2">
              Entire World
            </h3>

            <div className="relative w-full">
              <div className="absolute  inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <WorldSearch />
              </div>

              <input
                type="text"
                placeholder="All Countries"
                className="w-full p-2 pl-10 dark:bg-[#121212] dark:border-[#424346]  rounded-xl h-[52px] border border-[#D0D5DD]  focus:outline-none"
              />
            </div>
          </div>

          <div>
            <h3 className="text-[#2B2A2A] dark:text-white font-medium mb-2">
              North America
            </h3>
            <div className="flex gap-2">
              <button className="border dark:border-[#424346] border-[#D0D5DD] rounded-xl p-2 flex-1 text-[#6C6C6C]">
                🇺🇸 United States
              </button>
              <button className="border dark:border-[#424346] border-[#D0D5DD] rounded-xl p-2 flex-1 text-[#6C6C6C]">
                🇨🇦 Canada
              </button>
            </div>
          </div>

          <div className="">
            <h3 className="text-[#2B2A2A] dark:text-white font-medium mb-2">
              Europe
            </h3>
            <div className="grid grid-cols-2 gap-2">
              <button className="text-left w-[241px] h-[41px]">
                🇦🇹 Austria
              </button>
              <button className="text-left w-[241px] h-[41px]">
                🇱🇻 Latvia
              </button>
              <button className="text-left w-[241px] h-[41px]">
                🇧🇪 Belgium
              </button>
              <button className="text-left w-[241px] h-[41px]">
                🇱🇹 Lithuania
              </button>
            </div>
          </div>
        </div>

        <div className="flex lg:justify-between lg:flex-row flex-col border-childBorder lg:p-4 border-t mt-5">
          <button className="text-[#6C6C6C]  dark:border-[#252525] dark:bg-[#212121] bg-[#EAEAEC] text-[14px] font-semibold lg:w-[159px] h-[38px] lg:mt-0 my-2 rounded-lg">
            Reset to Default
          </button>
          <div className="flex justify-between">
            <button
              onClick={onClose}
              className="text-[#6699FF] dark:border-[#252525] dark:bg-[#1C1C1C] border border-[#FAFCFD] bg-[#EAEAEC] w-full lg:w-[94px] h-[38px] rounded-lg mr-4"
            >
              Cancel
            </button>
            <button className="bg-[#6699FF] text-white w-full lg:w-[85px] h-[38px] rounded-lg ">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryFilterModal;
