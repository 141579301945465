
export const AccountView = () => {
    return (
      <>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 3H6C3.79 3 2 4.78 2 6.97V17.03C2 19.22 3.79 21 6 21H18C20.21 21 22 19.22 22 17.03V6.97C22 4.78 20.21 3 18 3ZM8.5 7.17C9.77 7.17 10.81 8.21 10.81 9.48C10.81 10.75 9.77 11.79 8.5 11.79C7.23 11.79 6.19 10.75 6.19 9.48C6.19 8.21 7.23 7.17 8.5 7.17ZM12.37 16.66C12.28 16.76 12.14 16.82 12 16.82H5C4.86 16.82 4.72 16.76 4.63 16.66C4.54 16.56 4.49 16.42 4.5 16.28C4.67 14.6 6.01 13.27 7.69 13.11C8.22 13.06 8.77 13.06 9.3 13.11C10.98 13.27 12.33 14.6 12.49 16.28C12.51 16.42 12.46 16.56 12.37 16.66ZM19 16.75H17C16.59 16.75 16.25 16.41 16.25 16C16.25 15.59 16.59 15.25 17 15.25H19C19.41 15.25 19.75 15.59 19.75 16C19.75 16.41 19.41 16.75 19 16.75ZM19 12.75H15C14.59 12.75 14.25 12.41 14.25 12C14.25 11.59 14.59 11.25 15 11.25H19C19.41 11.25 19.75 11.59 19.75 12C19.75 12.41 19.41 12.75 19 12.75ZM19 8.75H14C13.59 8.75 13.25 8.41 13.25 8C13.25 7.59 13.59 7.25 14 7.25H19C19.41 7.25 19.75 7.59 19.75 8C19.75 8.41 19.41 8.75 19 8.75Z"
            fill="#6699FF"
          />
        </svg>
      </>
    );
  };
  
export const MedalStar = () => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.175 21.1169L22.36 21.5459C21.953 21.6449 21.634 21.9529 21.546 22.3599L21.161 23.9769C20.952 24.8569 19.83 25.1319 19.247 24.4389L15.958 20.6549C15.694 20.3469 15.837 19.8629 16.233 19.7639C18.18 19.2909 19.929 18.2019 21.216 16.6509C21.425 16.3979 21.799 16.3649 22.03 16.5959L24.472 19.0379C25.308 19.8739 25.011 20.9189 24.175 21.1169Z"
          fill="#A4A4A4"
        />
        <path
          d="M3.7695 21.1169L5.5845 21.5459C5.9915 21.6449 6.3105 21.9529 6.3985 22.3599L6.7835 23.9769C6.9925 24.8569 8.1145 25.1319 8.6975 24.4389L11.9865 20.6549C12.2505 20.3469 12.1075 19.8629 11.7115 19.7639C9.7645 19.2909 8.0155 18.2019 6.7285 16.6509C6.5195 16.3979 6.1455 16.3649 5.9145 16.5959L3.4725 19.0379C2.6365 19.8739 2.9335 20.9189 3.7695 21.1169Z"
          fill="#A4A4A4"
        />
        <path
          d="M13.9998 3C9.7428 3 6.2998 6.443 6.2998 10.7C6.2998 12.295 6.7728 13.758 7.5868 14.979C8.7748 16.739 10.6558 17.982 12.8448 18.301C13.2188 18.367 13.6038 18.4 13.9998 18.4C14.3958 18.4 14.7808 18.367 15.1548 18.301C17.3438 17.982 19.2248 16.739 20.4128 14.979C21.2268 13.758 21.6998 12.295 21.6998 10.7C21.6998 6.443 18.2568 3 13.9998 3ZM17.3658 10.458L16.4528 11.371C16.2988 11.525 16.2108 11.822 16.2658 12.042L16.5298 13.175C16.7388 14.066 16.2658 14.418 15.4738 13.945L14.3738 13.296C14.1758 13.175 13.8458 13.175 13.6478 13.296L12.5478 13.945C11.7558 14.407 11.2828 14.066 11.4918 13.175L11.7558 12.042C11.7998 11.833 11.7228 11.525 11.5688 11.371L10.6338 10.458C10.0948 9.919 10.2708 9.38 11.0188 9.259L12.1958 9.061C12.3938 9.028 12.6248 8.852 12.7128 8.676L13.3618 7.378C13.7138 6.674 14.2858 6.674 14.6378 7.378L15.2868 8.676C15.3748 8.852 15.6058 9.028 15.8148 9.061L16.9918 9.259C17.7288 9.38 17.9048 9.919 17.3658 10.458Z"
          fill="#A4A4A4"
        />
      </svg>
    </>
  );
};
