export const DollarSvg = () => {
  return (
    <>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.366667"
          y="0.366667"
          width="43.2667"
          height="43.2667"
          rx="21.6333"
          fill="#EDF4FF"
        />
        <rect
          x="0.366667"
          y="0.366667"
          width="43.2667"
          height="43.2667"
          rx="21.6333"
          stroke="#EAEAEC"
          stroke-width="0.733333"
        />
        <path
          d="M22.8252 26.3108H23.5402C24.2552 26.3108 24.8492 25.6728 24.8492 24.9028C24.8492 23.9458 24.5082 23.7588 23.9472 23.5608L22.8362 23.1758V26.3108H22.8252Z"
          fill="#6699FF"
        />
        <path
          d="M21.9673 10.8887C15.8953 10.9107 10.9783 15.8497 11.0003 21.9217C11.0223 27.9937 15.9613 32.9107 22.0333 32.8887C28.1053 32.8667 33.0223 27.9277 33.0003 21.8557C32.9783 15.7837 28.0393 10.8777 21.9673 10.8887ZM24.4863 21.9987C25.3443 22.2957 26.4993 22.9337 26.4993 24.9027C26.4993 26.5967 25.1683 27.9607 23.5403 27.9607H22.8253V28.5987C22.8253 29.0497 22.4513 29.4237 22.0003 29.4237C21.5493 29.4237 21.1753 29.0497 21.1753 28.5987V27.9607H20.7793C18.9753 27.9607 17.5123 26.4427 17.5123 24.5727C17.5123 24.1217 17.8863 23.7477 18.3373 23.7477C18.7883 23.7477 19.1623 24.1217 19.1623 24.5727C19.1623 25.5297 19.8883 26.3107 20.7793 26.3107H21.1753V22.5927L19.5143 21.9987C18.6563 21.7017 17.5013 21.0637 17.5013 19.0947C17.5013 17.4007 18.8323 16.0367 20.4603 16.0367H21.1753V15.3987C21.1753 14.9477 21.5493 14.5737 22.0003 14.5737C22.4513 14.5737 22.8253 14.9477 22.8253 15.3987V16.0367H23.2213C25.0253 16.0367 26.4883 17.5547 26.4883 19.4247C26.4883 19.8757 26.1143 20.2497 25.6633 20.2497C25.2123 20.2497 24.8383 19.8757 24.8383 19.4247C24.8383 18.4677 24.1123 17.6867 23.2213 17.6867H22.8253V21.4047L24.4863 21.9987Z"
          fill="#6699FF"
        />
        <path
          d="M19.1621 19.1055C19.1621 20.0625 19.5031 20.2495 20.0641 20.4475L21.1751 20.8325V17.6865H20.4601C19.7451 17.6865 19.1621 18.3245 19.1621 19.1055Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};
export const UnpaidEarningSvg = () => {
  return (
    <>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_19522_56148)">
          <rect width="44" height="44" rx="22" fill="#FFEAE9" />
          <path
            d="M25.1353 13.1437V17.3237H23.4853V13.1437C23.4853 12.8467 23.2213 12.7037 23.0453 12.7037C22.9903 12.7037 22.9353 12.7147 22.8803 12.7367L14.1573 16.0257C13.5743 16.2457 13.2003 16.7957 13.2003 17.4227V18.1597C12.1993 18.9077 11.5503 20.1067 11.5503 21.4597V17.4227C11.5503 16.1137 12.3533 14.9477 13.5743 14.4857L22.3083 11.1857C22.5503 11.0977 22.8033 11.0537 23.0453 11.0537C24.1453 11.0537 25.1353 11.9447 25.1353 13.1437Z"
            fill="#FF4D49"
          />
          <path
            d="M32.4503 24.7492V25.8492C32.4503 26.1462 32.2193 26.3882 31.9113 26.3992H30.3053C29.7223 26.3992 29.1943 25.9702 29.1503 25.3982C29.1173 25.0572 29.2493 24.7382 29.4693 24.5182C29.6673 24.3092 29.9423 24.1992 30.2393 24.1992H31.9003C32.2193 24.2102 32.4503 24.4522 32.4503 24.7492Z"
            fill="#FF4D49"
          />
          <path
            d="M14.3004 25.2988C13.2664 25.2988 12.3094 25.6618 11.5504 26.2668C10.5384 27.0698 9.90039 28.3128 9.90039 29.6988C9.90039 30.5238 10.1314 31.3048 10.5384 31.9648C11.2974 33.2408 12.6944 34.0988 14.3004 34.0988C15.4114 34.0988 16.4234 33.6918 17.1934 32.9988C17.5344 32.7128 17.8314 32.3608 18.0624 31.9648C18.4694 31.3048 18.7004 30.5238 18.7004 29.6988C18.7004 27.2678 16.7314 25.2988 14.3004 25.2988ZM12.5624 29.1048C12.2434 28.7858 12.2434 28.2578 12.5624 27.9388C12.8924 27.6198 13.4094 27.6198 13.7394 27.9388L14.3114 28.5218L14.8614 27.9608C15.1914 27.6418 15.7084 27.6418 16.0384 27.9608C16.3574 28.2908 16.3574 28.8078 16.0384 29.1378L15.4774 29.6878L16.0604 30.2598C16.3794 30.5898 16.3794 31.1068 16.0604 31.4368C15.8954 31.5908 15.6864 31.6678 15.4774 31.6678C15.2684 31.6678 15.0594 31.5908 14.8944 31.4368L14.3114 30.8538L13.7064 31.4588C13.5414 31.6238 13.3324 31.7008 13.1234 31.7008C12.9144 31.7008 12.7054 31.6238 12.5404 31.4588C12.2214 31.1398 12.2214 30.6118 12.5404 30.2928L13.1454 29.6878L12.5624 29.1048Z"
            fill="#FF4D49"
          />
          <path
            d="M30.2283 23.0442H31.3503C31.9553 23.0442 32.4503 22.5492 32.4503 21.9442V21.4602C32.4503 19.1832 30.5913 17.3242 28.3143 17.3242H15.6863C14.7513 17.3242 13.8933 17.6322 13.2003 18.1602C12.1993 18.9082 11.5503 20.1072 11.5503 21.4602V23.4182C11.5503 23.8362 11.9903 24.1002 12.3863 23.9682C13.0023 23.7592 13.6513 23.6492 14.3003 23.6492C17.6333 23.6492 20.3503 26.3662 20.3503 29.6992C20.3503 30.4912 20.1413 31.3602 19.8113 32.1302C19.6353 32.5262 19.9103 32.9992 20.3393 32.9992H28.3143C30.5913 32.9992 32.4503 31.1402 32.4503 28.8632V28.6542C32.4503 28.0492 31.9553 27.5542 31.3503 27.5542H30.3933C29.3373 27.5542 28.3253 26.9052 28.0503 25.8822C27.8303 25.0462 28.0943 24.2322 28.6443 23.7042C29.0513 23.2862 29.6123 23.0442 30.2283 23.0442ZM24.2003 22.8242H18.7003C18.2493 22.8242 17.8753 22.4502 17.8753 21.9992C17.8753 21.5482 18.2493 21.1742 18.7003 21.1742H24.2003C24.6513 21.1742 25.0253 21.5482 25.0253 21.9992C25.0253 22.4502 24.6513 22.8242 24.2003 22.8242Z"
            fill="#FF4D49"
          />
        </g>
        <rect
          x="0.366667"
          y="0.366667"
          width="43.2667"
          height="43.2667"
          rx="21.6333"
          stroke="#FF4D49"
          stroke-width="0.733333"
        />
        <defs>
          <clipPath id="clip0_19522_56148">
            <rect width="44" height="44" rx="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
export const LinkClicksSvg = () => {
  return (
    <>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.366667"
          y="0.366667"
          width="43.2667"
          height="43.2667"
          rx="21.6333"
          fill="#26C6F9"
        />
        <rect
          x="0.366667"
          y="0.366667"
          width="43.2667"
          height="43.2667"
          rx="21.6333"
          fill="white"
          fill-opacity="0.88"
        />
        <rect
          x="0.366667"
          y="0.366667"
          width="43.2667"
          height="43.2667"
          rx="21.6333"
          stroke="#26C6F9"
          stroke-width="0.733333"
        />
        <path
          d="M23.2652 25.7058C23.0232 25.3538 22.5942 25.1558 22.0992 25.1558H19.5362C19.3712 25.1558 19.2062 25.0898 19.1072 24.9578C18.9972 24.8258 18.9532 24.6608 18.9752 24.4738L19.2942 22.4278C19.4262 21.8228 19.0192 21.1298 18.4142 20.9208C17.8422 20.7118 17.1822 20.9978 16.9072 21.4048L14.3662 25.1888V24.7158C14.3662 23.7918 13.9702 23.4178 13.0022 23.4178H12.3642C11.3962 23.4288 11.0002 23.8028 11.0002 24.7268V31.0078C11.0002 31.9318 11.3962 32.3058 12.3642 32.3058H13.0022C13.9262 32.3058 14.3112 31.9428 14.3442 31.1068L16.2692 32.5918C16.5442 32.8558 17.1272 32.9988 17.5452 32.9988H19.9762C20.8122 32.9988 21.6482 32.3718 21.8462 31.6018L23.3862 26.9268C23.5512 26.4758 23.5072 26.0468 23.2652 25.7058Z"
          fill="#26C6F9"
        />
        <path
          d="M32.0213 11.6696H31.3833C30.4593 11.6696 30.0743 12.0326 30.0303 12.8686L28.1053 11.3836C27.8413 11.1196 27.2473 10.9766 26.8293 10.9766H24.3983C23.5623 10.9766 22.7263 11.6036 22.5283 12.3736L20.9883 17.0486C20.8233 17.4996 20.8783 17.9286 21.1093 18.2696C21.3513 18.6216 21.7803 18.8196 22.2753 18.8196H24.8383C25.0033 18.8196 25.1683 18.8856 25.2673 19.0176C25.3773 19.1496 25.4213 19.3146 25.3993 19.5016L25.0803 21.5476C24.9483 22.1526 25.3553 22.8456 25.9603 23.0546C26.5323 23.2636 27.1923 22.9776 27.4673 22.5706L30.0083 18.7866V19.2596C30.0083 20.1836 30.4043 20.5576 31.3723 20.5576H32.0103C32.9783 20.5576 33.3743 20.1836 33.3743 19.2596V12.9566C33.3853 12.0436 32.9893 11.6696 32.0213 11.6696Z"
          fill="#26C6F9"
        />
      </svg>
    </>
  );
};
export const ConversionsSvg = () => {
  return (
    <>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.366667"
          y="0.366667"
          width="43.2667"
          height="43.2667"
          rx="21.6333"
          fill="#F4FDEE"
        />
        <rect
          x="0.366667"
          y="0.366667"
          width="43.2667"
          height="43.2667"
          rx="21.6333"
          stroke="#65D31D"
          stroke-width="0.733333"
        />
        <path
          d="M29.8323 25.2334L30.1843 25.9484C30.3603 26.3004 30.8113 26.6304 31.1853 26.6964L31.6583 26.7734C33.0993 27.0154 33.4293 28.0714 32.4063 29.1054L31.9663 29.5454C31.6693 29.8424 31.5153 30.4144 31.6033 30.8324L31.6583 31.0964C32.0543 32.8344 31.1303 33.5054 29.6233 32.5924L29.3043 32.3944C28.9193 32.1634 28.2813 32.1634 27.8963 32.3944L27.5773 32.5924C26.0593 33.5054 25.1463 32.8344 25.5423 31.0964L25.5973 30.8324C25.6853 30.4254 25.5313 29.8424 25.2343 29.5454L24.7943 29.1054C23.7713 28.0604 24.1013 27.0154 25.5423 26.7734L26.0153 26.6964C26.4003 26.6304 26.8403 26.3004 27.0163 25.9484L27.3683 25.2334C28.0503 23.8584 29.1503 23.8584 29.8323 25.2334Z"
          fill="#65D31D"
        />
        <path
          d="M26.6092 10.999H17.3912C13.3872 10.999 11.0002 13.386 11.0002 17.39V26.608C11.0002 30.612 13.3872 32.999 17.3912 32.999H23.1992C23.5842 32.999 23.8592 32.603 23.8152 32.218C23.7712 31.8 23.7932 31.305 23.9252 30.733C23.9472 30.656 23.9252 30.568 23.8592 30.502L23.6172 30.26C22.6822 29.314 22.3412 28.17 22.6712 27.125C23.0122 26.091 23.9582 25.365 25.2672 25.145L25.5972 25.09L25.8942 24.496C26.4992 23.264 27.4892 22.549 28.6002 22.549C29.7112 22.549 30.7012 23.264 31.3062 24.496L31.4712 24.837C31.5482 25.002 31.7022 25.112 31.8782 25.145C31.9772 25.167 32.0762 25.189 32.1752 25.211C32.5602 25.31 33.0002 25.002 33.0002 24.595V17.39C33.0002 13.386 30.6132 10.999 26.6092 10.999ZM26.6862 18.655L24.1452 21.933C23.8262 22.34 23.3752 22.604 22.8582 22.659C22.3522 22.725 21.8352 22.582 21.4392 22.263L19.4262 20.701C19.3492 20.635 19.2612 20.635 19.2172 20.646C19.1732 20.646 19.0962 20.668 19.0302 20.756L16.4122 24.155C16.2472 24.364 16.0052 24.474 15.7522 24.474C15.5762 24.474 15.4002 24.419 15.2462 24.298C14.8832 24.023 14.8172 23.506 15.0922 23.143L17.7102 19.744C18.0292 19.337 18.4802 19.073 18.9972 19.007C19.5142 18.941 20.0202 19.084 20.4272 19.403L22.4402 20.987C22.5172 21.053 22.5942 21.042 22.6492 21.042C22.6932 21.042 22.7702 21.02 22.8362 20.932L25.3772 17.654C25.6522 17.291 26.1692 17.225 26.5322 17.511C26.9062 17.786 26.9612 18.303 26.6862 18.655Z"
          fill="#65D31D"
        />
      </svg>
    </>
  );
};
export const ReferLinkSvg = () => {
  return (
    <>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="44" height="44" rx="22" fill="#6699FF" />
        <path
          d="M26.6092 11H17.3912C13.3872 11 11.0002 13.387 11.0002 17.391V26.598C11.0002 30.613 13.3872 33 17.3912 33H26.5982C30.6022 33 32.9892 30.613 32.9892 26.609V17.391C33.0002 13.387 30.6132 11 26.6092 11ZM17.7982 27.247C17.7762 27.247 17.7432 27.247 17.7212 27.247C16.6542 27.148 15.6532 26.653 14.9052 25.861C13.1452 24.013 13.1452 21.01 14.9052 19.162L17.3142 16.632C18.1722 15.73 19.3272 15.224 20.5592 15.224C21.7912 15.224 22.9462 15.719 23.8042 16.632C25.5642 18.48 25.5642 21.483 23.8042 23.331L22.6052 24.596C22.2862 24.926 21.7692 24.937 21.4392 24.629C21.1092 24.31 21.0982 23.793 21.4062 23.463L22.6052 22.198C23.7712 20.977 23.7712 18.986 22.6052 17.776C21.5162 16.632 19.6022 16.632 18.5022 17.776L16.0932 20.306C14.9272 21.527 14.9272 23.518 16.0932 24.728C16.5662 25.234 17.2042 25.542 17.8752 25.608C18.3262 25.652 18.6562 26.059 18.6122 26.51C18.5792 26.928 18.2162 27.247 17.7982 27.247ZM29.0952 24.849L26.6862 27.379C25.8282 28.281 24.6732 28.787 23.4412 28.787C22.2092 28.787 21.0542 28.292 20.1962 27.379C18.4362 25.531 18.4362 22.528 20.1962 20.68L21.3952 19.415C21.7142 19.085 22.2312 19.074 22.5612 19.382C22.8912 19.701 22.9022 20.218 22.5942 20.548L21.3952 21.813C20.2292 23.034 20.2292 25.025 21.3952 26.235C22.4842 27.379 24.3982 27.39 25.4982 26.235L27.9072 23.705C29.0732 22.484 29.0732 20.493 27.9072 19.283C27.4342 18.777 26.7962 18.469 26.1252 18.403C25.6742 18.359 25.3442 17.952 25.3882 17.501C25.4322 17.05 25.8282 16.709 26.2902 16.764C27.3572 16.874 28.3582 17.358 29.1062 18.15C30.8552 19.987 30.8552 23.001 29.0952 24.849Z"
          fill="white"
        />
      </svg>
    </>
  );
};
export const HumanSvg = () => {
  return (
    <>
      <svg
        width="45"
        height="44"
        viewBox="0 0 45 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.666748" width="44" height="44" rx="22" fill="#6699FF" />
        <path
          d="M22.6669 11C19.7849 11 17.4419 13.343 17.4419 16.225C17.4419 19.052 19.6529 21.34 22.5349 21.439C22.6229 21.428 22.7109 21.428 22.7769 21.439C22.7989 21.439 22.8099 21.439 22.8319 21.439C22.8429 21.439 22.8429 21.439 22.8539 21.439C25.6699 21.34 27.8809 19.052 27.8919 16.225C27.8919 13.343 25.5489 11 22.6669 11Z"
          fill="white"
        />
        <path
          d="M28.2549 24.3646C25.1859 22.3186 20.1809 22.3186 17.0899 24.3646C15.6929 25.2996 14.9229 26.5646 14.9229 27.9176C14.9229 29.2706 15.6929 30.5246 17.0789 31.4486C18.6189 32.4826 20.6429 32.9996 22.6669 32.9996C24.6909 32.9996 26.7149 32.4826 28.2549 31.4486C29.6409 30.5136 30.4109 29.2596 30.4109 27.8956C30.3999 26.5426 29.6409 25.2886 28.2549 24.3646ZM24.8669 28.7426H23.4919V30.1176C23.4919 30.5686 23.1179 30.9426 22.6669 30.9426C22.2159 30.9426 21.8419 30.5686 21.8419 30.1176V28.7426H20.4669C20.0159 28.7426 19.6419 28.3686 19.6419 27.9176C19.6419 27.4666 20.0159 27.0926 20.4669 27.0926H21.8419V25.7176C21.8419 25.2666 22.2159 24.8926 22.6669 24.8926C23.1179 24.8926 23.4919 25.2666 23.4919 25.7176V27.0926H24.8669C25.3179 27.0926 25.6919 27.4666 25.6919 27.9176C25.6919 28.3686 25.3179 28.7426 24.8669 28.7426Z"
          fill="white"
        />
      </svg>
    </>
  );
};
export const BlueDollarSvg = () => {
  return (
    <>
      <svg
        width="45"
        height="44"
        viewBox="0 0 45 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0.333496" width="44" height="44" rx="22" fill="#6699FF" />
        <path
          d="M23.1587 26.3118H23.8737C24.5887 26.3118 25.1827 25.6738 25.1827 24.9038C25.1827 23.9468 24.8417 23.7598 24.2807 23.5618L23.1697 23.1768V26.3118H23.1587Z"
          fill="white"
        />
        <path
          d="M22.3008 10.8902C16.2288 10.9122 11.3118 15.8512 11.3338 21.9232C11.3558 27.9952 16.2948 32.9122 22.3668 32.8902C28.4388 32.8682 33.3558 27.9292 33.3338 21.8572C33.3118 15.7852 28.3728 10.8792 22.3008 10.8902ZM24.8198 22.0002C25.6778 22.2972 26.8328 22.9352 26.8328 24.9042C26.8328 26.5982 25.5018 27.9622 23.8738 27.9622H23.1588V28.6002C23.1588 29.0512 22.7848 29.4252 22.3338 29.4252C21.8828 29.4252 21.5088 29.0512 21.5088 28.6002V27.9622H21.1128C19.3088 27.9622 17.8458 26.4442 17.8458 24.5742C17.8458 24.1232 18.2198 23.7492 18.6708 23.7492C19.1218 23.7492 19.4958 24.1232 19.4958 24.5742C19.4958 25.5312 20.2218 26.3122 21.1128 26.3122H21.5088V22.5942L19.8478 22.0002C18.9898 21.7032 17.8348 21.0652 17.8348 19.0962C17.8348 17.4022 19.1658 16.0382 20.7938 16.0382H21.5088V15.4002C21.5088 14.9492 21.8828 14.5752 22.3338 14.5752C22.7848 14.5752 23.1588 14.9492 23.1588 15.4002V16.0382H23.5548C25.3588 16.0382 26.8218 17.5562 26.8218 19.4262C26.8218 19.8772 26.4478 20.2512 25.9968 20.2512C25.5458 20.2512 25.1718 19.8772 25.1718 19.4262C25.1718 18.4692 24.4458 17.6882 23.5548 17.6882H23.1588V21.4062L24.8198 22.0002Z"
          fill="white"
        />
        <path
          d="M19.4956 19.107C19.4956 20.064 19.8366 20.251 20.3976 20.449L21.5086 20.834V17.688H20.7936C20.0786 17.688 19.4956 18.326 19.4956 19.107Z"
          fill="white"
        />
      </svg>
    </>
  );
};
export const CopySvg = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
          stroke="#C6C6C6"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
          stroke="#C6C6C6"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
