import React from "react";
import firstrank from "../../Assets/Leaderboared/firstrank.svg";
import secondrank from "../../Assets/Leaderboared/secondrank.svg";
import thirdrank from "../../Assets/Leaderboared/thirdrank.svg";
import { MoveUp } from "lucide-react";
import more from "../../Assets/Competitions/more.svg";
import country from "../../Assets/Competitions/country.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
const Leaderboard = () => {
  return (
    <div>
      <div className="lg:text-[34px] text-[24px] font-semibold text-center">
        Funded Account Leaderboard
      </div>
      <div className="text-[#6C6C6C] lg:text-[16px] text-sm text-center">
        Top 10 account ranked by total gain.
      </div>

      {/*This is for large screen*/}
      <div className="mt-5 lg:flex lg:flex-row lg:gap-5 hidden sm:flex">
        <div className="border border-childBorder bg-background rounded-2xl w-full  p-5 flex flex-col gap-y-5">
          <div className="flex items-center gap-x-2">
            <img src={firstrank} alt="" />
            <div>
              <div className="text-[24px] font-semibold">Joshua C</div>
              <div className="text-[#6C6C6C] text-[14px]">@JoshuaC</div>
            </div>
          </div>
          <hr className="border-1px border-childBorder " />
          <div className="flex justify-between items-center">
            <div className="text-[#6C6C6C]">Total Profit</div>
            <div className="flex items-center gap-x-2">
              <div className="text-[20px] font-semibold"> $23,583</div>
              <div className="text-[#56CA00] bg-[#e8f4df] dark:bg-[#011845] w-[69px] h-[32px] rounded-xl flex justify-center items-center text-[13px] font-semibold">
                <MoveUp size={15} /> +42%
              </div>
            </div>
          </div>
        </div>

        <div className="border border-childBorder bg-background  rounded-2xl w-full p-5 flex flex-col gap-y-5">
          <div className="flex items-center gap-x-2">
            <img src={thirdrank} alt="" />
            <div>
              <div className="text-[24px] font-semibold">Joshua C</div>
              <div className="text-[#6C6C6C] text-[14px]">@JoshuaC</div>
            </div>
          </div>
          <hr className="border-1px border-childBorder " />
          <div className="flex justify-between items-center">
            <div className="text-[#6C6C6C]">Total Profit</div>
            <div className="flex items-center gap-x-2">
              <div className="text-[20px] font-semibold"> $23,583</div>
              <div className="text-[#56CA00] bg-[#e8f4df] dark:bg-[#011845] w-[69px] h-[32px] rounded-xl flex justify-center items-center text-[13px] font-semibold">
                <MoveUp size={15} /> +42%
              </div>
            </div>
          </div>
        </div>

        <div className="border border-childBorder bg-background  rounded-2xl  w-full  p-5 flex flex-col gap-y-5">
          <div className="flex items-center gap-x-2">
            <img src={secondrank} alt="" />
            <div>
              <div className="text-[24px] font-semibold">Joshua C</div>
              <div className="text-[#6C6C6C] text-[14px]">@JoshuaC</div>
            </div>
          </div>
          <hr className="border-1px border-childBorder " />
          <div className="flex justify-between items-center">
            <div className="text-[#6C6C6C]">Total Profit</div>
            <div className="flex items-center gap-x-2">
              <div className="text-[20px] font-semibold"> $23,583</div>
              <div className="text-[#56CA00] bg-[#e8f4df] dark:bg-[#011845] w-[69px] h-[32px] rounded-xl flex justify-center items-center text-[13px] font-semibold">
                <MoveUp size={15} /> +42%
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*This is for Mobile screen*/}
      <div className="mt-5 block sm:hidden">
        <Swiper
          modules={[Pagination]}
          pagination={{
            clickable: true,
            el: ".custom-pagination",
          }}
          spaceBetween={30}
          loop={true}
          slidesPerView={1}
          centeredSlides={true}
        >
          <SwiperSlide>
            <div className="border border-childBorder bg-background rounded-2xl w-full  p-5 flex flex-col gap-y-5">
              <div className="flex items-center gap-x-2">
                <img src={firstrank} alt="" />
                <div>
                  <div className="text-[24px] font-semibold">Joshua C</div>
                  <div className="text-[#6C6C6C] text-[14px]">@JoshuaC</div>
                </div>
              </div>
              <hr className="border-1px border-childBorder " />
              <div className="flex justify-between items-center">
                <div className="text-[#6C6C6C]">Total Profit</div>
                <div className="flex items-center gap-x-2">
                  <div className="text-[20px] font-semibold"> $23,583</div>
                  <div className="text-[#56CA00] bg-[#e8f4df] dark:bg-[#011845] w-[69px] h-[32px] rounded-xl flex justify-center items-center text-[13px] font-semibold">
                    <MoveUp size={15} /> +42%
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="border border-childBorder bg-background  rounded-2xl w-full p-5 flex flex-col gap-y-5">
              <div className="flex items-center gap-x-2">
                <img src={thirdrank} alt="" />
                <div>
                  <div className="text-[24px] font-semibold">Joshua C</div>
                  <div className="text-[#6C6C6C] text-[14px]">@JoshuaC</div>
                </div>
              </div>
              <hr className="border-1px border-childBorder " />
              <div className="flex justify-between items-center">
                <div className="text-[#6C6C6C]">Total Profit</div>
                <div className="flex items-center gap-x-2">
                  <div className="text-[20px] font-semibold"> $23,583</div>
                  <div className="text-[#56CA00] bg-[#e8f4df] dark:bg-[#011845] w-[69px] h-[32px] rounded-xl flex justify-center items-center text-[13px] font-semibold">
                    <MoveUp size={15} /> +42%
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="border border-childBorder bg-background  rounded-2xl  w-full  p-5 flex flex-col gap-y-5">
              <div className="flex items-center gap-x-2">
                <img src={secondrank} alt="" />
                <div>
                  <div className="text-[24px] font-semibold">Joshua C</div>
                  <div className="text-[#6C6C6C] text-[14px]">@JoshuaC</div>
                </div>
              </div>
              <hr className="border-1px border-childBorder " />
              <div className="flex justify-between items-center">
                <div className="text-[#6C6C6C]">Total Profit</div>
                <div className="flex items-center gap-x-2">
                  <div className="text-[20px] font-semibold"> $23,583</div>
                  <div className="text-[#56CA00] bg-[#e8f4df] dark:bg-[#011845] w-[69px] h-[32px] rounded-xl flex justify-center items-center text-[13px] font-semibold">
                    <MoveUp size={15} /> +42%
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        {/* Custom Pagination Dots */}
        <div className="custom-pagination flex justify-center mt-6"></div>
      </div>

      <div className="w-full mt-4 rounded-2xl border border-childBorder bg-background  p-5">
        <div className="flex justify-between">
          <div className="font-semibold ">Ranking</div>

          <div className="">
            <img src={more} alt="" />
          </div>
        </div>

        <div className="mt-5 overflow-x-auto ">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase bg-[#6699FF]  ">
              <tr>
                <th scope="col" className="px-6 py-5 ">
                  RANK
                </th>
                <th scope="col" className="px-6 py-5">
                  User
                </th>
                <th scope="col" className="px-6 py-5">
                  PROFIT
                </th>
                <th scope="col" className="px-6 py-5">
                  Account Size
                </th>
                <th scope="col" className="px-6 py-5">
                  Gain %
                </th>
                <th scope="col" className="px-6 py-5">
                  COUNTRY
                </th>
              </tr>
            </thead>
            <tbody className="">
              <tr className="">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">$26,149</td>
                <td className="px-6 py-2 text-[#67CA24]">$100,000</td>
                <td className="px-6 py-2">26%</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-tableBackground  ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">$26,149</td>
                <td className="px-6 py-2 text-[#67CA24]">$100,000</td>
                <td className="px-6 py-2">26%</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className=" ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">$26,149</td>
                <td className="px-6 py-2 text-[#67CA24]">$100,000</td>
                <td className="px-6 py-2">26%</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-tableBackground  ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">$26,149</td>
                <td className="px-6 py-2 text-[#67CA24]">$100,000</td>
                <td className="px-6 py-2">26%</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className=" ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">$26,149</td>
                <td className="px-6 py-2 text-[#67CA24]">$100,000</td>
                <td className="px-6 py-2">26%</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-tableBackground ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">$26,149</td>
                <td className="px-6 py-2 text-[#67CA24]">$100,000</td>
                <td className="px-6 py-2">26%</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">$26,149</td>
                <td className="px-6 py-2 text-[#67CA24]">$100,000</td>
                <td className="px-6 py-2">26%</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
              <tr className="bg-tableBackground  ">
                <td className="px-6 py-2">#1</td>
                <td className="px-6 py-2">Joshua C</td>
                <td className="px-6 py-2">$26,149</td>
                <td className="px-6 py-2 text-[#67CA24]">$100,000</td>
                <td className="px-6 py-2">26%</td>
                <td className="px-6 py-2">
                  <div className="flex items-center gap-x-2 font-medium">
                    <img
                      src={country}
                      alt="countryFlag"
                      className="w-[34px] h-[34px] rounded-full"
                    />
                    AUS
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
