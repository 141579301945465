import { CircleX } from "lucide-react";
import React, { useState } from "react";
import {
  VisaSvg,
  BlueMasterCardSvg,
  MasterCardSvg,
  AmexSvg,
  PaypalSvg,
  OrangeBitcoinSvg,
  EthereumSvg,
  GreenBitcoinSvg,
} from "../../../../Assets/Challenge/SvgNewChallenge";
const Checkout = () => {
  const [promoCode, setPromoCode] = useState("");
  const [isApplied, setIsApplied] = useState(false);
  const [selected, setSelected] = useState("Credit Card");
  const handleApply = () => {
    if (promoCode.trim()) {
      setIsApplied(true);
    }
  };

  const handleRemove = () => {
    setPromoCode("");
    setIsApplied(false);
  };

  const options = [
    {
      label: "Credit Card",
      icons: [
        {
          component: <VisaSvg />,
          alt: "Visa",
        },
        {
          component: <BlueMasterCardSvg />,
          alt: "MasterCard",
        },
        {
          component: <MasterCardSvg />,
          alt: "MasterCard",
        },
        {
          component: <AmexSvg />,
          alt: "Amex",
        },
      ],
    },
    {
      label: "Paypal",
      icons: [
        {
          component: <PaypalSvg />,
          alt: "PayPal",
        },
      ],
    },
    {
      label: "Crypto",
      icons: [
        {
          component: <OrangeBitcoinSvg />,
          alt: "Bitcoin",
        },
        {
          component: <EthereumSvg />,
          alt: "Ethereum",
        },
        {
          component: <GreenBitcoinSvg />,
          alt: "Bitcoin Cash",
        },
      ],
    },
  ];

  return (
    <div>
      {" "}
      <div className="text-[#6699FF] font-semibold text-[20px] text-center lg:mt-0 mt-5">
        Checkout
      </div>
      <div className="text-[16px] text-[#6C6C6C] font-normal text-center">
        Payment Method
      </div>
      <div className="mt-5 relative lg:w-[360px]">
        <input
          className="w-full h-[56px] rounded-xl dark:border-[#424346] dark:bg-[#121212] border-[#D0D5DD] border pl-4 pr-[80px] outline-none text-[#6C6C6C]"
          placeholder="Have a promo code? Enter here"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          disabled={isApplied} // Disable input when promo code is applied
        />
        <button
          onClick={isApplied ? handleRemove : handleApply}
          className={`absolute right-3 top-1/2 transform -translate-y-1/2 ${
            isApplied ? "" : "bg-[#6699FF]"
          } text-white font-semibold px-4 py-2 rounded-xl flex items-center justify-center`}
        >
          {isApplied ? (
            <CircleX className="w-5 h-5" color="#9E9FAB" />
          ) : (
            "Apply"
          )}
        </button>
      </div>
      <hr className="my-5 dark:border-[#424346]" />
      <div className="flex lg:justify-start lg:flex-row flex-col lg:gap-x-4 gap-y-4 mt-5">
        {options.map((option) => (
          <div
            key={option.label}
            onClick={() => setSelected(option.label)}
            className={`cursor-pointer flex lg:flex-col lg:gap-0 gap-2 items-center p-4 border rounded-2xl box-shadow lg:w-48 ${
              selected === option.label
                ? "border-[#6699FF] border-2  bg-blue-50 dark:bg-[#121212]"
                : "border-gray-300 dark:border-[#252525]"
            }`}
          >
            <div className="text-lg font-semibold lg:mb-3">{option.label}</div>
            <div className="flex space-x-2">
              {option.icons.map((icon, index) => (
                <div
                  key={index}
                  className="w-8 h-8 flex items-center justify-center"
                >
                  {icon.component}
                </div>
              ))}
            </div>
            <div
              className={`lg:mt-4 w-5 h-5 rounded-full border-2 flex items-center justify-center ${
                selected === option.label
                  ? "border-[#6699FF]"
                  : "border-gray-300"
              }`}
            >
              {selected === option.label && (
                <div className="w-2.5 h-2.5 rounded-full bg-[#6699FF]"></div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Checkout;
