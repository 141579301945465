import React, { useEffect, useState } from "react";
import qrcodeImage from "../../../../../Assets/Security/qrcodeImage.png";
import { CopySvg } from "../../../../../Assets/Affiliate/SvgAffiliate";
const QrCodePopUp = ({ isOpen, onClose }) => {
  const [step, setStep] = useState(1);
  const [backupCode] = useState("ASDLKNASDA9AH5678dGhASD7AB8"); // Example backup code

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Clean up when the component is unmounted
    };
  }, [isOpen]);

  const handleNext = () => {
    setStep(step + 1);
  };

  // const handleBack = () => {
  //   setStep(step - 1);
  // };

  return (
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-50 flex lg:justify-center lg:items-center justify-end items-end  z-50"
      onClick={onClose}
    >
      <div
        className="bg-white dark:bg-[#121212] lg:rounded-2xl rounded-t-3xl shadow-lg w-[500px] p-6"
        onClick={(e) => e.stopPropagation()}
      >
        {step === 1 && (
          <div>
            <div className="text-[34px] font-semibold text-center ">
              Scan QR Code
            </div>
            <p className="font-normal text-[#6C6C6C] text-center mb-4">
              Using an authenticator app like Google Authenticator, Microsoft
              Authenticator, Authy, or 1Password, scan the QR code. It will
              generate a 6 digit code for you to enter below.
            </p>
            <div className="flex justify-center mb-4">
              <div className="border  dark:border-[#252525] p-1 rounded-xl">
                <img src={qrcodeImage} alt="QR Code" />
              </div>
            </div>
            <p className="font-normal text-[#6C6C6C] text-center mb-4">
              It will generate a 6-digit code for you to enter below.
            </p>
            <input
              type="text"
              placeholder="Enter Authentication Code"
              className="border dark:bg-[#121212] dark:border-[#252525] w-full px-3 h-[56px] rounded-xl mb-4"
            />
            <div className="lg:flex lg:justify-end">
              <div className="flex space-x-2">
                <button
                  onClick={onClose}
                  className="bg-[#FAFCFD] dark:border-[#252525] dark:bg-[#1C1C1C] border-[#EAEAEC] border w-full lg:w-[94px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
                >
                  Cancel
                </button>
                <button
                  onClick={handleNext}
                  className="bg-[#6699FF] w-full lg:w-[109px] h-[38px] rounded-lg text-white font-semibold"
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        )}
        {step === 2 && (
          <div>
            <div className="text-[34px] font-semibold text-center ">
              You’re All Set
            </div>
            <p className=" text-[#6C6C6C] font-normal text-center mb-4">
              Use this backup code to access your account if you lose your phone
              or don’t have access to your verification device configured for
              2-factor authentication.
            </p>
            <div className="my-4 text-[#6C6C6C]  font-normal text-center">
              The backup code can only be used one time.
            </div>
            <p className="text-sm bg-[#faf4e4] dark:bg-[#2D2600] text-[#FDB528] text-left px-2 py-3 rounded-lg mb-4 font-semibold">
              Important: Save this backup code somewhere safe.
            </p>
            <div className="border-[#D0D5DD] dark:bg-[#121212] dark:border-[#252525] border h-[56px] rounded-xl flex justify-between items-center pl-2 pr-4 text-[#2B2A2A] mb-4">
              {backupCode}
              <CopySvg className="cursor-pointer" />
            </div>

            <hr className="my-4 dark:border-[#252525]" />
            <div className="flex justify-end">
              {/* <button
                onClick={handleBack}
                className="bg-gray-500 text-white px-4 py-2 rounded-md"
              >
                Back
              </button> */}
              <button
                onClick={onClose}
                className="bg-[#6699FF] w-[109px] h-[38px] rounded-lg text-white font-semibold"
              >
                Done
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QrCodePopUp;
