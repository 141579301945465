import React from "react";
import { Download } from "../../Assets/Tools/SvgTools";

const Downloads = () => {
  return (
    <div>
      <div className="lg:text-[34px] text-[24px] font-semibold text-center">
        Downloads
      </div>
      <div className="text-[#6C6C6C] lg:text-[16px] text-[14px]  text-center">
        View all the upcoming economic news events.
      </div>
      <div className=" border border-childBorder bg-background   rounded-2xl p-5 mt-10">
        <div className="text-[20px] font-semibold">Trading Platforms</div>
        <div className="overflow-x-auto">
          <table className="w-full mt-5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white  uppercase bg-[#6699FF] ">
              <tr>
                <th scope="col" className="px-6 py-4 w-[65%]">
                  Title
                </th>
                <th scope="col" className="px-6 py-4">
                  PlatforM
                </th>
                <th scope="col" className="px-6 py-4">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="dark:text-white">
              <tr className="bg-white dark:bg-gray-800 ">
                <td className="px-6 py-4">BespokeAlpha-Server</td>
                <td className="px-6 py-4">
                  <div className="bg-[#F4FDEE] flex justify-center items-center dark:bg-[#00270B] text-[#65D31D] border border-[#65D31D] w-[49px] h-[24px]  rounded-2xl">
                    MT5
                  </div>
                </td>

                <td className="px-6 py-4 font-semibold text-[#6699FF] flex items-center gap-x-2 ">
                  Download <Download />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="  border border-childBorder bg-background  rounded-2xl p-5 mt-5">
        <div className="text-[20px] font-semibold">Trading Tools</div>
        <div className="overflow-x-auto">
          <table className="w-full  mt-5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white  uppercase bg-[#6699FF] ">
              <tr>
                <th scope="col" className="px-6 py-4 w-[65%]">
                  Title
                </th>
                <th scope="col" className="px-6 py-4">
                  PlatforM
                </th>
                <th scope="col" className="px-6 py-4">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="dark:text-white">
              <tr className="bg-white   dark:bg-gray-800 ">
                <td className="px-6 py-4">Trade Manager</td>
                <td className="px-6 py-4">
                  <div className="bg-[#F4FDEE] flex justify-center dark:bg-[#00270B] items-center text-[#65D31D] border border-[#65D31D] w-[49px] h-[24px]  rounded-2xl">
                    MT5
                  </div>
                </td>

                <td className="px-6 py-4 font-semibold text-[#6699FF] flex items-center gap-x-2 ">
                  Download <Download />
                </td>
              </tr>
              <tr className="bg-[#F6F8FE]   dark:bg-gray-800 ">
                <td className="px-6 py-4 flex  items-center  gap-x-2">
                  Harmonic Pattern Scanner{" "}
                  <div className="text-[#6699FF] dark:bg-[#011845] bg-[#EDF4FF] w-[126px] h-[24px] lg:text-[13px] text-[8px] flex justify-center items-center rounded-xl border border-[#6699FF]">
                    MEMBERS ONLY
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="bg-[#F4FDEE] flex justify-center items-center dark:bg-[#00270B] text-[#65D31D] border border-[#65D31D] w-[49px] h-[24px]  rounded-2xl">
                    MT5
                  </div>
                </td>

                <td className="px-6 py-4 font-semibold text-[#6699FF] flex items-center gap-x-2 ">
                  Download <Download />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="  border border-childBorder bg-background  rounded-2xl p-5 mt-5">
        <div className="text-[20px] font-semibold">E-Books</div>
        <div className="overflow-x-auto">
          <table className="w-full mt-5 text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white  uppercase bg-[#6699FF] ">
              <tr>
                <th scope="col" className="px-6 py-4 w-[65%]">
                  Title
                </th>
                <th scope="col" className="px-6 py-4">
                  Difficulty
                </th>
                <th scope="col" className="px-6 py-4">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="dark:text-white">
              <tr className="bg-white   dark:bg-gray-800 ">
                <td className="px-6 py-4">Trade Manager</td>
                <td className="px-6 py-4">
                  <div className="bg-[#F4FDEE] flex justify-center items-center dark:bg-[#00270B] text-[#65D31D]  text-[13px] border border-[#65D31D] w-[79px] h-[24px]  rounded-2xl">
                    BEGINNER
                  </div>
                </td>

                <td className="px-6 py-4 font-semibold text-[#6699FF] flex items-center gap-x-2 ">
                  Download <Download />
                </td>
              </tr>
              <tr className="bg-[#F6F8FE]   dark:bg-gray-800 ">
                <td className="px-6 py-4 flex  items-center  gap-x-2">
                  Harmonic Pattern Scanner{" "}
                  <div className="text-[#6699FF] bg-[#EDF4FF] dark:bg-[#011845] w-[126px] h-[24px] lg:text-[13px] text-[8px] flex justify-center items-center rounded-xl border border-[#6699FF]">
                    MEMBERS ONLY
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div className="bg-[#caeaf4] flex justify-center items-center dark:bg-[#18283C] text-[#26C6F9]  text-[13px] border border-[#26C6F9] w-[79px] h-[24px]  rounded-2xl">
                    ADVANCED
                  </div>
                </td>

                <td className="px-6 py-4 font-semibold text-[#6699FF] flex items-center gap-x-2 ">
                  Download <Download />
                </td>
              </tr>
              <tr className="bg-white   dark:bg-gray-800 ">
                <td className="px-6 py-4">Trading Currencies 101</td>
                <td className="px-6 py-4">
                  <div className="bg-[#f3ebd9] flex justify-center items-center dark:bg-[#2D2600] text-[#FDB528]  text-[13px] border border-[#FDB528] w-[118px] h-[24px]  rounded-2xl">
                    INTERMEDIATE
                  </div>
                </td>

                <td className="px-6 py-4 font-semibold text-[#6699FF] flex items-center gap-x-2 ">
                  Download <Download />
                </td>
              </tr>
              <tr className="bg-[#F6F8FE]   dark:bg-gray-800 ">
                <td className="px-6 py-4 ">Beginners Trading Guide</td>
                <td className="px-6 py-4">
                  <div className="bg-[#F4FDEE] flex justify-center dark:bg-[#00270B]  items-center text-[#65D31D]  text-[13px] border border-[#65D31D] w-[79px] h-[24px]  rounded-2xl">
                    BEGINNER
                  </div>
                </td>

                <td className="px-6 py-4 font-semibold text-[#6699FF] flex items-center gap-x-2 ">
                  Download <Download />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Downloads;
