import { Copy, TriangleAlert } from "lucide-react";
import React, { useEffect, useRef } from "react";
const CredentialsPopup = ({ isOpen, onClose }) => {
  const popupRef = useRef(null);
  // Close the popup when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Clean up when the component is unmounted
    };
  }, [isOpen]);
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex lg:justify-center lg:items-center justify-end items-end z-50">
      <div
        ref={popupRef}
        className="bg-white dark:bg-[#121212] lg:rounded-2xl rounded-t-3xl p-6 lg:w-[50%] max-w-[1100px] flex flex-col justify-between shadow-lg"
      >
        {/* Header */}
        <div className="flex justify-between items-center ">
          <h2 className="font-semibold dark:text-white lg:text-[34px] text-[20px] text-gray-800 text-center w-full">
            Account Credentials
          </h2>
          <button
            onClick={onClose}
            className="text-white w-[24px] h-[24px] rounded-full bg-[#9E9FAB] transition duration-200"
          >
            ✕
          </button>
        </div>

        {/* Description */}
        <p className="text-[#6C6C6C] lg:text-[16px] text-center font-medium mb-6">
          Below are you MT5 trading account credentials, login to start trading.
        </p>
        <div className="text-[#FDB528] dark:bg-[#2D2600] lg:text-[14px] text-[12px]  bg-[#f9f2e5] gap-x-1 mb-6 flex justify-center items-center py-3 px-2 w-full rounded-lg font-normal">
          <TriangleAlert /> <span className="font-bold">Note:</span>Download our
          MT5 installer below to get started. Learn More
        </div>
        <hr className="border-childBorder" />

        <form className="mt-6 ">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-5">
            <div className="relative">
              <input
                className="w-full dark:border-[#424346] dark:bg-[#121212] h-[56px] rounded-xl border-[#D0D5DD] border pl-2 pr-10 outline-none text-[#6C6C6C]" // Add padding-right to make space for the icon
                name="firstName"
                placeholder="Joshua Casey"
              />

              <Copy
                color="#9E9FAB"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
              />
            </div>
            <div className="relative">
              <input
                className="w-full dark:border-[#424346] dark:bg-[#121212] h-[56px] rounded-xl border-[#D0D5DD] border pl-2 pr-10 outline-none text-[#6C6C6C]" // Add padding-right to make space for the icon
                name="firstName"
                placeholder="DLbR8HvH"
              />

              <Copy
                color="#9E9FAB"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
              />
            </div>
            <input
              className="w-full dark:border-[#424346] dark:bg-[#121212] h-[56px] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
              name="phone"
              placeholder="+61 493 166 747"
            />
            <input
              className="w-full dark:border-[#424346] dark:bg-[#121212] h-[56px] rounded-xl border-[#D0D5DD] border pl-2 outline-none text-[#6C6C6C]"
              name="amount"
              placeholder="$1,750.00"
            />
            <div className="relative">
              <input
                className="w-full dark:border-[#424346] dark:bg-[#121212] h-[56px] rounded-xl border-[#D0D5DD] border pl-2 pr-10 outline-none text-[#6C6C6C]" // Add padding-right to make space for the icon
                name="firstName"
                placeholder="BespokeAlpha-Server"
              />

              <Copy
                color="#9E9FAB"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
              />
            </div>
            <div className="relative">
              <input
                className="w-full dark:border-[#424346] dark:bg-[#121212] h-[56px] rounded-xl border-[#D0D5DD] border pl-2 pr-10 outline-none text-[#6C6C6C]" // Add padding-right to make space for the icon
                name="firstName"
                placeholder="Windows / Mac / Android / iOS"
              />

              <Copy
                color="#9E9FAB"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CredentialsPopup;
