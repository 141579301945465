import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const SmsAuthPopup = ({ isOpen, onClose }) => {
  const [phone, setPhone] = useState("");
  const [step, setStep] = useState(1); // Step 1: Mobile Number, Step 2: SMS Code

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Clean up when the component is unmounted
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const goToNextStep = () => setStep(2);
  const goToPreviousStep = () => setStep(1);

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex lg:justify-center lg:items-center justify-end items-end ">
      <div className="bg-white dark:bg-[#121212] rounded-t-3xl lg:rounded-2xl p-6 w-[500px]">
        {step === 1 && (
          <div>
            <div className="text-[34px] text-center font-semibold">
              Enter Your Mobile Number
            </div>
            <div className="text-[16px] text-center text-[#6C6C6C] mb-4">
              Enter your mobile phone number with country code and we will send
              you a verification code.
            </div>

            <div>
              <PhoneInput
                country={"us"}
                value={phone}
                onChange={(value) => setPhone(value)}
                inputStyle={{
                  width: "100%",
                  height: "40px",
                  borderRadius: "12px",
                  border: "1px solid #ccc",
                  paddingLeft: "50px",
                  fontSize: "16px",
                }}
                buttonStyle={{
                  // border: "none",
                  borderRadius: "12px 0 0 12px",
                  backgroundColor: "#f9f9f9",
                }}
                containerStyle={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                dropdownStyle={{
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              />
            </div>

            {/* <p className="text-red-500 text-sm my-4">
              Too many failed attempts, please try again in (30) minutes.
            </p> */}
            <hr className="my-4 border-childBorder" />
            <div className="flex justify-end space-x-2">
              <button
                onClick={onClose}
                className="bg-[#FAFCFD] border-[#EAEAEC] dark:border-[#252525] dark:bg-[#1C1C1C] border w-full lg:w-[94px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
              >
                Cancel
              </button>
              <button
                onClick={goToNextStep}
                className="bg-[#6699FF] w-full lg:w-[109px] h-[38px] rounded-lg text-white font-semibold"
              >
                Continue
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div>
            <div className="text-[34px] text-center font-semibold ">
              Verify Your SMS Code
            </div>

            <div className="text-[16px] text-center  text-[#6C6C6C] mb-4">
              <p>
                Enter the verification code sent to your mobile. Didn't get the
                code? <span className="text-[#6699FF]">Resend</span>
              </p>
            </div>

            <input
              type="text"
              placeholder="Verification Code"
              className="w-full dark:bg-[#121212] dark:border-[#252525] border outline-none rounded-xl p-2 mb-4"
            />
            {/* <p className="text-red-500 text-sm mb-4">
              Too many failed attempts, please try again in (30) minutes.
            </p> */}
            <hr className="my-4 border-childBorder" />
            <div className="flex justify-end space-x-2">
              <button
                onClick={goToPreviousStep}
                className="bg-[#FAFCFD] dark:border-[#252525] dark:bg-[#1C1C1C] border-[#EAEAEC] border w-[79px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
              >
                Back
              </button>
              <button
                onClick={onClose}
                className="bg-[#6699FF] w-[87px] h-[38px] rounded-lg text-white font-semibold"
              >
                Verify
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SmsAuthPopup;
