import React from "react";

import wallet from "../../Assets/Challenge/wallet.svg";
import profile from "../../Assets/Challenge/profile.svg";
import setting from "../../Assets/Challenge/setting.svg";
import LineChart from "./Charts/LineChart";
const Balance = () => {
  return (
    <div className="bg-background rounded-2xl border-childBorder border flex justify-between lg:flex-row flex-col p-5 gap-5">
      <div className="w-full sm:w-[270px] flex flex-col justify-between">
        <div className="bg-[#F5F5F5] dark:bg-[#0D0D0D]  rounded-lg w-full h-[42px] flex items-center justify-between gap-x-2">
          <div className="text-[#6C6C6C] font-semibold w-[97px] h-[30px] flex justify-center items-center">
            Drawdown
          </div>
          <div className="bg-white dark:bg-[#011845] dark:text-white rounded-lg text-[#6699FF] font-semibold w-[79px] h-[30px] flex justify-center items-center">
            Balance
          </div>
          <div className="text-[#6C6C6C] font-semibold w-[69px] h-[30px] flex justify-center items-center">
            Equity
          </div>
        </div>
        <div className="flex lg:flex-col flex-col-reverse">
          <div className="flex flex-col items-start gap-y-2 lg:mt-4">
            <div className="flex justify-start">
              <div className="bg-[#FAFCFD] dark:bg-[#121212] border-childBorder w-[60px] h-[60px] rounded-full border-[#EEEEEE] border flex justify-center items-center mx-auto sm:mx-0">
                <img src={wallet} alt="Wallet Icon" />
              </div>
            </div>
            <div className="text-[16px] font-semibold text-center sm:text-left">
              Total Balance
            </div>
            <div className="text-center sm:text-left">
              <div className="text-[34px] font-semibold">$80,500</div>
              <div className="text-[#6C6C6C] text-[12px]">
                Recorded balance for a year
              </div>
            </div>
          </div>

          <div className="flex lg:justify-start justify-end sm:justify-start gap-x-2 mt-4">
            <img
              src={profile}
              alt="Profile Icon"
              className="w-[24px] h-[24px] cursor-pointer"
            />
            <img
              src={setting}
              alt="Setting Icon"
              className="w-[24px] h-[24px] cursor-pointer"
            />
          </div>
        </div>
      </div>

      <div className="lg:border-l border-childBorder w-full lg:w-[850px] lg:pl-5 ">
        <div className="text-[#6C6C6C] font-semibold text-[16px]">Balance</div>
        <div className="text-[24px] font-semibold">$221,568.02</div>
        <div className="text-[#6C6C6C] font-medium text-[14px]">
          Balance from Jan - Dec, 2024
        </div>
        <div className="mt-2 w-full">
          <LineChart />
        </div>
      </div>

      <button className="mt-2 w-full dark:bg-[#1C1C1C] dark:border-[#252525] lg:hidden bg-[#FAFCFD] border-[#EAEAEC] border text-[#6699FF] font-semibold rounded-lg h-[38px] ">
        View Report
      </button>
    </div>
  );
};

export default Balance;
