export const GreyStar = () => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.7278 6.53102L19.2788 9.63302C19.4878 10.062 20.0488 10.469 20.5218 10.557L23.3268 11.019C25.1198 11.316 25.5378 12.614 24.2508 13.912L22.0618 16.101C21.6988 16.464 21.4898 17.179 21.6108 17.696L22.2378 20.402C22.7328 22.536 21.5888 23.372 19.7078 22.25L17.0788 20.688C16.6058 20.402 15.8138 20.402 15.3408 20.688L12.7118 22.25C10.8308 23.361 9.68681 22.536 10.1818 20.402L10.8088 17.696C10.9078 17.168 10.6988 16.453 10.3358 16.09L8.14681 13.901C6.85981 12.614 7.27781 11.316 9.07081 11.008L11.8758 10.546C12.3488 10.469 12.9098 10.051 13.1188 9.62202L14.6698 6.52002C15.5168 4.84802 16.8808 4.84802 17.7278 6.53102Z"
          fill="#A4A4A4"
        />
        <path
          d="M9.59883 7.12501H2.99883C2.54783 7.12501 2.17383 6.75101 2.17383 6.30001C2.17383 5.84901 2.54783 5.47501 2.99883 5.47501H9.59883C10.0498 5.47501 10.4238 5.84901 10.4238 6.30001C10.4238 6.75101 10.0498 7.12501 9.59883 7.12501Z"
          fill="#A4A4A4"
        />
        <path
          d="M6.29883 22.525H2.99883C2.54783 22.525 2.17383 22.151 2.17383 21.7C2.17383 21.249 2.54783 20.875 2.99883 20.875H6.29883C6.74983 20.875 7.12383 21.249 7.12383 21.7C7.12383 22.151 6.74983 22.525 6.29883 22.525Z"
          fill="#A4A4A4"
        />
        <path
          d="M4.09883 14.825H2.99883C2.54783 14.825 2.17383 14.451 2.17383 14C2.17383 13.549 2.54783 13.175 2.99883 13.175H4.09883C4.54983 13.175 4.92383 13.549 4.92383 14C4.92383 14.451 4.54983 14.825 4.09883 14.825Z"
          fill="#A4A4A4"
        />
      </svg>
    </>
  );
};

export const BlueStar = () => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.7278 6.53102L19.2788 9.63302C19.4878 10.062 20.0488 10.469 20.5218 10.557L23.3268 11.019C25.1198 11.316 25.5378 12.614 24.2508 13.912L22.0618 16.101C21.6988 16.464 21.4898 17.179 21.6108 17.696L22.2378 20.402C22.7328 22.536 21.5888 23.372 19.7078 22.25L17.0788 20.688C16.6058 20.402 15.8138 20.402 15.3408 20.688L12.7118 22.25C10.8308 23.361 9.68681 22.536 10.1818 20.402L10.8088 17.696C10.9078 17.168 10.6988 16.453 10.3358 16.09L8.14681 13.901C6.85981 12.614 7.27781 11.316 9.07081 11.008L11.8758 10.546C12.3488 10.469 12.9098 10.051 13.1188 9.62202L14.6698 6.52002C15.5168 4.84802 16.8808 4.84802 17.7278 6.53102Z"
          fill="#6699FF"
        />
        <path
          d="M9.59883 7.12501H2.99883C2.54783 7.12501 2.17383 6.75101 2.17383 6.30001C2.17383 5.84901 2.54783 5.47501 2.99883 5.47501H9.59883C10.0498 5.47501 10.4238 5.84901 10.4238 6.30001C10.4238 6.75101 10.0498 7.12501 9.59883 7.12501Z"
          fill="#6699FF"
        />
        <path
          d="M6.29883 22.525H2.99883C2.54783 22.525 2.17383 22.151 2.17383 21.7C2.17383 21.249 2.54783 20.875 2.99883 20.875H6.29883C6.74983 20.875 7.12383 21.249 7.12383 21.7C7.12383 22.151 6.74983 22.525 6.29883 22.525Z"
          fill="#6699FF"
        />
        <path
          d="M4.09883 14.825H2.99883C2.54783 14.825 2.17383 14.451 2.17383 14C2.17383 13.549 2.54783 13.175 2.99883 13.175H4.09883C4.54983 13.175 4.92383 13.549 4.92383 14C4.92383 14.451 4.54983 14.825 4.09883 14.825Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const OrangeStar = () => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.7278 6.5309L19.2788 9.6329C19.4878 10.0619 20.0488 10.4689 20.5218 10.5569L23.3268 11.0189C25.1198 11.3159 25.5378 12.6139 24.2508 13.9119L22.0618 16.1009C21.6988 16.4639 21.4898 17.1789 21.6108 17.6959L22.2378 20.4019C22.7328 22.5359 21.5888 23.3719 19.7078 22.2499L17.0788 20.6879C16.6058 20.4019 15.8138 20.4019 15.3408 20.6879L12.7118 22.2499C10.8308 23.3609 9.68681 22.5359 10.1818 20.4019L10.8088 17.6959C10.9078 17.1679 10.6988 16.4529 10.3358 16.0899L8.14681 13.9009C6.85981 12.6139 7.27781 11.3159 9.07081 11.0079L11.8758 10.5459C12.3488 10.4689 12.9098 10.0509 13.1188 9.6219L14.6698 6.5199C15.5168 4.8479 16.8808 4.8479 17.7278 6.5309Z"
          fill="#FDB528"
        />
        <path
          d="M9.59883 7.12491H2.99883C2.54783 7.12491 2.17383 6.75091 2.17383 6.29991C2.17383 5.84891 2.54783 5.47491 2.99883 5.47491H9.59883C10.0498 5.47491 10.4238 5.84891 10.4238 6.29991C10.4238 6.75091 10.0498 7.12491 9.59883 7.12491Z"
          fill="#FDB528"
        />
        <path
          d="M6.29883 22.5249H2.99883C2.54783 22.5249 2.17383 22.1509 2.17383 21.6999C2.17383 21.2489 2.54783 20.8749 2.99883 20.8749H6.29883C6.74983 20.8749 7.12383 21.2489 7.12383 21.6999C7.12383 22.1509 6.74983 22.5249 6.29883 22.5249Z"
          fill="#FDB528"
        />
        <path
          d="M4.09883 14.8249H2.99883C2.54783 14.8249 2.17383 14.4509 2.17383 13.9999C2.17383 13.5489 2.54783 13.1749 2.99883 13.1749H4.09883C4.54983 13.1749 4.92383 13.5489 4.92383 13.9999C4.92383 14.4509 4.54983 14.8249 4.09883 14.8249Z"
          fill="#FDB528"
        />
      </svg>
    </>
  );
};

export const Placement = () => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9992 17.3005C18.0999 17.3005 21.4242 14.0993 21.4242 10.1505C21.4242 6.20165 18.0999 3.00049 13.9992 3.00049C9.8985 3.00049 6.57422 6.20165 6.57422 10.1505C6.57422 14.0993 9.8985 17.3005 13.9992 17.3005Z"
          fill="#6699FF"
        />
        <path
          d="M18.1678 17.9715C18.5308 17.7845 18.9488 18.0595 18.9488 18.4665V23.8015C18.9488 24.7915 18.2558 25.2755 17.3978 24.8685L14.4498 23.4715C14.1968 23.3615 13.8008 23.3615 13.5478 23.4715L10.5998 24.8685C9.74183 25.2645 9.04883 24.7805 9.04883 23.7905L9.07083 18.4665C9.07083 18.0595 9.49983 17.7955 9.85183 17.9715C11.0948 18.5985 12.5028 18.9505 13.9988 18.9505C15.4948 18.9505 16.9138 18.5985 18.1678 17.9715Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};
export const DollarCircle = () => {
  return (
    <>
      <svg
        width="27"
        height="28"
        viewBox="0 0 27 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.4912 18.3122H15.2062C15.9212 18.3122 16.5152 17.6742 16.5152 16.9042C16.5152 15.9472 16.1742 15.7602 15.6132 15.5622L14.5022 15.1772V18.3122H14.4912Z"
          fill="#65D31D"
        />
        <path
          d="M13.6331 2.89016C7.56109 2.91216 2.64409 7.85116 2.66609 13.9232C2.68809 19.9952 7.62709 24.9122 13.6991 24.8902C19.7711 24.8682 24.6881 19.9292 24.6661 13.8572C24.6441 7.78515 19.7051 2.87916 13.6331 2.89016ZM16.1521 14.0002C17.0101 14.2972 18.1651 14.9352 18.1651 16.9042C18.1651 18.5982 16.8341 19.9622 15.2061 19.9622H14.4911V20.6002C14.4911 21.0512 14.1171 21.4252 13.6661 21.4252C13.2151 21.4252 12.8411 21.0512 12.8411 20.6002V19.9622H12.4451C10.6411 19.9622 9.17809 18.4442 9.17809 16.5742C9.17809 16.1232 9.55209 15.7492 10.0031 15.7492C10.4541 15.7492 10.8281 16.1232 10.8281 16.5742C10.8281 17.5312 11.5541 18.3122 12.4451 18.3122H12.8411V14.5942L11.1801 14.0002C10.3221 13.7032 9.16709 13.0652 9.16709 11.0962C9.16709 9.40215 10.4981 8.03815 12.1261 8.03815H12.8411V7.40016C12.8411 6.94916 13.2151 6.57516 13.6661 6.57516C14.1171 6.57516 14.4911 6.94916 14.4911 7.40016V8.03815H14.8871C16.6911 8.03815 18.1541 9.55615 18.1541 11.4262C18.1541 11.8772 17.7801 12.2512 17.3291 12.2512C16.8781 12.2512 16.5041 11.8772 16.5041 11.4262C16.5041 10.4692 15.7781 9.68816 14.8871 9.68816H14.4911V13.4062L16.1521 14.0002Z"
          fill="#65D31D"
        />
        <path
          d="M10.8281 11.1075C10.8281 12.0645 11.1691 12.2515 11.7301 12.4495L12.8411 12.8345V9.68848H12.1261C11.4111 9.68848 10.8281 10.3265 10.8281 11.1075Z"
          fill="#65D31D"
        />
      </svg>
    </>
  );
};
export const Gift = () => {
  return (
    <>
      <svg
        width="27"
        height="28"
        viewBox="0 0 27 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.1322 14.0004V20.6004C22.1322 23.0314 20.1632 25.0004 17.7322 25.0004H8.93223C6.50123 25.0004 4.53223 23.0314 4.53223 20.6004V14.0004C4.53223 13.3954 5.02723 12.9004 5.63223 12.9004H7.79923C8.40423 12.9004 8.89923 13.3954 8.89923 14.0004V17.4544C8.89923 18.2684 9.35023 19.0164 10.0652 19.4014C10.3842 19.5774 10.7362 19.6654 11.0992 19.6654C11.5172 19.6654 11.9352 19.5444 12.2982 19.3024L13.3432 18.6204L14.3112 19.2694C14.9822 19.7204 15.8402 19.7754 16.5552 19.3904C17.2812 19.0054 17.7322 18.2684 17.7322 17.4434V14.0004C17.7322 13.3954 18.2272 12.9004 18.8322 12.9004H21.0322C21.6372 12.9004 22.1322 13.3954 22.1322 14.0004Z"
          fill="#26C6F9"
        />
        <path
          d="M23.7818 8.50029V9.60029C23.7818 10.8103 23.1988 11.8003 21.5818 11.8003H5.08184C3.39884 11.8003 2.88184 10.8103 2.88184 9.60029V8.50029C2.88184 7.29029 3.39884 6.30029 5.08184 6.30029H21.5818C23.1988 6.30029 23.7818 7.29029 23.7818 8.50029Z"
          fill="#26C6F9"
        />
        <path
          d="M12.9363 6.30018H6.86428C6.49028 5.89318 6.50128 5.26618 6.89728 4.87018L8.45928 3.30818C8.86628 2.90118 9.53728 2.90118 9.94428 3.30818L12.9363 6.30018Z"
          fill="#26C6F9"
        />
        <path
          d="M19.7888 6.30018H13.7168L16.7088 3.30818C17.1158 2.90118 17.7868 2.90118 18.1938 3.30818L19.7558 4.87018C20.1518 5.26618 20.1628 5.89318 19.7888 6.30018Z"
          fill="#26C6F9"
        />
        <path
          d="M15.4988 12.9004C16.1038 12.9004 16.5988 13.3954 16.5988 14.0004V17.4434C16.5988 18.3234 15.6198 18.8514 14.8938 18.3564L13.9038 17.6964C13.5408 17.4544 13.0678 17.4544 12.6938 17.6964L11.6598 18.3784C10.9338 18.8624 9.96582 18.3344 9.96582 17.4654V14.0004C9.96582 13.3954 10.4608 12.9004 11.0658 12.9004H15.4988Z"
          fill="#26C6F9"
        />
      </svg>
    </>
  );
};
export const Timer = () => {
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9989 5.91528C8.74091 5.91528 4.46191 10.1943 4.46191 15.4523C4.46191 20.7103 8.74091 25.0003 13.9989 25.0003C19.2569 25.0003 23.5359 20.7213 23.5359 15.4633C23.5359 10.2053 19.2569 5.91528 13.9989 5.91528ZM14.8239 15.1003C14.8239 15.5513 14.4499 15.9253 13.9989 15.9253C13.5479 15.9253 13.1739 15.5513 13.1739 15.1003V9.60028C13.1739 9.14928 13.5479 8.77528 13.9989 8.77528C14.4499 8.77528 14.8239 9.14928 14.8239 9.60028V15.1003Z"
          fill="#FDB528"
        />
        <path
          d="M17.1773 4.59524H10.8193C10.3793 4.59524 10.0273 4.24324 10.0273 3.80324C10.0273 3.36324 10.3793 3.00024 10.8193 3.00024H17.1773C17.6173 3.00024 17.9693 3.35224 17.9693 3.79224C17.9693 4.23224 17.6173 4.59524 17.1773 4.59524Z"
          fill="#FDB528"
        />
      </svg>
    </>
  );
};
