import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import { Link } from "react-router-dom";
import Logincarousel from "../Logincarousel/Logincarousel";
import images from "../../Assets/Assets";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    password: "",
    confirmPassword: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNextStep = () => {
    setCurrentStep(2);
  };

  const handlePreviousStep = () => {
    setCurrentStep(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", formData);
  };
  return (
    <div className="lg:flex lg:justify-between ">
      <section className="hidden lg:block  lg:w-[843px]">
        <div
          className="w-full h-[100vh] bg-cover bg-center text-white lg:flex flex-col justify-center items-center relative"
          style={{ backgroundImage: `url(${images.background})` }}
        >
          <div className="absolute inset-0 bg-black opacity-50 z-10"></div>
          <div className="absolute top-10 left-10 z-20">
            <img src={images.logo} alt="logo" />
          </div>
          <div className="font-semibold w-[563px] text-center z-20 mt-20">
            <div className="text-[58px] leading-[56px] text-white">
              Manage your Money Anywhere
            </div>
            <div className="text-[#FFFFFF] text-[16px] text-center leading-[24px] mt-5">
              Embarking on the journey of investment just a financial decision
              is a commitment to your future prosperity.
            </div>
            <div className="mt-12">
              <Logincarousel />
            </div>
          </div>
        </div>
      </section>
      <section className="w-full lg:w-1/2 flex justify-center items-center">
        <div className="w-[90%] sm:w-[397px] flex flex-col gap-y-5 mx-auto">
          <div className="md:hidden pt-5">
            <img src={images.logo} alt="logo" />
          </div>
          <div>
            <div className="font-semibold  dark:text-white lg:text-[34px] text-[#2B2A2A] lg:text-center text-left text-[24px]">
              Sign up
            </div>
            <div className="text-[#6C6C6C] text-[14px] font-normal lg:text-center text-left mt-2">
              Fill out the information below to get started
            </div>
          </div>

          {currentStep === 1 && (
            <form onSubmit={handleSubmit} className="flex flex-col gap-y-5">
              <input
                type="text"
                name="firstName"
                placeholder="First name"
                value={formData.firstName}
                onChange={handleInputChange}
                className="h-[56px] w-full dark:border-[#424346] dark:bg-[#121212] rounded-lg border border-[#D0D5DD] pl-5 outline-none placeholder-style"
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last name"
                value={formData.lastName}
                onChange={handleInputChange}
                className="h-[56px] w-full dark:border-[#424346] dark:bg-[#121212] rounded-lg border border-[#D0D5DD] pl-5 outline-none placeholder-style"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                className="h-[56px] w-full dark:border-[#424346] dark:bg-[#121212] rounded-lg border border-[#D0D5DD] pl-5 outline-none placeholder-style"
              />
              <button
                type="button"
                onClick={handleNextStep}
                className="w-full py-3 bg-[#6699FF] text-white rounded-lg font-semibold"
              >
                Next
              </button>
              <div className="mt-2 text-left text-[#6C6C6C]">
                Already have an account?{" "}
                <Link to={"/login"}>
                  <span className="text-[#4673CC] font-medium cursor-pointer">
                    Login here
                  </span>
                </Link>
              </div>
            </form>
          )}

          {currentStep === 2 && (
            <form onSubmit={handleSubmit} className="flex flex-col gap-y-5">
              <input
                type="text"
                name="country"
                placeholder="Country"
                value={formData.country}
                onChange={handleInputChange}
                className="h-[56px] w-full dark:border-[#424346] dark:bg-[#121212] rounded-lg border border-[#D0D5DD] pl-5 outline-none placeholder-style"
              />

              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="h-[56px] w-full dark:border-[#424346] dark:bg-[#121212] rounded-lg border border-[#D0D5DD] pl-5 pr-12 outline-none placeholder-style"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <div
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff className="text-gray-500" />
                  ) : (
                    <Eye className="text-gray-500" />
                  )}
                </div>
              </div>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  className="h-[56px] w-full dark:border-[#424346] dark:bg-[#121212] rounded-lg border border-[#D0D5DD] pl-5 pr-12 outline-none placeholder-style"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                />
                <div
                  className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <EyeOff className="text-gray-500" />
                  ) : (
                    <Eye className="text-gray-500" />
                  )}
                </div>
              </div>

              <div className="flex items-center mb-4">
                <input type="checkbox" className="mr-2" required />
                <label className="text-[13px]">
                  I certify that I am 18 years of age or older, agree to
                  the&nbsp;
                  <span className="font-bold">User Agreement</span> and
                  acknowledge the{" "}
                  <span className="font-bold">Privacy Policy.</span>
                </label>
              </div>
              <button
                type="submit"
                className="w-full py-3 bg-[#6699FF] text-white rounded-lg font-semibold"
              >
                Sign Up
              </button>
              <div className="mt-2 text-center text-gray-600">
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="text-[#6D788D] cursor-pointer font-semibold"
                >
                  Go Back
                </button>
              </div>
            </form>
          )}

          {/* Social Media Icons */}
          <div className="flex items-center gap-4">
            <hr className="flex-grow border-childBorder" />
            <span className="text-gray-500">or</span>
            <hr className="flex-grow border-childBorder" />
          </div>
          <div className="flex gap-x-3 justify-center">
            {[
              images.facebook,
              images.google,
              images.linkdin,
              images.xsocialmedia,
            ].map((icon, index) => (
              <div
                key={index}
                className="border-[#D0D5DD] border lg:w-[59px] lg:h-[59px] w-[40px] h-[40px] rounded-full flex justify-center items-center cursor-pointer"
              >
                <img
                  src={icon}
                  alt="social media"
                  className="lg:w-[36px] lg:h-[36px] w-[25px] h-[25px]"
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Signup;
