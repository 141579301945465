import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import bluemedal from "../../../Assets/Mychallenge/bluemedal-star.svg";
import greymedal from "../../../Assets/Mychallenge/greymedal.svg";
import StartYourChallenge from "../StartYourChallenge/StartYourChallenge";
import { PopupContext } from "../../../Contexts/PopupContext";
const NewChallenge = () => {
  const [activeTab, setActiveTab] = useState("newChallenge");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { isPopupOpen, closePopup, openPopup } = useContext(PopupContext);

  const handleTabClick = (tab, route) => {
    setActiveTab(tab);
    navigate(route);
  };

  return (
    <div className="border border-childBorder bg-background  rounded-2xl p-5 pb-28">
      <div className="flex justify-center mt-5">
        <div className="bg-[#F5F5F5] dark:bg-[#0D0D0D] w-[256px] h-[42px] rounded-xl flex items-center justify-center px-2">
          <div
            onClick={() =>
              handleTabClick("newChallenge", "/challenge/new-challenge")
            }
            className={`w-[127px] h-[30px] text-[13px] flex justify-center items-center cursor-pointer transition-all duration-200 ${
              activeTab === "newChallenge"
                ? "bg-white dark:text-white dark:bg-[#011845] text-[#6699FF] font-semibold rounded-lg"
                : "text-[#6C6C6C]"
            }`}
          >
            New Challenge
          </div>
          <div
            onClick={() =>
              handleTabClick("myChallenge", "/challenge/my-challenge")
            }
            className={`w-[127px] h-[30px] text-[13px] flex justify-center items-center cursor-pointer transition-all duration-200 ${
              activeTab === "myChallenge"
                ? "bg-white text-[#6699FF] font-semibold rounded-lg"
                : "text-[#6C6C6C]"
            }`}
          >
            My Challenge
          </div>
        </div>
      </div>

      <div className="font-semibold text-center text-[24px] lg:text-[34px] mt-5">
        Start A Challenge!
      </div>
      <div className="text-[#6C6C6C] text-center text-[14px] lg:text-[16px]">
        Select from the options below to get started
      </div>

      <div className="flex flex-col lg:flex-row justify-center items-center gap-5 mt-10 lg:mt-20 ">
        {/* Most Popular Card */}
        <div className="w-full lg:w-[363px]">
          <div className="bg-[#6699FF] rounded-t-2xl text-white font-semibold text-[16px] h-[48px] flex justify-center items-center">
            Most popular
          </div>

          <div
            className="border border-childBorder bg-background rounded-b-2xl  p-5 flex flex-col gap-y-5"
            style={{ boxShadow: "0px 2px 10px 0px rgba(76, 78, 100, 0.22)" }}
          >
            <div className="text-[20px] lg:text-[24px] font-semibold">
              <div className="flex gap-x-2 items-center justify-center">
                <div className="bg-[#E6EDFC] dark:bg-[#011845] dark:border-[#6699FF]   border-[#EAEAEC] border w-[50px] lg:w-[60px] h-[50px] lg:h-[60px] rounded-full flex justify-center items-center">
                  <img src={bluemedal} alt="" />
                </div>
                Build A Challenge
              </div>
            </div>
            <div className="flex flex-col gap-y-3">
              <div className="flex justify-between">
                <div className="text-[#6C6C6C] text-[14px] lg:text-[16px]">
                  Balance
                </div>
                <div className="font-extrabold text-[14px] lg:text-[16px]">
                  $5k - $200k
                </div>
              </div>
              <hr className="border border-childBorder" />
              <div className="flex justify-between">
                <div>
                  <div className="text-[#6C6C6C] font-medium text-[10px] lg:text-[12px]">
                    Evaluation
                  </div>
                  <div className="text-[14px] lg:text-[16px] font-semibold">
                    Instant - Triple
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-[#6C6C6C] font-medium text-[10px] lg:text-[12px]">
                    Time Limit
                  </div>
                  <div className="text-[14px] lg:text-[16px] font-semibold">
                    Unlimited
                  </div>
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <div className="text-[#6C6C6C] font-medium text-[10px] lg:text-[12px]">
                    Account
                  </div>
                  <div className="text-[14px] lg:text-[16px] font-semibold">
                    Standard / Aggressive
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-[#6C6C6C] font-medium text-[10px] lg:text-[12px]">
                    Profit Share
                  </div>
                  <div className="text-[14px] lg:text-[16px] font-semibold">
                    85%
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <button
                onClick={openPopup}
                // onClick={() => setIsOpen(true)}
                className="w-full lg:w-[299px] h-[44px] rounded-xl bg-[#6699FF] text-white text-[14px] lg:text-[16px] font-semibold"
              >
                Start Your Challenge
              </button>
            </div>
          </div>
        </div>
        {isPopupOpen && <StartYourChallenge onClose={closePopup} />}
        {/* {isOpen && <StartYourChallenge onClose={() => setIsOpen(false)} />} */}

        {/* Free Trial Card */}

        <div className="w-full lg:w-[363px]">
          <div className="lg:h-[48px] h-[0px]"></div>

          <div className="w-full lg:w-[363px]">
            <div
              className=" rounded-2xl border border-childBorder bg-background  p-5 flex flex-col gap-y-5"
              style={{ boxShadow: "0px 2px 10px 0px rgba(76, 78, 100, 0.22)" }}
            >
              <div className="text-[20px] lg:text-[24px] font-semibold">
                <div className="flex gap-x-2 items-center justify-center">
                  <div className="bg-[#EEEEEE]  bg-background border-[#EAEAEC] border w-[50px] lg:w-[60px] h-[50px] lg:h-[60px] rounded-full flex justify-center items-center">
                    <img src={greymedal} alt="" />
                  </div>
                  Free Trial
                </div>
              </div>
              <div className="flex flex-col gap-y-3">
                <div className="flex justify-between">
                  <div className="text-[#6C6C6C] text-[14px] lg:text-[16px]">
                    Balance
                  </div>
                  <div className="font-extrabold text-[14px] lg:text-[16px]">
                    $5k - $200k
                  </div>
                </div>
                <hr className="border border-childBorder" />
                <div className="flex justify-between">
                  <div>
                    <div className="text-[#6C6C6C] font-medium text-[10px] lg:text-[12px]">
                      Evaluation
                    </div>
                    <div className="text-[14px] lg:text-[16px] font-semibold">
                      Instant - Triple
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="text-[#6C6C6C] font-medium text-[10px] lg:text-[12px]">
                      Time Limit
                    </div>
                    <div className="text-[14px] lg:text-[16px] font-semibold">
                      Unlimited
                    </div>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div>
                    <div className="text-[#6C6C6C] font-medium text-[10px] lg:text-[12px]">
                      Account
                    </div>
                    <div className="text-[14px] lg:text-[16px] font-semibold">
                      Standard / Aggressive
                    </div>
                  </div>
                  <div className="text-right">
                    <div className="text-[#6C6C6C] font-medium text-[10px] lg:text-[12px]">
                      Profit Share
                    </div>
                    <div className="text-[14px] lg:text-[16px] font-semibold">
                      85%
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <button className="w-full lg:w-[299px] h-[44px] rounded-xl border border-childBorder bg-[#FAFCFD] dark:bg-[#1C1C1C]   text-[#6699FF] text-[14px] lg:text-[16px] font-semibold">
                  Try for Free
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewChallenge;
