import React from "react";
import more from "../../Assets/Challenge/more.svg";

import GradientAreaChart from "./Charts/GradientAreaChart";
import { ChevronDown } from "lucide-react";
const TradingOverview = () => {
  return (
    <div className="w-full bg-background rounded-2xl border-childBorder border p-5">
      <div className="flex lg:justify-between lg:flex-row flex-col gap-5">
        <div className="font-semibold ">Trading Overview</div>

        <div className="flex items-center gap-x-5">
          <div className="bg-[#FAFCFD] border border-childBorder dark:bg-[#1C1C1C] dark:text-white text-[#2197ED] text-[13px] lg:w-[120px] w-[300px] h-[30px] rounded-xl font-semibold flex justify-center items-center gap-x-1">
            This Week <ChevronDown size={20} />
          </div>
          <img src={more} alt="" />
        </div>
      </div>
      <div className="mt-5">
        <div>
          <div className="flex justify-around">
            <div className="w-[225px]">
              <div className="text-[#6C6C6C] text-[12px]">Gain</div>
              <div className="text-[#67CA24] text-[20px] font-semibold">
                +0.94%
              </div>
            </div>
            <div className="w-[225px]">
              <div className="text-[#6C6C6C] text-[12px]">Profit</div>
              <div className="text-[#67CA24] text-[20px] font-semibold">
                $879.00
              </div>
            </div>
            <div className="w-[225px]">
              <div className="text-[#6C6C6C] text-[12px]">PIPS</div>
              <div className="text-[#67CA24] text-[20px] font-semibold">
                +294.3
              </div>
            </div>
          </div>
          <div className=" flex  mt-2">
            <div className="w-full">
              <GradientAreaChart />
            </div>
            <div className="flex flex-col justify-around">
              <div>
                <div className="text-[#6C6C6C] text-[12px]">Win %</div>
                <div className="text-[20px] font-semibold">75%</div>
              </div>
              <div>
                <div className="text-[#6C6C6C] text-[12px]">Trades</div>
                <div className="text-[20px] font-semibold">4</div>
              </div>
              <div>
                <div className="text-[#6C6C6C] text-[12px]">Win %</div>
                <div className="text-[20px] font-semibold">75%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingOverview;
