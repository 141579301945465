import React from "react";
import images from "../../Assets/Assets";
import { ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";
const TradingActivity = () => {
  return (
    <div className="w-full  bg-background rounded-2xl border-childBorder border p-5">
      <div className="flex lg:justify-between lg:flex-row flex-col">
        <div className="font-semibold ">Trading Activity</div>

        <div className="flex items-center gap-x-5">
          <div className="bg-[#FAFCFD] border border-childBorder dark:bg-[#1C1C1C] dark:text-white text-[#6699FF] text-[13px] w-[159px] h-[38px] rounded-xl font-semibold flex justify-center items-center gap-x-1">
            Open Orders
            <ChevronDown size={20} />
          </div>
          <img src={images.more} alt="" />
        </div>
      </div>

      <div className="mt-5 w-full overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-white uppercase bg-[#6699FF] ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Open Date
              </th>
              <th scope="col" className="px-6 py-3">
                symbol
              </th>
              <th scope="col" className="px-6 py-3">
                action
              </th>
              <th scope="col" className="px-6 py-3">
                lots
              </th>
              <th scope="col" className="px-6 py-3">
                open price
              </th>
              <th scope="col" className="px-6 py-3">
                Pips
              </th>
              <th scope="col" className="px-6 py-3">
                Net profit
              </th>
              <th scope="col" className="px-6 py-3">
                Gain
              </th>
            </tr>
          </thead>
          <tbody className="dark:text-white">
            <tr className="">
              <td className="px-6 py-2">07.06.2023 04:35</td>
              <td className="px-6 py-2">EURUSD</td>
              <td className="px-6 py-2">
                <div className="w-[44px] h-[32px] dark:bg-[#011845] rounded-lg bg-[#F4FDEE] text-[#65D31D] font-semibold flex justify-center items-center">
                  BUY
                </div>
              </td>
              <td className="px-6 py-2">0.20</td>
              <td className="px-6 py-2">1.08471</td>
              <td className="px-6 py-2 text-[#67CA24]">16.7</td>
              <td className="px-6 py-2 text-[#67CA24]">52.00</td>
              <td className="px-6 py-2 text-[#67CA24]">0.03%</td>
            </tr>
            <tr className=" bg-tableBackground">
              <td className="px-6 py-2">07.06.2023 04:35</td>
              <td className="px-6 py-2">EURUSD</td>
              <td className="px-6 py-2">
                <div className="w-[44px] h-[32px] dark:bg-[#011845] rounded-lg bg-[#FFEAE9] text-[#FF4D49] font-semibold flex justify-center items-center">
                  SELL
                </div>
              </td>
              <td className="px-6 py-2">0.20</td>
              <td className="px-6 py-2">1.08471</td>
              <td className="px-6 py-2 text-[#67CA24]">16.7</td>
              <td className="px-6 py-2 text-[#67CA24]">52.00</td>
              <td className="px-6 py-2 text-[#67CA24]">0.03%</td>
            </tr>
            <tr className=" ">
              <td className="px-6 py-2">07.06.2023 04:35</td>
              <td className="px-6 py-2">EURUSD</td>
              <td className="px-6 py-2">
                <div className="w-[44px] h-[32px] dark:bg-[#011845] rounded-lg bg-[#F4FDEE] text-[#65D31D] font-semibold flex justify-center items-center">
                  BUY
                </div>
              </td>
              <td className="px-6 py-2">0.20</td>
              <td className="px-6 py-2">1.08471</td>
              <td className="px-6 py-2 text-[#67CA24]">16.7</td>
              <td className="px-6 py-2 text-[#67CA24]">52.00</td>
              <td className="px-6 py-2 text-[#67CA24]">0.03%</td>
            </tr>
            <tr className=" bg-tableBackground">
              <td className="px-6 py-2">07.06.2023 04:35</td>
              <td className="px-6 py-2">EURUSD</td>
              <td className="px-6 py-2">
                <div className="w-[44px] h-[32px] dark:bg-[#011845] rounded-lg bg-[#FFEAE9] text-[#FF4D49] font-semibold flex justify-center items-center">
                  SELL
                </div>
              </td>
              <td className="px-6 py-2">0.20</td>
              <td className="px-6 py-2">1.08471</td>
              <td className="px-6 py-2 text-[#E54542]">-16.7</td>
              <td className="px-6 py-2 text-[#E54542]">-52.00</td>
              <td className="px-6 py-2 text-[#E54542]">-0.03%</td>
            </tr>
          </tbody>
        </table>
      </div>
      {/*pagination*/}

      <div className="flex justify-end mt-2">
        <div className="flex items-center gap-x-5">
          <div className="text-[14px]">1 0f 13</div>

          <div className="flex gap-x-5 items-center">
            <ChevronLeft size={20} />
            <ChevronRight size={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingActivity;
