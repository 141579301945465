import React from "react";
import {
  BuyTicket,
  Crypto,
  DayTrader,
  DollarCircle,
  Forex,
  Friend,
  Global,
  HighFrequency,
  MarketExposer,
  MedalStar,
  ProfitWizard,
  RiskGuru,
  RiskTaker,
  Star,
  SteadyHead,
} from "../../Assets/Achievements/SvgAchievements";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
const Achievements = () => {
  const BlueProgressBar = ({ progress }) => {
    return (
      <div className="w-full bg-[#EDF4FF] dark:bg-[#011845] rounded-full h-2 mt-2">
        <div
          className="bg-[#6699FF] h-2 rounded-l-full"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    );
  };
  return (
    <>
      <div>
        <div className="text-[34px] font-semibold text-center">
          Achievements{" "}
        </div>
        <div className="text-[#6C6C6C] lg:text-base text-[14px] text-center">
          Unlock personalized certificates to showcase your achievements.
        </div>

        <div className="mt-10">
          {/*This is for large screen*/}
          <div className="text-[24px] font-semibold">Daily Achievements</div>
          <div className="mt-2 lg:flex lg:flex-wrap lg:gap-5 hidden sm:flex">
            <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">
                    The Challenger
                  </span>
                  <span className="text-[#6C6C6C] text-sm">
                    Start 10 challenges
                  </span>
                </div>
                <div>
                  <Star />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">2/10</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>5</div>

                  <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={20} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>20%</div>
                </div>
              </div>
            </div>
            <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">
                    Contest Conqueror
                  </span>
                  <span className="text-[#6C6C6C] text-sm">
                    Start your first competition
                  </span>
                </div>
                <div>
                  <MedalStar />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">0/1</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>1</div>

                  <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={20} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>20%</div>
                </div>
              </div>
            </div>
            <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">Pay Day</span>
                  <span className="text-[#6C6C6C] text-sm">
                    Reach a total of $100 in payouts
                  </span>
                </div>
                <div>
                  <DollarCircle />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">52/100</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>15</div>

                  <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={20} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>20%</div>
                </div>
              </div>
            </div>
          </div>

          {/*This is for Mobile screen*/}
          <div className="block sm:hidden mt-2">
            <Swiper
              modules={[Pagination]}
              pagination={{
                clickable: true,
                el: ".custom-pagination-1",
              }}
              spaceBetween={30}
              slidesPerView="auto"
              loop={true}
              centeredSlides={true}
            >
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        The Challenger
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Start 10 challenges
                      </span>
                    </div>
                    <div>
                      <Star />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">2/10</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>5</div>

                      <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={20} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>20%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        Contest Conqueror
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Start your first competition
                      </span>
                    </div>
                    <div>
                      <MedalStar />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">0/1</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>1</div>

                      <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={20} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>20%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">Pay Day</span>
                      <span className="text-[#6C6C6C] text-sm">
                        Reach a total of $100 in payouts
                      </span>
                    </div>
                    <div>
                      <DollarCircle />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">52/100</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>15</div>

                      <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={20} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>20%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            {/* Custom Pagination Dots */}
            <div className="custom-pagination-1 flex justify-center mt-6"></div>
          </div>
        </div>

        <div className="mt-5">
          <div className="text-[24px] font-semibold">Weekly Achievements</div>
          {/*This is for large screen*/}
          <div className="mt-2 lg:flex lg:flex-wrap lg:gap-5 hidden sm:flex">
            <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">
                    Referral Ninja
                  </span>
                  <span className="text-[#6C6C6C] text-sm">
                    Refer 10 friends to Propmode
                  </span>
                </div>
                <div>
                  <Friend />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">9/10</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>5</div>

                  <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={90} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>20%</div>
                </div>
              </div>
            </div>
            <div className="bg-background border-childBorder border rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">
                    High Frequency
                  </span>
                  <span className="text-[#6C6C6C] text-sm">
                    Place a total of 1,000 trades
                  </span>
                </div>
                <div>
                  <HighFrequency />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">150/1,000</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>1</div>

                  <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={15} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>15%</div>
                </div>
              </div>
            </div>
            <div className="bg-background border-childBorder border rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">Big Ticket</span>
                  <span className="text-[#6C6C6C] text-sm">
                    Reach $10B in total trade volume
                  </span>
                </div>
                <div>
                  <BuyTicket />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">6/10</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>10</div>

                  <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={20} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>20%</div>
                </div>
              </div>
            </div>

            <div className="bg-background border-childBorder border  rounded-2xl p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">Steady Hand</span>
                  <span className="text-[#6C6C6C] text-sm">
                    Maintain a positive trading balance for 30 consecutive days
                  </span>
                </div>
                <div>
                  <SteadyHead />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">26/30</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>5</div>

                  <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={90} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>20%</div>
                </div>
              </div>
            </div>
            <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">
                    Crypto Crusader
                  </span>
                  <span className="text-[#6C6C6C] text-sm">
                    Complete 20 cryptocurrency trades
                  </span>
                </div>
                <div>
                  <Crypto />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">1/20</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>1</div>

                  <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={15} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>15%</div>
                </div>
              </div>
            </div>
            <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">Risk Taker</span>
                  <span className="text-[#6C6C6C] text-sm">
                    Execute a trade with a risk-to-reward ratio of 1:5
                  </span>
                </div>
                <div>
                  <RiskTaker />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">0/1</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>10</div>

                  <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={20} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>20%</div>
                </div>
              </div>
            </div>
          </div>
          {/*This is for Mobile screen*/}
          <div className=" block sm:hidden mt-2">
            <Swiper
              modules={[Pagination]}
              pagination={{
                clickable: true,
                el: ".custom-pagination-2",
              }}
              spaceBetween={30}
              slidesPerView="auto"
              loop={true}
              centeredSlides={true}
            >
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        Referral Ninja
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Refer 10 friends to Propmode
                      </span>
                    </div>
                    <div>
                      <Friend />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">9/10</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>5</div>

                      <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={90} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>20%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-background border-childBorder border rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        High Frequency
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Place a total of 1,000 trades
                      </span>
                    </div>
                    <div>
                      <HighFrequency />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">150/1,000</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>1</div>

                      <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={15} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>15%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-background border-childBorder border rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        Big Ticket
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Reach $10B in total trade volume
                      </span>
                    </div>
                    <div>
                      <BuyTicket />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">6/10</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>10</div>

                      <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={20} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>20%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        Steady Hand
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Maintain a positive trading balance for 30 consecutive
                        days
                      </span>
                    </div>
                    <div>
                      <SteadyHead />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">26/30</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>5</div>

                      <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={90} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>20%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        Crypto Crusader
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Complete 20 cryptocurrency trades
                      </span>
                    </div>
                    <div>
                      <Crypto />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">1/20</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>1</div>

                      <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={15} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>15%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        Risk Taker
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Execute a trade with a risk-to-reward ratio of 1:5
                      </span>
                    </div>
                    <div>
                      <RiskTaker />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">0/1</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>10</div>

                      <div className="text-[#6C6C6C] ml-1  text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={20} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>20%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            {/* Custom Pagination Dots */}
            <div className="custom-pagination-2 flex justify-center mt-6"></div>
          </div>
        </div>
        <div className="mt-5">
          <div className="text-[24px] font-semibold">Monthly Achievements</div>
          {/*This is for large screen*/}
          <div className="mt-2 lg:flex lg:flex-wrap lg:gap-5  hidden sm:flex">
            <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">Forex Prodigy</span>
                  <span className="text-[#6C6C6C] text-sm">
                    Achieve a 100-pip profit in a single day
                  </span>
                </div>
                <div>
                  <Forex />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">0/1</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>5</div>

                  <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={90} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>20%</div>
                </div>
              </div>
            </div>
            <div className="bg-background border-childBorder border  rounded-2xl p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">
                    Global Navigator
                  </span>
                  <span className="text-[#6C6C6C] text-sm">
                    Trade in every major global market within a year
                  </span>
                </div>
                <div>
                  <Global />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">0/1</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>1</div>

                  <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={15} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>15%</div>
                </div>
              </div>
            </div>
            <div className="bg-background border-childBorder border  rounded-2xl p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">Risk Guru</span>
                  <span className="text-[#6C6C6C] text-sm">
                    Maintain a win-loss ratio of 3:1 or higher for 30 days
                  </span>
                </div>
                <div>
                  <RiskGuru />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">6/10</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>10</div>

                  <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={20} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>20%</div>
                </div>
              </div>
            </div>
            <div className="bg-background border-childBorder border  rounded-2xl p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">Day Trader</span>
                  <span className="text-[#6C6C6C] text-sm">
                    Execute 5 trades in a single day
                  </span>
                </div>
                <div>
                  <DayTrader />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">0/1</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>5</div>

                  <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={90} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>20%</div>
                </div>
              </div>
            </div>
            <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">
                    Market Explorer
                  </span>
                  <span className="text-[#6C6C6C] text-sm">
                    Trade in 10 different symbols
                  </span>
                </div>
                <div>
                  <MarketExposer />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">2/10</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>1</div>

                  <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={15} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>15%</div>
                </div>
              </div>
            </div>
            <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
              <div className="flex justify-between">
                <div className="flex flex-col">
                  <span className="font-semibold text-base">Profit Wizard</span>
                  <span className="text-[#6C6C6C] text-sm">
                    Achieve a 50% return on investment within a month
                  </span>
                </div>
                <div>
                  <ProfitWizard />
                </div>
              </div>
              <div className="flex justify-between">
                <div className="font-semibold text-[24px]">0/1</div>
                <div className="font-semibold text-[24px] flex items-center ">
                  <div>10</div>

                  <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                    point
                  </div>
                </div>
              </div>
              <div>
                <BlueProgressBar progress={20} />
                <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                  <div>Progress</div>
                  <div>20%</div>
                </div>
              </div>
            </div>
          </div>
          {/*This is for Mobile screen*/}
          <div className=" block sm:hidden mt-2">
            <Swiper
              modules={[Pagination]}
              pagination={{
                clickable: true,
                el: ".custom-pagination-3",
              }}
              spaceBetween={30}
              slidesPerView="auto"
              loop={true}
              centeredSlides={true}
            >
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        Forex Prodigy
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Achieve a 100-pip profit in a single day
                      </span>
                    </div>
                    <div>
                      <Forex />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">0/1</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>5</div>

                      <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={90} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>20%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        Global Navigator
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Trade in every major global market within a year
                      </span>
                    </div>
                    <div>
                      <Global />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">0/1</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>1</div>

                      <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={15} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>15%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">Risk Guru</span>
                      <span className="text-[#6C6C6C] text-sm">
                        Maintain a win-loss ratio of 3:1 or higher for 30 days
                      </span>
                    </div>
                    <div>
                      <RiskGuru />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">6/10</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>10</div>

                      <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={20} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>20%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        Day Trader
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Execute 5 trades in a single day
                      </span>
                    </div>
                    <div>
                      <DayTrader />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">0/1</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>5</div>

                      <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={90} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>20%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        Market Explorer
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Trade in 10 different symbols
                      </span>
                    </div>
                    <div>
                      <MarketExposer />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">2/10</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>1</div>

                      <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={15} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>15%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="bg-background border-childBorder border  rounded-2xl  p-5 w-full lg:w-[380px] flex flex-col gap-y-2 ">
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <span className="font-semibold text-base">
                        Profit Wizard
                      </span>
                      <span className="text-[#6C6C6C] text-sm">
                        Achieve a 50% return on investment within a month
                      </span>
                    </div>
                    <div>
                      <ProfitWizard />
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold text-[24px]">0/1</div>
                    <div className="font-semibold text-[24px] flex items-center ">
                      <div>10</div>

                      <div className="text-[#6C6C6C] ml-1 text-sm font-medium">
                        point
                      </div>
                    </div>
                  </div>
                  <div>
                    <BlueProgressBar progress={20} />
                    <div className="text-[#6C6C6C] text-[14px] font-medium flex justify-between mt-2">
                      <div>Progress</div>
                      <div>20%</div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            {/* Custom Pagination Dots */}
            <div className="custom-pagination-3 flex justify-center mt-6"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Achievements;
