import React, { useState } from "react";
import Profitcards from "./Profitcards";
import Balance from "./Balance";
import PopularAssets from "./PopularAssets";
import MonthlyAnalytics from "./MonthlyAnalytics";
import AdvancedStatistics from "./AdvancedStatistics";
import TradingOverview from "./TradingOverview";
import TradingActivity from "./TradingActivity";
import ChallengeGraph from "./ChallengeGraph";
import RequestPayoutPopup from "./Popup/RequestPayoutPopup";
import CredentialsPopup from "./Popup/CredentialsPopup";

const Challenge = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isCredentialPopup, setCredentialPopup] = useState(false);
  // Function to close the Credentials Popup
  const closeCredentialPopup = () => {
    setCredentialPopup(false);
  };
  return (
    <div className="flex flex-col gap-y-3">
      <div className="lg:flex lg:justify-end lg:space-x-3">
        <div className="flex space-x-3">
          <button className="lg:w-[97px] dark:bg-[#121212] dark:text-[#6D788D] border border-childBorder w-full h-[44px] rounded-lg text-[#6699FF] font-semibold bg-white">
            Rules
          </button>
          <button
            onClick={() => setCredentialPopup(true)}
            className="lg:w-[146px] w-full h-[44px] dark:bg-[#121212] dark:text-[#6D788D] border border-childBorder rounded-lg text-[#6699FF] font-semibold bg-white"
          >
            Credentials
          </button>
        </div>
        <div className="lg:mt-0 mt-2">
          <button
            onClick={() => setIsOpen(true)}
            className="lg:w-[180px] w-full h-[44px]  rounded-lg bg-[#6699FF] font-semibold text-white"
          >
            Request Payout
          </button>
        </div>
      </div>
      {isOpen && (
        <RequestPayoutPopup onClose={() => setIsOpen(false)} isOpen={isOpen} />
      )}
      {isCredentialPopup && (
        <CredentialsPopup
          onClose={closeCredentialPopup}
          isOpen={isCredentialPopup}
        />
      )}
      <Profitcards />
      <Balance />
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
        <PopularAssets />
        <MonthlyAnalytics />
      </div>
      <AdvancedStatistics />
      <div className="flex lg:justify-between lg:flex-row flex-col gap-4">
        <ChallengeGraph />
         <TradingOverview />
      </div>
      <TradingActivity />
    </div>
  );
};

export default Challenge;
