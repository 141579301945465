import { Eye, EyeOff } from "lucide-react";
import React, { useState } from "react";

const ChangePassword = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  return (
    <div>
      <div className="font-semibold text-[20px]">Change Password</div>
      <div className="mt-2 flex flex-col gap-4">
        <div className="relative">
          <input
            type={showCurrentPassword ? "text" : "password"}
            className="h-[56px] w-full rounded-xl dark:bg-[#121212] dark:border-[#424346] border pl-5 pr-12 outline-none placeholder-style"
            placeholder="Current Password"
          />
          <div
            className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={() => setShowCurrentPassword(!showCurrentPassword)}
          >
            {showCurrentPassword ? (
              <EyeOff className="text-gray-500" />
            ) : (
              <Eye className="text-gray-500" />
            )}
          </div>
        </div>
        <div className="relative">
          <input
            type={showNewPassword ? "text" : "password"}
            className="h-[56px] w-full rounded-xl dark:bg-[#121212] dark:border-[#424346] border pl-5 pr-12 outline-none placeholder-style"
            placeholder="New Password"
          />
          <div
            className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={() => setShowNewPassword(!showNewPassword)}
          >
            {showNewPassword ? (
              <EyeOff className="text-gray-500" />
            ) : (
              <Eye className="text-gray-500" />
            )}
          </div>
        </div>
        <div className="relative">
          <input
            type={showConfirmPassword ? "text" : "password"}
            className="h-[56px] w-full rounded-xl dark:bg-[#121212] dark:border-[#424346] border pl-5 pr-12 outline-none placeholder-style"
            placeholder="Confirm New Password"
          />
          <div
            className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            {showConfirmPassword ? (
              <EyeOff className="text-gray-500" />
            ) : (
              <Eye className="text-gray-500" />
            )}
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="font-semibold">Password Requirements:</div>
        <ul className="text-[#6C6C6C] list-disc pl-4 leading-7">
          <li>Minimum 8 characters long - the more, the better</li>
          <li>At least one lowercase character</li>
          <li>At least one number, symbol, or whitespace character</li>
        </ul>
      </div>
      <hr className="my-5 dark:border-[#424346]" />
      <div className="flex justify-end gap-3">
        <button
          type="button"
          className="bg-[#FAFCFD] dark:bg-[#121212] dark:border-[#424346]   border-[#EAEAEC] border w-full lg:w-[122px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
        >
          Cancel
        </button>
        <button
          type="button"
          className="bg-[#6699FF] w-full lg:w-[146px] h-[38px] rounded-lg text-white font-semibold"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default ChangePassword;
