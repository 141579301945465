import React, { useState, useEffect } from "react";
import { ChevronRight, ChevronLeft } from "lucide-react";
import ChangePassword from "./ChangePassword/ChangePassword";
import IdentityVerification from "./IdentityVerification/IdentityVerification";
import TwoFactorAuth from "./TwoFactorAuth/TwoFactorAuth";
import RecentDevices from "./RecentDevices/RecentDevices";

const Security = () => {
  const [activeTab, setActiveTab] = useState("ChangePassword");
  const [isDetailView, setIsDetailView] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Detect if the screen is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // Consider mobile if width < 1024px
    };

    handleResize(); // Check initially
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderComponent = () => {
    switch (activeTab) {
      case "ChangePassword":
        return <ChangePassword />;
      case "IdentityVerification":
        return <IdentityVerification />;
      case "TwoFactorAuthentication":
        return <TwoFactorAuth />;
      case "RecentDevices":
        return <RecentDevices />;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col lg:flex-row gap-5">
      {/* Sidebar */}
      {(!isMobile || !isDetailView) && (
        <div className="bg-background border-childBorder border rounded-2xl w-full lg:w-[356px] h-auto lg:h-[280px] p-5">
          <div className="text-[16px] font-semibold">Security</div>
          <ul className="mt-2">
            {[
              { id: "ChangePassword", label: "Change Password" },
              { id: "IdentityVerification", label: "Identity Verification" },
              {
                id: "TwoFactorAuthentication",
                label: "Two-Factor Authentication",
              },
              { id: "RecentDevices", label: "Recent Devices" },
            ].map((item) => (
              <li
                key={item.id}
                className={`flex items-center justify-between py-3 px-3 rounded-lg font-semibold cursor-pointer ${
                  activeTab === item.id
                    ? "bg-[#EDF4FF] dark:bg-[#011845] text-[#6699FF]"
                    : "text-[#6C6C6C]"
                }`}
                onClick={() => {
                  setActiveTab(item.id);
                  if (isMobile) setIsDetailView(true); // Only toggle detail view on mobile
                }}
              >
                {item.label}
                {isMobile && <ChevronRight size={16} />}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Detail View */}
      {(!isMobile || isDetailView) && (
        <div className="bg-background border-childBorder border  rounded-2xl w-full p-5">
          {/* Back Button for Mobile */}
          {isMobile && isDetailView && (
            <button
              className="flex items-center gap-2 mb-4 text-[#6699FF] font-semibold"
              onClick={() => setIsDetailView(false)}
            >
              <ChevronLeft size={16} />
              Back
            </button>
          )}
          {renderComponent()}
        </div>
      )}
    </div>
  );
};

export default Security;
