import React from "react";

import ActiveChart from "./Charts/ActiveChart";
const ChallengeGraph = () => {
  return (
    <div className="lg:w-[368px] bg-background rounded-2xl border-childBorder border p-5">
      <div className="font-semibold">New Challenge</div>
      <div className="text-[#67CA24] text-[24px] font-semibold mt-2">
        Active
      </div>
      <ActiveChart />

      <div>
        <div className="flex justify-between">
          <div className="w-[106px] h-[40px] flex flex-col gap-y-2">
            <div className="text-[#6C6C6C] text-[12px]">Leverage</div>
            <div className="font-semibold">128</div>
          </div>
          <div className="w-[106px] h-[40px] flex flex-col gap-y-2 text-center">
            <div className="text-[#6C6C6C] text-[12px]">Number</div>
            <div className="font-semibold">1622</div>
          </div>
          <div className="w-[106px] h-[40px] flex flex-col gap-y-2  text-right">
            <div className="text-[#6C6C6C] text-[12px]">Balance</div>
            <div className="font-semibold">$10,000</div>
          </div>
        </div>
        <div className="flex justify-between mt-4">
          <div className="w-[106px] h-[40px] flex flex-col gap-y-2">
            <div className="text-[#6C6C6C] text-[12px]">Stage</div>
            <div className="font-semibold">Funded</div>
          </div>
          <div className="w-[106px] h-[40px] flex flex-col gap-y-2 text-center">
            <div className="text-[#6C6C6C] text-[12px]">Evaluation </div>
            <div className="font-semibold">Double </div>
          </div>
          <div className="w-[106px] h-[40px] flex flex-col gap-y-2  text-right">
            <div className="text-[#6C6C6C] text-[12px]">Account</div>
            <div className="font-semibold">Aggressive</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengeGraph;
