import React, { useState } from "react";
import SmsAuthPopup from "./TwoFactorAuthPopUp/SmsAuthPopup";
import QrCodePopUp from "./TwoFactorAuthPopUp/QrCodePopUp";
const TwoFactorAuth = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => setIsPopupOpen(true);
  const closePopup = () => setIsPopupOpen(false);

  const [isQrPopupOpen, setQrIsPopupOpen] = useState(false);
  const openQrPopup = () => setQrIsPopupOpen(true);
  const closeQrPopup = () => setQrIsPopupOpen(false);
  return (
    <div>
      <div className="font-semibold text-[20px]">Two-Factor Authentication</div>
      <div className="text-[#6C6C6C] mt-2">
        Two-factor authentication adds an additional layer of security to your
        account.
        <span className="text-[#6699FF]">Learn more</span>
      </div>

      <div className="mt-5 rounded-2xl border-childBorder border p-5">
        <div className="flex lg:flex-row flex-col lg:items-center gap-y-2 lg:gap-5">
          <div className="text-[16px] font-semibold">Authenticator App </div>
          <div className="bg-[#EFEFF0] dark:bg-[#232325] dark:text-[#A4A4A4]  font-semibold flex justify-center items-center w-[160px] h-[32px] rounded-2xl border-[#6C6C6C] border text-[#6C6C6C]">
            NOT Configured
          </div>
        </div>
        <div className="text-[#6C6C6C] font-normal mt-2">
          Examples: Google Authenticator, Authy, etc.
        </div>
        <hr className="my-2 border-childBorder" />
        <div className="flex justify-end">
          <button
            onClick={openQrPopup}
            type="button"
            className="bg-[#FAFCFD] dark:bg-[#1C1C1C] border-childBorder border w-full lg:w-[115px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
          >
            Configure
          </button>
        </div>
        {isQrPopupOpen && (
          <QrCodePopUp onClose={closeQrPopup} isOpen={isQrPopupOpen} />
        )}
      </div>
      <div className="mt-5 rounded-2xl border-childBorder border p-5">
        <div className="flex lg:flex-row flex-col lg:items-center gap-y-2 lg:gap-5">
          <div className="text-[16px] font-semibold">SMS Authentication </div>
          <div className="bg-[#EFEFF0] dark:bg-[#232325] dark:text-[#A4A4A4] font-semibold flex justify-center items-center w-[160px] h-[32px] rounded-2xl border-[#6C6C6C] border text-[#6C6C6C]">
            NOT Configured
          </div>
        </div>
        <div className="text-[#6C6C6C] font-normal mt-2">
          Get a code sent to your mobile number.
        </div>
        <hr className="my-2 border-childBorder" />
        <div className="flex justify-end">
          <button
            onClick={openPopup}
            type="button"
            className="bg-[#FAFCFD] dark:bg-[#1C1C1C] border-childBorder border w-full lg:w-[115px] h-[38px] rounded-lg text-[#6699FF] font-semibold"
          >
            Configure
          </button>
        </div>
      </div>
      {/* SmsAuthPopup */}
      <SmsAuthPopup isOpen={isPopupOpen} onClose={closePopup} />
    </div>
  );
};

export default TwoFactorAuth;
