export const Flag = () => {
  return (
    <>
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.017 9.27484L11.0003 8.25817C10.7587 8.04984 10.617 7.7415 10.6087 7.39984C10.592 7.02484 10.742 6.64984 11.017 6.37484L12.017 5.37484C12.8837 4.50817 13.2087 3.67484 12.9337 3.0165C12.667 2.3665 11.842 2.00817 10.6253 2.00817H1.91699V1.2915C1.91699 0.949837 1.63366 0.666504 1.29199 0.666504C0.950326 0.666504 0.666992 0.949837 0.666992 1.2915V16.7082C0.666992 17.0498 0.950326 17.3332 1.29199 17.3332C1.63366 17.3332 1.91699 17.0498 1.91699 16.7082V12.6415H10.6253C11.8253 12.6415 12.6337 12.2748 12.9087 11.6165C13.1837 10.9582 12.867 10.1332 12.017 9.27484Z"
          fill="#FDB528"
        />
      </svg>
    </>
  );
};

<svg
  width="12"
  height="22"
  viewBox="0 0 12 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M8.97035 0.980111L4.15535 5.79511L1.20035 8.73511C-0.0446484 9.98011 -0.0446484 12.0051 1.20035 13.2501L8.97035 21.0201C9.99035 22.0401 11.7304 21.3051 11.7304 19.8801V11.4651V2.12011C11.7304 0.680111 9.99035 -0.039889 8.97035 0.980111Z"
    fill="#6C6C6C"
  />
</svg>;

export const Left = () => {
  return (
    <>
      <svg
        width="12"
        height="22"
        viewBox="0 0 12 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.97035 0.980111L4.15535 5.79511L1.20035 8.73511C-0.0446484 9.98011 -0.0446484 12.0051 1.20035 13.2501L8.97035 21.0201C9.99035 22.0401 11.7304 21.3051 11.7304 19.8801V11.4651V2.12011C11.7304 0.680111 9.99035 -0.039889 8.97035 0.980111Z"
          fill="#6C6C6C"
        />
      </svg>
    </>
  );
};

export const Right = () => {
  return (
    <>
      <svg
        width="12"
        height="22"
        viewBox="0 0 12 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.7995 8.73489L7.84453 5.77989L3.02953 0.96489C2.00953 -0.0401096 0.269531 0.67989 0.269531 2.11989V11.4649V19.8799C0.269531 21.3199 2.00953 22.0399 3.02953 21.0199L10.7995 13.2499C12.0445 12.0199 12.0445 9.97989 10.7995 8.73489Z"
          fill="#6C6C6C"
        />
      </svg>
    </>
  );
};

export const Dropdown = () => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.4401 6.13477H8.76756H4.56006C3.84006 6.13477 3.48006 7.00477 3.99006 7.51477L7.87506 11.3998C8.49756 12.0223 9.51006 12.0223 10.1326 11.3998L11.6101 9.92227L14.0176 7.51477C14.5201 7.00477 14.1601 6.13477 13.4401 6.13477Z"
          fill="#6699FF"
        />
      </svg>
    </>
  );
};

export const Download = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 14.2506V11.25C11 10.5639 10.4361 10 9.75 10C9.06386 10 8.5 10.5639 8.5 11.25V14.2332C8.01226 13.8754 7.3164 13.9165 6.87645 14.3564C6.39118 14.8417 6.39118 15.6383 6.87645 16.1236L8.8417 18.0888C8.87235 18.1271 8.90372 18.1549 8.92687 18.173C9.01348 18.254 9.13478 18.3452 9.27943 18.4024C9.4272 18.4662 9.5822 18.5 9.75 18.5C9.91461 18.5 10.0669 18.4675 10.2121 18.406C10.3786 18.3457 10.5244 18.2427 10.6336 18.1336L12.6336 16.1336C13.1188 15.6483 13.1188 14.8517 12.6336 14.3664C12.1906 13.9235 11.4882 13.8849 11 14.2506ZM8.97637 18.2072C8.97691 18.2075 8.97597 18.207 8.97413 18.2058L8.97505 18.2064L8.97594 18.2069L8.97621 18.2071L8.97637 18.2072ZM17.61 10.69H20.5C20.7739 10.69 21 10.9161 21 11.19V16.43C21 18.0923 20.4239 19.3476 19.5277 20.1893C18.6266 21.0356 17.3629 21.5 15.93 21.5H8.07C6.63711 21.5 5.37343 21.0356 4.4723 20.1893C3.57612 19.3476 3 18.0923 3 16.43V7.57C3 5.90766 3.57612 4.65241 4.4723 3.81071C5.37343 2.96436 6.63711 2.5 8.07 2.5H12.31C12.5839 2.5 12.81 2.72614 12.81 3V5.89C12.81 8.53614 14.9639 10.69 17.61 10.69Z"
          fill="#6699FF"
          stroke="#6699FF"
        />
        <path
          d="M17.435 8.31002L17.435 8.30999H17.4297C16.1826 8.30999 15.1797 7.31077 15.1797 6.13999V2.64999C15.1797 2.61542 15.1892 2.59388 15.2009 2.57784C15.2145 2.55924 15.2373 2.54025 15.269 2.52778C15.3323 2.50287 15.3962 2.51366 15.4461 2.56355C16.4446 3.562 17.9435 5.07454 19.2557 6.39873C19.8876 7.03643 20.4763 7.63045 20.9449 8.10232L20.9461 8.10355C20.972 8.12937 20.9791 8.15048 20.9815 8.16561C20.9845 8.18366 20.9825 8.20721 20.9712 8.23266C20.9492 8.28243 20.9027 8.31999 20.8297 8.31999C19.6987 8.31999 18.3817 8.31999 17.435 8.31002Z"
          stroke="#6699FF"
        />
      </svg>
    </>
  );
};

export const WorldSearch = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22"
          stroke="#9E9FAB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.99961 3H8.99961C7.04961 8.84 7.04961 15.16 8.99961 21H7.99961"
          stroke="#9E9FAB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15 3C15.97 5.92 16.46 8.96 16.46 12"
          stroke="#9E9FAB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3 16V15C5.92 15.97 8.96 16.46 12 16.46"
          stroke="#9E9FAB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001"
          stroke="#9E9FAB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.2 21.4C19.9673 21.4 21.4 19.9673 21.4 18.2C21.4 16.4327 19.9673 15 18.2 15C16.4327 15 15 16.4327 15 18.2C15 19.9673 16.4327 21.4 18.2 21.4Z"
          stroke="#9E9FAB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22 22L21 21"
          stroke="#9E9FAB"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
