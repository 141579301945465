import React from "react";

const Billing = () => {
  return (
    <div className="w-full  mt-4  rounded-2xl bg-background border-childBorder border p-5">
      <div className="">
        <div className="font-semibold text-[20px] ">Payment History</div>
      </div>

      <div className="mt-5 overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead className="text-xs text-white uppercase bg-[#6699FF] ">
            <tr>
              <th scope="col" className="px-6 py-4">
                Account No.
              </th>
              <th scope="col" className="px-6 py-4">
                TYPE
              </th>
              <th scope="col" className="px-6 py-4">
                ISSUED DATE
              </th>
              <th scope="col" className="px-6 py-4">
                STATUS
              </th>
              <th scope="col" className="px-6 py-4">
                TOTAL
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-white dark:bg-[#121212]">
              <td className=" lg:whitespace-nowrap px-6 py-4">17372</td>
              <td className="lg:whitespace-nowrap px-6 py-4 dark:text-white font-semibold text-black">
                Profit Share
              </td>
              <td className="lg:whitespace-nowrap px-6 py-4">06 Jul 2023</td>
              <td className=" lg:whitespace-nowrap px-6 py-4 ">
                <div className="bg-[#fdf2dd] flex justify-center dark:bg-[#2D2600] items-center font-semibold border-[#FDB528] border text-[#FDB528] w-[83px] h-[24px] rounded-2xl">
                  Pending
                </div>
              </td>
              <td className=" lg:whitespace-nowrap px-6 py-4 dark:text-white font-semibold text-black">
                $2,500.00 USD
              </td>
            </tr>
            <tr className="bg-[#F6F8FE]  dark:bg-[#252525] ">
              <td className="px-6 py-4">17372</td>
              <td className="px-6 py-4 font-semibold dark:text-white text-black">
                Profit Share
              </td>
              <td className="px-6 py-4">06 Jul 2023</td>
              <td className="px-6 py-4 ">
                <div className="bg-[#F4FDEE] flex justify-center items-center dark:bg-[#1a253c] font-semibold border-[#67CA24] border text-[#67CA24] w-[83px] h-[24px] rounded-2xl">
                  Complete
                </div>
              </td>
              <td className="px-6 py-4 font-semibold dark:text-white text-black">
                $2,500.00 USD
              </td>
            </tr>
            <tr className="bg-white dark:bg-[#121212]">
              <td className="px-6 py-4">17372</td>
              <td className="px-6 py-4 font-semibold dark:text-white text-black">
                Profit Share
              </td>
              <td className="px-6 py-4">06 Jul 2023</td>
              <td className="px-6 py-4 ">
                <div className="bg-[#FFEAE9] flex  dark:bg-[#011845] justify-center items-center font-semibold border-[#FF4D49] border text-[#FF4D49] w-[83px] h-[24px] rounded-2xl">
                  Declined
                </div>
              </td>
              <td className="px-6 py-4 font-semibold dark:text-white text-black">
                $2,500.00 USD
              </td>
            </tr>
            <tr className="bg-[#F6F8FE]  dark:bg-[#252525] ">
              <td className="px-6 py-4">17372</td>
              <td className="px-6 py-4 font-semibold dark:text-white text-black">
                Profit Share
              </td>
              <td className="px-6 py-4">06 Jul 2023</td>
              <td className="px-6 py-4 ">
                <div className="bg-[#F4FDEE] flex justify-center dark:bg-[#011845] items-center font-semibold border-[#67CA24] border text-[#67CA24] w-[83px] h-[24px] rounded-2xl">
                  Complete
                </div>
              </td>
              <td className="px-6 py-4 font-semibold dark:text-white text-black">
                $2,500.00 USD
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Billing;
